import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import PurchaseService from '../api/PurchaseService'

class PurchaseStore {
    isProgress = false
    hasChange = false
    purchase = {}
    status = [
        { value: 1, text: 'Новая', color: 'green' },
        { value: 2, text: 'Согласована', color: 'blue' },
        { value: 3, text: 'Отклонена', color: 'red' },
        { value: 4, text: 'Заказчик выбран', color: 'green' },
        { value: 5, text: 'Завершена', color: 'grey' },
    ]

    types = [
        { value: 1, label: 'Товар' },
        { value: 2, label: 'Услуга' },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(purchase) {
        this.purchase = purchase
        this.hasChange = false
    }

    change(props) {
        this.purchase = { ...this.purchase, ...props }
        this.hasChange = true
    }

    addSupplier(props) {
        if (!this.purchase.suppliers) {
            this.change({ suppliers: [props] })
        } else {
            this.change({ suppliers: [...this.purchase.suppliers, props] })
        }
    }

    changeSupplier(index, props) {
        this.change({
            suppliers: this.purchase.suppliers.map((item, i) =>
                i === index ? { ...item, ...props } : item
            ),
        })
    }

    async setSelectedSupplier(id) {
        await PurchaseService.save({
            id: this.purchase.id,
            selectedSupplier: id,
        })
        this.purchase.selectedSupplier = id
    }

    unset() {
        this.purchase = {}
        this.hasChange = false
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const purchase = (await PurchaseService.getById(id)).data
            if (purchase.id) {
                purchase.dateClose =
                    purchase.dateClose && dayjs(purchase.dateClose)
                if (purchase.original?.dateClose) {
                    purchase.original.dateClose =
                        purchase.original.dateClose &&
                        dayjs(purchase.original.dateClose)
                }
                purchase.suppliers = purchase.suppliers.map((item) => {
                    item.payDate = item.payDate && dayjs(item.payDate)
                    item.firstPayDate =
                        item.firstPayDate && dayjs(item.firstPayDate)
                    item.lastPayDate =
                        item.lastPayDate && dayjs(item.lastPayDate)
                    return item
                })
                this.set(purchase)
            }
        } catch (err) {
            console.log('Purchase get error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            let purchase = {
                ...this.purchase,
                suppliers: this.purchase.suppliers?.map((sup) => ({
                    ...sup,
                    files: sup.files?.map((file) => file.uid),
                })),
            }
            purchase = (await PurchaseService.save(purchase)).data
            if (purchase.id) {
                this.change(purchase)
                this.hasChange = false
                return purchase.id
            }
        } catch (err) {
            console.log('Purchase save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async changeStatus(status) {
        this.setIsProgress(true)
        try {
            await PurchaseService.save({ id: this.purchase.id, status })
            this.purchase.status = status
        } catch (err) {
            console.log('Purchase changeStatus error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async saveOriginal() {
        this.setIsProgress(true)
        try {
            this.purchase.original = {
                name: this.purchase.name,
                brand: this.purchase.brand,
                count: this.purchase.count,
                cost: this.purchase.cost,
                dateClose: this.purchase.dateClose,
            }
            await PurchaseService.save({
                id: this.purchase.id,
                original: this.purchase.original,
            })
        } catch (err) {
            console.log('Purchase changeStatus error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await PurchaseService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Purchase delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new PurchaseStore()
