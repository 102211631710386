import React from 'react'
import dayjs from 'dayjs'
import { Bflex, H4, Table, ButtonOut, Text } from '../../ui'
import { Link } from 'react-router-dom'

const columns = [
    {
        title: 'Дата ремонта, факт',
        dataIndex: 'startDateFact',
        rowScope: 'row',
        render: (startDateFact, { endDateFact }) =>
            startDateFact &&
            dayjs(startDateFact).format('DD.MM.YYYY') +
                ' — ' +
                (!endDateFact ? '' : dayjs(endDateFact).format('DD.MM.YYYY')),
    },
    {
        title: 'Затраты, план',
        dataIndex: 'costPlan',
    },
    {
        title: 'Затраты, факт',
        dataIndex: 'costFact',
    },
    {
        title: 'Отклон. по затратам',
        dataIndex: 'costPlan',
        render: (costPlan, { costFact }) => {
            if (!costPlan || !costFact) return
            const deviation = costFact - costPlan
            return deviation === 0 ? (
                <Text type="secondary">0</Text>
            ) : deviation < 0 ? (
                <Text type="success">{deviation}</Text>
            ) : (
                <Text type="danger">+{deviation}</Text>
            )
        },
    },
    {
        title: 'Причина ремонта',
        dataIndex: 'reason',
    },
]

const UnplanedBlock = ({ dataSource }) => (
    <>
        <Bflex>
            <H4>Плановые ремонты</H4>
            <Link to="/repair/create">
                <ButtonOut>Добавить ремонт</ButtonOut>
            </Link>
        </Bflex>
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
        />
    </>
)

export default UnplanedBlock
