import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Vflex, Table, H3, Spin } from '../../../ui/index.jsx'
import DirectorService from '../../../api/DirectorService.jsx'

const columns = [
    {
        title: 'Транспорт',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Тип аренды',
        dataIndex: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
        title: 'Арендатор',
        dataIndex: 'client',
        sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
        title: 'Дата передачи',
        dataIndex: 'startDate',
        sorter: (a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)),
        render: (startDate) => dayjs(startDate).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата возврата',
        dataIndex: 'endDate',
        sorter: (a, b) => dayjs(a.endDate).diff(dayjs(b.endDate)),
        render: (endDate) => dayjs(endDate).format('DD.MM.YYYY'),
    },
    {
        title: 'Срок',
        dataIndex: 'countDays',
        sorter: (a, b) => a.countDays - b.countDays,
    },
]

const RentTable = ({ date, period }) => {
    const [data, setData] = useState()
    
    useEffect(() => {
        async function fetchData() {
            const data = (
                await DirectorService.getRent({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setData(data)
        }
        fetchData()
    }, [date, period])

    if (!data) {
        return <Spin />
    }

    return (
        <Vflex>
            <H3>Аренда</H3>
            <Table
                columns={columns}
                dataSource={data}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default RentTable
