import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import RepairService from '../../api/RepairService'
import TransportStore from '../../store/TransportStore'
import {
    Breadcrumb,
    InputText,
    InputNum,
    Input2Num,
    InputDate,
    Vflex,
    Hflex,
    H4,
    Button,
    Spin,
    Select,
    Badge,
} from '../../ui'
import AddParamModal from './addParamModal'
import SpareBlock from './spareBlock'
import TiresBlock from './tiresBlock'
import ServiceBlock from './serviceBlock'
import PlanedBlock from './planedBlock'
import UnplanedBlock from './unplanedBlock'

function unflat(src, count) {
    if (!src) return []
    const result = []
    for (let s = 0, e = count; s < src.length; s += count, e += count)
        result.push(src.slice(s, e))
    return result
}

const EditTransport = () => {
    let { id } = useParams()
    const [transports, setTransports] = useState([])
    const [repairs, setRepairs] = useState([])
    useEffect(() => {
        async function fetchData() {
            const transports = (await RepairService.getTransport()).data
            setTransports(transports.filter((item) => item.value !== id))
            const repairs = (await RepairService.getByTransportId(id)).data
            setRepairs(Object.groupBy(repairs, ({ type }) => type))
        }
        fetchData()
    }, [])
    useEffect(() => {
        TransportStore.getById(id)
    }, [id])
    const { transport, status, isProgress, hasChange } = TransportStore

    if (isProgress) {
        return <Spin />
    }

    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    { text: 'Транспорт', link: '/transport' },
                    {
                        text: `${transport.brand} — ${transport.model}, ${transport.release} (${transport.gosnumber})`,
                    },
                ]}
            />
            <H4>Транспорт</H4>
            <Hflex>
                <InputText
                    value={transport.brand}
                    onChange={(brand) => TransportStore.change({ brand })}
                    style={{ width: '100%' }}
                >
                    Марка
                </InputText>
                <InputText
                    value={transport.model}
                    onChange={(model) => TransportStore.change({ model })}
                    style={{ width: '100%' }}
                >
                    Модель
                </InputText>
                <InputText
                    value={transport.gosnumber}
                    onChange={(gosnumber) =>
                        TransportStore.change({ gosnumber })
                    }
                    style={{ width: '100%' }}
                >
                    Госномер
                </InputText>
                <InputDate
                    picker="year"
                    placeholder="2000"
                    format="YYYY"
                    maxDate={dayjs()}
                    value={
                        transport.release &&
                        dayjs(transport.release.toString(), 'YYYY')
                    }
                    onChange={(val) =>
                        TransportStore.change({ release: val.year() })
                    }
                    style={{ width: '100%' }}
                >
                    Год выпуска
                </InputDate>
                <InputNum
                    value={transport.mileage}
                    onChange={(mileage) => TransportStore.change({ mileage })}
                    style={{ width: '100%' }}
                >
                    Пробег, км
                </InputNum>
                <Select
                    style={{ width: '100%' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={transport.status}
                    onChange={(status) => TransportStore.change({ status })}
                >
                    Статус
                </Select>
            </Hflex>
            {transport.status == 3 && (
                <Hflex>
                    <InputDate
                        value={transport.withdrawalDate}
                        onChange={(withdrawalDate) =>
                            TransportStore.change({ withdrawalDate })
                        }
                        style={{ width: '100%' }}
                    >
                        Дата вывода
                    </InputDate>
                    {Array.from(Array(5).keys()).map((i) => (
                        <div key={i} style={{ width: '100%' }} />
                    ))}
                </Hflex>
            )}
            {transport.status == 4 && (
                <Hflex>
                    <InputDate
                        value={transport.saleDate}
                        onChange={(saleDate) =>
                            TransportStore.change({ saleDate })
                        }
                        style={{ width: '100%' }}
                    >
                        Дата продажи
                    </InputDate>
                    <InputNum
                        value={transport.salePrice}
                        onChange={(salePrice) =>
                            TransportStore.change({ salePrice })
                        }
                        style={{ width: '100%' }}
                    >
                        Цена продажи
                    </InputNum>
                    {Array.from(Array(4).keys()).map((i) => (
                        <div key={i} style={{ width: '100%' }} />
                    ))}
                </Hflex>
            )}
            {(transport.status == 5 || transport.status == 6) && (
                <Hflex>
                    <InputDate
                        value={transport.rentStartDate}
                        onChange={(rentStartDate) =>
                            TransportStore.change({ rentStartDate })
                        }
                        style={{ width: '100%' }}
                    >
                        Дата вывода
                    </InputDate>
                    <InputDate
                        value={transport.rentEndDate}
                        onChange={(rentEndDate) =>
                            TransportStore.change({ rentEndDate })
                        }
                        style={{ width: '100%' }}
                    >
                        Дата возврата
                    </InputDate>
                    <InputNum
                        value={transport.rentPrice}
                        onChange={(rentPrice) =>
                            TransportStore.change({ rentPrice })
                        }
                        style={{ width: '100%' }}
                    >
                        Цена аренды в месяц
                    </InputNum>
                    {Array.from(Array(3).keys()).map((i) => (
                        <div key={i} style={{ width: '100%' }} />
                    ))}
                </Hflex>
            )}
            <Button disabled={!hasChange} onClick={() => TransportStore.save()}>
                Сохранить
            </Button>
            <H4>Ввод в эксплуатацию</H4>
            {unflat(transport.params, 5).map((group, indexGroup) => (
                <Hflex key={indexGroup}>
                    {group.map((param, indexParam) => (
                        <Input2Num
                            key={indexParam}
                            style={{ width: '100%' }}
                            value1={param.plan}
                            onChange1={(plan) =>
                                TransportStore.changeParam(
                                    { plan },
                                    indexGroup * 5 + indexParam
                                )
                            }
                            value2={param.fact}
                            onChange2={(fact) =>
                                TransportStore.changeParam(
                                    { fact },
                                    indexGroup * 5 + indexParam
                                )
                            }
                        >
                            {param.name}
                        </Input2Num>
                    ))}
                    {Array.from(Array(5 - group.length).keys()).map((i) => (
                        <div key={i} style={{ width: '100%' }} />
                    ))}
                </Hflex>
            ))}
            <Hflex>
                <AddParamModal />
            </Hflex>
            <SpareBlock dataSource={transport.spares} />
            <TiresBlock dataSource={transport.tires} transports={transports} />
            <ServiceBlock dataSource={repairs[0]} />
            <PlanedBlock dataSource={repairs[1]} />
            <UnplanedBlock dataSource={repairs[2]} />
        </Vflex>
    )
}

export default observer(EditTransport)
