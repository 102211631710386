import React from 'react'
import { observer } from 'mobx-react-lite'
import CandidateStore from '../../store/CandidateStore'
import { InputText, InputDate, Select, Hflex, H4 } from '../../ui'

const InformationBloack = ({ candidate }) => {
    return (
        <>
            <H4>ИНФОРМАЦИЯ</H4>
            <Hflex>
                <Select
                    style={{
                        minWidth: 120,
                    }}
                    options={[
                        {
                            value: true,
                            label: 'Да',
                        },
                        {
                            value: false,
                            label: 'Нет',
                        },
                    ]}
                    value={candidate.medical}
                    onChange={(medical) => CandidateStore.change({ medical })}
                >
                    Медкомиссия
                </Select>
                <InputDate
                    value={candidate.medicalDate}
                    onChange={(medicalDate) =>
                        CandidateStore.change({ medicalDate })
                    }
                >
                    Дата прохождения
                </InputDate>
                <InputText
                    style={{ width: '100%' }}
                    value={candidate.medicalNote}
                    onChange={(medicalNote) =>
                        CandidateStore.change({ medicalNote })
                    }
                >
                    Заметка
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    style={{
                        minWidth: 120,
                    }}
                    options={[
                        {
                            value: true,
                            label: 'Пройдено',
                        },
                        {
                            value: false,
                            label: 'Не пройдено',
                        },
                    ]}
                    value={candidate.otk}
                    onChange={(otk) => CandidateStore.change({ otk })}
                >
                    ОТК
                </Select>
                <InputText
                    style={{ width: '100%' }}
                    value={candidate.otkNote}
                    onChange={(otkNote) => CandidateStore.change({ otkNote })}
                >
                    Причина
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    style={{
                        minWidth: 120,
                    }}
                    options={[
                        {
                            value: true,
                            label: 'Да',
                        },
                        {
                            value: false,
                            label: 'Нет',
                        },
                    ]}
                    value={candidate.tachograph}
                    onChange={(tachograph) =>
                        CandidateStore.change({ tachograph })
                    }
                >
                    Карта тахографа
                </Select>
                <InputText
                    style={{ width: '100%' }}
                    value={candidate.tachographNote}
                    onChange={(tachographNote) =>
                        CandidateStore.change({ tachographNote })
                    }
                >
                    Заметка
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                        minWidth: 300,
                    }}
                    options={['A', 'B', 'BE', 'C', 'CE', 'D', 'DE', 'M'].map(
                        (item) => ({ value: item, label: item })
                    )}
                    value={candidate.license}
                    onChange={(license) => CandidateStore.change({ license })}
                >
                    Водительское удостоверение
                </Select>
                <InputText
                    style={{ width: '100%' }}
                    value={candidate.truck}
                    onChange={(truck) => CandidateStore.change({ truck })}
                >
                    Тип грузовика
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    style={{
                        minWidth: 120,
                    }}
                    options={[
                        {
                            value: true,
                            label: 'Да',
                        },
                        {
                            value: false,
                            label: 'Нет',
                        },
                    ]}
                    value={candidate.crew}
                    onChange={(crew) => CandidateStore.change({ crew })}
                >
                    Экипаж
                </Select>
                <InputText
                    style={{ width: '100%' }}
                    value={candidate.crewNote}
                    onChange={(crewNote) => CandidateStore.change({ crewNote })}
                >
                    Заметка
                </InputText>
            </Hflex>
        </>
    )
}

export default observer(InformationBloack)
