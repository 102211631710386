import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import EmployeeService from '../../api/EmployeeService'
import EmployeeStore from '../../store/EmployeeStore'
import AuthStore from '../../store/AuthStore'
import {
    Breadcrumb,
    InputText,
    InputDate,
    Select,
    Vflex,
    Hflex,
    Badge,
    Spin,
    Button,
    InputNum,
    Comments,
} from '../../ui'
import RoutesBlock from './routesBlock'
import NonWorkingBlock from './nonWorkingBlock'
import InformationBlock from './informationBlock'
import VisaBlock from './visaBlock'

const DriverEmployeeEdit = () => {
    const { employee, status, isProgress, hasChange } = EmployeeStore
    let { id } = useParams()
    const [routes, setRoutes] = useState([])
    useEffect(() => {
        async function fetchData() {
            const routes = (await EmployeeService.getRoutesById(id)).data
            setRoutes(routes)
        }
        fetchData()
    }, [])
    useEffect(() => {
        EmployeeStore.getById(id)
    }, [id])
    if (isProgress) {
        return <Spin />
    }
    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    {
                        text: 'Водители',
                        link:
                            AuthStore.user.role === 'driver' ? '/' : '/drivers',
                    },
                    { text: employee.name || 'Новая вакансия' },
                ]}
            />
            <Hflex>
                <InputText
                    placeholder="Иванов Иван Иванович"
                    style={{ width: '20%' }}
                    value={employee.name}
                    onChange={(name) => EmployeeStore.change({ name })}
                >
                    ФИО
                </InputText>
                <InputDate
                    style={{ width: '20%' }}
                    value={employee.birstday}
                    onChange={(birstday) => EmployeeStore.change({ birstday })}
                >
                    Дата рождения
                </InputDate>
                <InputText
                    placeholder="Воронеж"
                    style={{ width: '20%' }}
                    value={employee.city}
                    onChange={(city) => EmployeeStore.change({ city })}
                >
                    Город
                </InputText>
                <InputText
                    placeholder="+7 (999) 999-99-99"
                    style={{ width: '20%' }}
                    value={employee.workPhone}
                    onChange={(workPhone) =>
                        EmployeeStore.change({ workPhone })
                    }
                >
                    Рабочий номер телефона
                </InputText>
                <InputText
                    placeholder="+7 (999) 999-99-99"
                    style={{ width: '20%' }}
                    value={employee.phone}
                    onChange={(phone) => EmployeeStore.change({ phone })}
                >
                    Личный номер телефона
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: '20%' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={employee.status}
                    onChange={(status) => EmployeeStore.change({ status })}
                >
                    Статус
                </Select>
                <InputDate
                    style={{ width: '20%' }}
                    value={employee.employmentDate}
                    onChange={(employmentDate) =>
                        EmployeeStore.change({ employmentDate })
                    }
                >
                    Дата трудоустройства
                </InputDate>
                <InputDate
                    style={{ width: '20%' }}
                    value={employee.dismissalDate}
                    onChange={(dismissalDate) =>
                        EmployeeStore.change({ dismissalDate })
                    }
                >
                    Дата увольнения
                </InputDate>
                <Select
                    style={{
                        width: '20%',
                        minWidth: 120,
                    }}
                    options={[
                        {
                            value: true,
                            label: 'Есть',
                        },
                        {
                            value: false,
                            label: 'Нет',
                        },
                    ]}
                    value={employee.passport}
                    onChange={(passport) => EmployeeStore.change({ passport })}
                >
                    Загранпаспорт
                </Select>
                <InputDate
                    style={{ width: '20%' }}
                    value={employee.passportEnd}
                    onChange={(passportEnd) =>
                        EmployeeStore.change({ passportEnd })
                    }
                >
                    Дата окончания
                </InputDate>
            </Hflex>
            <Hflex>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.parkingLoad}
                    onChange={(parkingLoad) =>
                        EmployeeStore.change({ parkingLoad })
                    }
                >
                    Простой по погрузке, ₽/день
                </InputNum>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.parkingRepair}
                    onChange={(parkingRepair) =>
                        EmployeeStore.change({ parkingRepair })
                    }
                >
                    Простой по ремонту, ₽/день
                </InputNum>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.parkingAbroad}
                    onChange={(parkingAbroad) =>
                        EmployeeStore.change({ parkingAbroad })
                    }
                >
                    Простой за рубежом, ₽/день
                </InputNum>
            </Hflex>
            <Hflex>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.salary}
                    onChange={(salary) => EmployeeStore.change({ salary })}
                >
                    Фиксированная зарплата
                </InputNum>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.costPerKm}
                    onChange={(costPerKm) =>
                        EmployeeStore.change({ costPerKm })
                    }
                >
                    Ставка ₽/км
                </InputNum>
                <InputNum
                    style={{ width: '100%' }}
                    value={employee.costPerDay}
                    onChange={(costPerDay) =>
                        EmployeeStore.change({ costPerDay })
                    }
                >
                    Суточные
                </InputNum>
            </Hflex>
            <Button disabled={!hasChange} onClick={() => EmployeeStore.save()}>
                Сохранить
            </Button>
            <Comments
                comments={employee.comments}
                add={(comment) => EmployeeStore.addComment(comment)}
            />
            <RoutesBlock dataSource={routes} />
            <NonWorkingBlock items={employee.nonWorking} />

            <InformationBlock employee={employee} />
            <VisaBlock employee={employee} />
            <Button disabled={!hasChange} onClick={() => EmployeeStore.save()}>
                Сохранить
            </Button>
        </Vflex>
    )
}

export default observer(DriverEmployeeEdit)
