import instance from './index'

const rootPath = '/api/employee/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getDrivers: () => {
        return instance.get(rootPath + 'drivers')
    },

    getReport: (date) => {
        return instance.post(rootPath + 'report', { date })
    },

    getDashboard: ({ period, date }) => {
        return instance.post(rootPath + 'dashboard', { period, date })
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    getRoutesById: (id) => {
        return instance.get(rootPath + 'routesById/' + id)
    },

    save: (employee) => {
        return instance.post(rootPath + 'save', { employee })
    },

    addComment: ({ employeeId, text }) => {
        return instance.post(rootPath + 'addComment', { employeeId, text })
    },

    addNonWorking: (params) => {
        return instance.post(rootPath + 'addNonWorking', params)
    },

    changeNonWorking: (params) => {
        return instance.post(rootPath + 'changeNonWorking', params)
    },

    deleteNonWorking: (params) => {
        return instance.post(rootPath + 'deleteNonWorking', params)
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
