import React from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { Button, H3, Bflex, Table, Badge } from '../../ui'
import { Link, useNavigate } from 'react-router-dom'
import EmployeeStore from '../../store/EmployeeStore'

const CandidatesBlock = ({ vacancy, candidates }) => {
    const navigate = useNavigate()
    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, record) => (
                <Link to={'/candidate/' + vacancy + '/' + record.id}>{_}</Link>
            ),
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Город',
            dataIndex: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city),
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (num) =>
                num ? (
                    <Badge
                        color={EmployeeStore.status[num - 1].color}
                        text={EmployeeStore.status[num - 1].text}
                    />
                ) : null,
        },
        {
            title: 'Тег',
            dataIndex: 'tag',
            sorter: (a, b) => a.tag.localeCompare(b.tag),
        },
        {
            title: 'Дата последнего изменения',
            dataIndex: 'last',
            sorter: (a, b) => dayjs(a.last).diff(dayjs(b.last)),
            render: (last) => dayjs(last).format('DD.MM.YYYY'),
        },
    ]
    return (
        <>
            <Bflex>
                <H3>Соискатели</H3>
                <Button
                    onClick={() =>
                        navigate('/candidate/' + vacancy + '/create')
                    }
                >
                    Создать
                </Button>
            </Bflex>
            <Table columns={columns} dataSource={candidates} />
        </>
    )
}

export default observer(CandidatesBlock)
