import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Vflex, DashPeriod, Table, H3, Spin, Hflex } from '../../ui'
import EmployeeService from '../../api/EmployeeService'

const columns1 = [
    {
        title: 'Сотрудник',
        dataIndex: 'name',
    },
    {
        title: 'Вакансия',
        dataIndex: 'vacancy',
    },
    {
        title: 'Город',
        dataIndex: 'city',
    },
    {
        title: 'Дата выхода',
        dataIndex: 'employmentDate',
        sorter: (a, b) =>
            dayjs(a.employmentDate, 'DD.MM.YYYY') -
            dayjs(b.employmentDate, 'DD.MM.YYYY'),
        render: (employmentDate) => dayjs(employmentDate).format('DD.MM.YYYY'),
    },
]

const columns2 = [
    {
        title: 'Сотрудник',
        dataIndex: 'name',
    },
    {
        title: 'Должность',
        dataIndex: 'vacancy',
    },
    {
        title: 'Город',
        dataIndex: 'city',
    },
    {
        title: 'Причина',
        dataIndex: 'reason',
    },
    {
        title: 'Дата увольнения',
        dataIndex: 'dismissalDate',
        sorter: (a, b) =>
            dayjs(a.endDate, 'DD.MM.YYYY') - dayjs(b.endDate, 'DD.MM.YYYY'),
        render: (dismissalDate) => dayjs(dismissalDate).format('DD.MM.YYYY'),
    },
]

const HRDashboardPage = () => {
    const [date, setDate] = useState(dayjs())
    const [period, setPeriod] = useState('week')
    const [employees, setEmployees] = useState()
    useEffect(() => {
        async function fetchData() {
            const employees = (
                await EmployeeService.getDashboard({
                    period,
                    date: date.format('DD.MM.YYYYF'),
                })
            ).data
            setEmployees(employees)
        }
        fetchData()
    }, [date, period])

    if (!employees) {
        return <Spin />
    }

    return (
        <Vflex>
            <Hflex align="flex-end">
                <H3>Найм</H3>
                <DashPeriod
                    style={{ marginTop: 24 }}
                    date={date}
                    setDate={setDate}
                    period={period}
                    setPeriod={setPeriod}
                />
            </Hflex>
            <Table
                columns={columns1}
                dataSource={employees.filter((item) => item.status == 4)}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
            <H3>Увольнение</H3>
            <Table
                columns={columns2}
                dataSource={employees.filter((item) => item.status == 5)}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default HRDashboardPage
