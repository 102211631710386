import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import TransportService from '../api/TransportService'

class TransportStore {
    isProgress = false
    hasChange = false
    transport = {}
    status = [
        { value: 1, text: 'Ввод в эксплуатацию', color: 'gray' },
        { value: 2, text: 'Эксплуатация', color: 'green' },
        { value: 3, text: 'Выставлен на продажу', color: 'purple' },
        { value: 4, text: 'Продан', color: 'red' },
        { value: 5, text: 'Сдан в аренду', color: 'blue' },
        { value: 6, text: 'Взят в аренду', color: 'yellow' },
    ]
    defaultParams = [
        'Госпошлина',
        'Осаго',
        'Тахограф',
        'Датчик скорости',
        'Калибровка',
        'Диагностическая карта',
        'Навигация',
        'Ключи',
        'Телефон',
        'Рация',
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(transport) {
        this.transport = transport
        this.hasChange = false
    }

    change(props) {
        this.transport = { ...this.transport, ...props }
        this.hasChange = true
    }

    changeParam(param, i) {
        this.transport.params[i] = { ...this.transport.params[i], ...param }
        this.hasChange = true
    }

    addParam(param) {
        this.transport.params.push(param)
        TransportService.save({
            id: this.transport.id,
            params: this.transport.params,
        })
    }

    addSpare(spare) {
        if (!this.transport.spares) {
            this.transport.spares = []
        }
        this.transport.spares = [...this.transport.spares, spare]
        TransportService.save({
            id: this.transport.id,
            spares: this.transport.spares,
        })
    }

    addTires(tire) {
        if (!this.transport.tires) {
            this.transport.tires = []
        }
        this.transport.tires = [...this.transport.tires, tire]
        TransportService.save({
            id: this.transport.id,
            tires: this.transport.tires,
        })
    }

    unset() {
        this.transport = {}
        this.hasChange = false
    }

    dayProps = [
        {
            text: 'П',
            full: 'простой',
            color: 'rgba(250, 173, 20, 0.5)',
        },
        {
            text: 'М',
            full: 'маршрут',
            color: 'rgba(82, 196, 26, 0.5)',
        },
        {
            text: 'TO',
            full: 'тех. осмотр',
            color: 'rgba(255, 151, 77, 0.7)',
        },
        {
            text: 'ПР',
            full: 'плановый ремонт',
            color: 'rgba(255, 151, 77, 0.5)',
        },
        {
            text: 'ВР',
            full: 'внеплановый ремонт',
            color: 'rgba(255, 77, 79, 0.5)',
        },
        {
            text: 'Т',
            full: 'таможня',
            color: 'rgba(149, 149, 149, 0.5)',
        },
        {
            text: 'В',
            full: 'выгрузка',
            color: 'rgba(24, 144, 255, 0.5)',
        },
        {
            text: 'З',
            full: 'загрузка',
            color: 'rgba(24, 144, 255, 0.5)',
        },
        {
            text: 'ПП',
            full: 'порожний пробег',
            color: '#FAFAFA',
        },
    ]

    getDayProps(typeDate) {
        switch (typeDate) {
            case 'P':
                return this.dayProps[0]
            case 'M':
                return this.dayProps[1]
            case 'TO':
                return this.dayProps[2]
            case 'PR':
                return this.dayProps[3]
            case 'VR':
                return this.dayProps[4]
            case 'T':
                return this.dayProps[5]
            case 'V':
                return this.dayProps[6]
            case 'Z':
                return this.dayProps[7]
            case 'PP':
                return this.dayProps[8]
        }
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const transport = (await TransportService.getById(id)).data
            if (transport.id) {
                transport.startDate = dayjs(transport.startDate)
                transport.endDate = dayjs(transport.endDate)
                transport.withdrawalDate =
                    transport.withdrawalDate && dayjs(transport.withdrawalDate)
                transport.saleDate =
                    transport.saleDate && dayjs(transport.saleDate)
                transport.rentStartDate =
                    transport.rentStartDate && dayjs(transport.rentStartDate)
                transport.rentEndDate =
                    transport.rentEndDate && dayjs(transport.rentEndDate)
                if (transport.params?.length === 0) {
                    transport.params = this.defaultParams.map((name) => ({
                        name,
                        plan: 0,
                        fact: 0,
                    }))
                }
                this.set(transport)
            }
        } catch (err) {
            console.log('Transport get error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const transport = (await TransportService.save(this.transport)).data
            if (transport.id) {
                this.change(transport)
                this.hasChange = false
            }
        } catch (err) {
            console.log('Transport save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await TransportService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Transport delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new TransportStore()
