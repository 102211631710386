import React from 'react'
import { Spin as AntSpin } from 'antd'

const Spin = () => (
    <AntSpin
        size="large"
        style={{
            display: 'block',
            position: 'fixed',
            height: '100vh',
            top: 'calc(50% - 16px)',
            left: 'calc(50% - 16px)',
        }}
    />
)

export { Spin }
