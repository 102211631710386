import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import EmployeeService from '../api/EmployeeService'

class EmployeeStore {
    isProgress = false
    hasChange = false
    employee = {}
    status = [
        { value: 1, text: 'Собеседование', color: 'orange' },
        { value: 2, text: 'Оформление', color: 'blue' },
        { value: 3, text: 'Резерв', color: 'grey' },
        { value: 4, text: 'Трудоустроен', color: 'green' },
        { value: 5, text: 'Уволился', color: 'red' },
    ]
    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(employee) {
        this.employee = employee
        this.hasChange = false
    }

    change(props) {
        this.employee = { ...this.employee, ...props }
        this.hasChange = true
    }
    setNonWorking(nonWorking) {
        this.employee.nonWorking = nonWorking
    }

    async addNonWorking({ typePeriod, start, end }) {
        const result = await EmployeeService.addNonWorking({
            employeeId: this.employee.id,
            typePeriod,
            start,
            end,
        })
        this.setNonWorking(
            result.data.map((item) => ({
                ...item,
                start: dayjs(item.start),
                end: dayjs(item.end),
            }))
        )
    }

    async changeNonWorking({ id, start, end }) {
        const result = await EmployeeService.changeNonWorking({
            employeeId: this.employee.id,
            id,
            start,
            end,
        })
        this.setNonWorking(
            result.data.map((item) => ({
                ...item,
                start: dayjs(item.start),
                end: dayjs(item.end),
            }))
        )
    }

    async deleteNonWorking(id) {
        const result = await EmployeeService.deleteNonWorking({
            employeeId: this.employee.id,
            id,
        })
        this.setNonWorking(
            result.data.map((item) => ({
                ...item,
                start: dayjs(item.start),
                end: dayjs(item.end),
            }))
        )
    }

    addVisa(props) {
        this.employee.visas.push(props)
        EmployeeService.save({
            id: this.employee.id,
            visas: this.employee.visas,
        })
    }

    changeVisa(index, props) {
        this.change({
            visas: this.employee.visas.map((item, i) =>
                i === index ? { ...item, ...props } : item
            ),
        })
    }

    unset() {
        this.employee = {}
        this.hasChange = false
    }

    dayProps = [
        {
            text: 'П',
            full: 'простой',
            color: 'rgba(250, 173, 20, 0.5)',
        },
        {
            text: 'Б',
            full: 'больничный',
            color: 'rgba(255, 77, 79, 0.5)',
        },
        {
            text: 'О',
            full: 'отпуск',
            color: 'rgba(24, 144, 255, 0.5)',
        },
        {
            text: '8',
            full: 'маршрут',
            color: 'rgba(82, 196, 26, 0.5)',
        },
    ]
    getDayProps(typeDate) {
        switch (typeDate) {
            case 'P':
                return this.dayProps[0]
            case 'B':
                return this.dayProps[1]
            case 'O':
                return this.dayProps[2]
            case '8':
                return this.dayProps[3]
        }
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const employee = (await EmployeeService.getById(id)).data
            if (employee.id) {
                employee.birstday =
                    employee.birstday && dayjs(employee.birstday)
                employee.employmentDate =
                    employee.employmentDate && dayjs(employee.employmentDate)
                employee.dismissalDate =
                    employee.dismissalDate && dayjs(employee.dismissalDate)
                employee.passportEnd =
                    employee.passportEnd && dayjs(employee.passportEnd)
                employee.medicalDate =
                    employee.medicalDate && dayjs(employee.medicalDate)
                employee.nonWorking = employee.nonWorking?.map((item) => ({
                    ...item,
                    start: dayjs(item.start),
                    end: dayjs(item.end),
                }))
                employee.visas = employee.visas.map((visa) => ({
                    ...visa,
                    date: visa.date && dayjs(visa.date),
                }))
                this.set(employee)
            }
        } catch (err) {
            console.log('getEmployee error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const employee = (await EmployeeService.save(this.employee)).data
            if (employee.id) {
                this.change(employee)
                this.hasChange = false
            }
        } catch (err) {
            console.log('Employee save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async addComment(text) {
        this.setIsProgress(true)
        try {
            const newComment = (
                await EmployeeService.addComment({
                    employeeId: this.employee.id,
                    text,
                })
            ).data
            if (newComment) {
                if (this.employee.comments) {
                    this.employee.comments = [
                        newComment,
                        ...this.employee.comments,
                    ]
                } else {
                    this.employee.comments = [newComment]
                }
            }
        } catch (err) {
            console.log('Employee save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await EmployeeService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Employee delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new EmployeeStore()
