import instance from './index'

const rootPath = '/api/'

export default {
    login: (email, password) => {
        return instance.post(rootPath + 'login', { email, password })
    },

    logout: () => {
        return instance.get(rootPath + 'logout')
    },

    checkAuth: () => {
        return instance.get(rootPath + 'checkAuth')
    },

    changeRole: (role) => {
        return instance.post(rootPath + 'changeRole', { role })
    },

    changeCompany: (id) => {
        return instance.get(rootPath + 'changeCompany/' + id)
    },
}
