import React from 'react'
import {
    Button as AntButton,
    Radio,
    theme,
    ConfigProvider,
    Upload,
    Checkbox as AntCheckbox,
} from 'antd'
import { PaperClipOutlined, FileTextOutlined } from '@ant-design/icons'
import { Link } from './'

const Button = ({ onClick, children, ...props }) => (
    <AntButton type="primary" onClick={onClick} {...props}>
        {children}
    </AntButton>
)

const ButtonOut = ({ onClick, children, ...props }) => (
    <AntButton color="primary" variant="outlined" onClick={onClick} {...props}>
        {children}
    </AntButton>
)

const ButtonDefault = ({ onClick, children, ...props }) => (
    <AntButton onClick={onClick} {...props}>
        {children}
    </AntButton>
)

const ButtonBlack = ({ onClick, children, ...props }) => (
    <AntButton color="default" variant="solid" onClick={onClick} {...props}>
        {children}
    </AntButton>
)

const ButtonRed = ({ onClick, children, ...props }) => (
    <AntButton color="danger" variant="solid" onClick={onClick} {...props}>
        {children}
    </AntButton>
)

const ButtonGreen = ({ onClick, children, ...props }) => {
    const { token } = theme.useToken()
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: token.colorSuccess,
                },
            }}
        >
            <AntButton type="primary" onClick={onClick} {...props}>
                {children}
            </AntButton>
        </ConfigProvider>
    )
}

const Checkbox = ({ onChange, children, ...props }) => (
    <AntCheckbox onChange={(e) => onChange(e.target.checked)} {...props}>
        {children}
    </AntCheckbox>
)

const RadioGroup = ({ options, value, onChange, props }) => (
    <Radio.Group
        onChange={(e) => onChange(e.target.value)}
        value={value}
        {...props}
    >
        {options.map((item) => (
            <Radio key={item.value} value={item.value}>
                {item.label}
            </Radio>
        ))}
    </Radio.Group>
)

const ButtonFile = ({ onClick, value, onChange, ...props }) => {
    const handleChange = (info) => {
        let file = info.file
        if (file.status === 'done') {
            file.uid = file.response.id
            file.url = '/file/' + file.response.id
        }
        if (file.status === 'removed') {
            onChange()
        } else {
            onChange(file)
        }
    }
    return (
        <Upload
            name="file"
            action="/upload"
            onChange={handleChange}
            onRemove={() => onChange()}
            fileList={value ? [value] : []}
        >
            {!value && (
                <ButtonDefault onClick={onClick} {...props}>
                    <PaperClipOutlined rotate={180} />
                </ButtonDefault>
            )}
        </Upload>
    )
}

const ButtonFiles = ({ onClick, value, onChange, ...props }) => {
    const handleChange = (info) => {
        let newFileList = info.fileList.map((file) => {
            if (file.status === 'done') {
                file.uid = file.response.id
                file.url = '/file/' + file.response.id
            }
            return file
        })
        onChange(newFileList)
    }
    return (
        <Upload
            name="file"
            action="/upload"
            onChange={handleChange}
            fileList={value}
            multiple
        >
            <ButtonDefault onClick={onClick} {...props}>
                <PaperClipOutlined rotate={180} /> Файлы
            </ButtonDefault>
        </Upload>
    )
}

const File = ({ id, name }) => (
    <Link color="black" href={'/file/' + id} target="_blank">
        <p
            style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
        >
            <FileTextOutlined /> {name}
        </p>
    </Link>
)

export {
    Button,
    ButtonOut,
    ButtonDefault,
    ButtonBlack,
    ButtonRed,
    ButtonGreen,
    Checkbox,
    RadioGroup,
    ButtonFile,
    ButtonFiles,
    File,
}
