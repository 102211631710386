import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import RouteService from '../api/RouteService'

class RouteStore {
    isProgress = false
    hasChange = false
    route = {}
    employees = []
    transports = []
    checkList = {
        truck: [],
        trailer: [],
    }
    status = [
        { value: 1, label: 'Запланирован' },
        { value: 2, label: 'Укомплектован' },
        { value: 3, label: 'В пути' },
        { value: 4, label: 'Завершен' },
        { value: 5, label: 'Отменен' },
    ]

    dayStatus = [
        { value: 1, text: 'В пути', color: 'green' },
        { value: 2, text: 'Погрузка', color: 'grey' },
        { value: 3, text: 'Выгрузка', color: 'blue' },
        { value: 4, text: 'Таможня', color: 'red' },
        { value: 5, text: 'Ремонт', color: 'orange' },
        { value: 6, text: 'Порожний пробег', color: 'black' },
        { value: 7, text: 'Ожидание', color: 'volcano' },
    ]

    statusDoc = [
        { value: 1, label: 'Оригинал не получен' },
        { value: 2, label: 'У водителя' },
        { value: 3, label: 'Получен оригинал' },
    ]

    statusBill = [
        { value: 1, label: 'Выставлен' },
        { value: 2, label: 'Не выставлен' },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(route) {
        this.route = route
        this.hasChange = false
    }

    change(props) {
        this.route = { ...this.route, ...props }
        this.hasChange = true
    }

    unset() {
        this.route = {}
        this.hasChange = false
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const route = (await RouteService.getById(id)).data
            if (route.id) {
                route.startDatePlan =
                    route.startDatePlan && dayjs(route.startDatePlan)
                route.endDatePlan =
                    route.endDatePlan && dayjs(route.endDatePlan)
                route.startDateFact =
                    route.startDateFact && dayjs(route.startDateFact)
                route.endDateFact =
                    route.endDateFact && dayjs(route.endDateFact)
                route.payDate = route.payDate && dayjs(route.payDate)
                this.set(route)
                if (route.startDatePlan && route.endDatePlan) {
                    this.employees = (
                        await RouteService.getEmployees({
                            routeId: route.id,
                            start: route.startDatePlan.format('DD.MM.YYYY'),
                            end: route.endDatePlan.format('DD.MM.YYYY'),
                        })
                    ).data
                    this.transports = (
                        await RouteService.getTransport({
                            routeId: route.id,
                            start: route.startDatePlan.format('DD.MM.YYYY'),
                            end: route.endDatePlan.format('DD.MM.YYYY'),
                        })
                    ).data
                }
                this.checkList = (await RouteService.getCheckList()).data
            }
        } catch (err) {
            console.log('Route get error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const route = (await RouteService.save(this.route)).data
            if (route.id) {
                this.change(route)
                this.hasChange = false
            }
        } catch (err) {
            console.log('Route save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async saveChange(props) {
        try {
            const route = (
                await RouteService.save({ id: this.route.id, ...props })
            ).data
            if (route.id) {
                this.route = { ...this.route, ...props }
            }
        } catch (err) {
            console.log('Route saveChange error', err)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await RouteService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Route delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new RouteStore()
