import React, { useState, useEffect } from 'react'
import AuthStore from '../../../store/AuthStore'
import { Modal, InputText, Select, Vflex, InputPassword } from '../../../ui'
import DirectorService from '../../../api/DirectorService'

const EditUserModal = ({ user, onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [role, setRole] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [note, setNote] = useState()
    useEffect(() => {
        if (!user) return setIsModalOpen(false)
        setName(user.name)
        setRole(user.role)
        setEmail(user.email)
        setPassword()
        setNote(user.note)
        setIsModalOpen(true)
    }, [user])

    const handleOk = async () => {
        await DirectorService.changeUser({
            id: user.id,
            name,
            role,
            password,
            note,
        })
        onClose()
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <Modal
            title="Добавить сотрудника"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{
                disabled:
                    name?.length < 1 ||
                    !role ||
                    email?.length < 1 ||
                    password?.length < 1,
            }}
        >
            <Vflex>
                <InputText value={name} onChange={setName}>
                    ФИО
                </InputText>
                <Select
                    options={AuthStore.roles.filter(
                        (item) => item.value !== 'admin'
                    )}
                    value={role}
                    onChange={setRole}
                >
                    Роль
                </Select>
                <InputText value={email} disabled={true}>
                    Емейл
                </InputText>
                <InputPassword
                    value={password}
                    onChange={setPassword}
                >
                    Пароль
                </InputPassword>
                <InputText value={note} onChange={setNote}>
                    Заметка
                </InputText>
            </Vflex>
        </Modal>
    )
}

export default EditUserModal
