import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import RepairStore from '../../../store/RepairStore'
import { Table, Badge } from '../../../ui'

const columns = [
    {
        title: 'Номер',
        dataIndex: 'number',
        sorter: (a, b) => a.number - b.number,
        render: (_, record) => <Link to={'/repair/' + record.id}>{_}</Link>,
    },
    {
        title: 'Транспорт',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Тип',
        dataIndex: 'transportType',
        render: (transportType) =>
            transportType === 1 ? 'Тягач' : transportType === 2 ? 'Прицеп' : '',
        sorter: (a, b) => a.transportType - b.transportType,
    },
    {
        title: 'Вид ремонта',
        dataIndex: 'type',
        render: (type) =>
            type === 0
                ? 'ТО'
                : type === 1
                  ? 'Плановый ремонт'
                  : type === 2
                    ? 'Внеплановый ремонт'
                    : '',
        sorter: (a, b) => a.type - b.type,
    },
    {
        title: 'Дата начала (План)',
        dataIndex: 'startDatePlan',
        sorter: (a, b) => dayjs(a.startDatePlan).diff(dayjs(b.startDatePlan)),
        render: (startDatePlan) => dayjs(startDatePlan).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата начала (Факт)',
        dataIndex: 'startDateFact',
        sorter: (a, b) => dayjs(a.startDateFact).diff(dayjs(b.startDateFact)),
        render: (startDateFact) => dayjs(startDateFact).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата окончания (План)',
        dataIndex: 'endDatePlan',
        sorter: (a, b) => dayjs(a.endDatePlan).diff(dayjs(b.endDatePlan)),
        render: (endDatePlan) => dayjs(endDatePlan).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата окончания (Факт)',
        dataIndex: 'endDateFact',
        sorter: (a, b) => dayjs(a.endDateFact).diff(dayjs(b.endDateFact)),
        render: (endDateFact) => dayjs(endDateFact).format('DD.MM.YYYY'),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={RepairStore.status[num - 1].color}
                    text={RepairStore.status[num - 1].text}
                />
            ) : null,
    },
]
const RepairTable = ({ repairs }) => (
    <Table
        columns={columns}
        dataSource={repairs}
        showSorterTooltip={{
            target: 'sorter-icon',
        }}
    />
)

export default RepairTable
