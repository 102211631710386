import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import RouteStore from '../../store/RouteStore'
import { InputText, InputDate, Hflex, Modal, Button, Spin } from '../../ui'

const CreateRouteModal = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    useEffect(() => {
        RouteStore.unset()
    }, [])
    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = async () => {
        await RouteStore.save()
        navigate('/route/' + RouteStore.route.id)
    }

    const handleCancel = () => {
        RouteStore.unset()
        setIsModalOpen(false)
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Создать
            </Button>
            <Modal
                title="Создание маршрута"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                okButtonProps={{
                    disabled:
                        RouteStore.isProgress ||
                        !RouteStore.route.pointA ||
                        !RouteStore.route.pointB ||
                        !RouteStore.route.startDatePlan ||
                        !RouteStore.route.endDatePlan,
                }}
                cancelText="Отмена"
                cancelButtonProps={{ disabled: RouteStore.isProgress }}
            >
                <Hflex>
                    <InputText
                        style={{ width: '50%' }}
                        value={RouteStore.route.pointA}
                        onChange={(pointA) => RouteStore.change({ pointA })}
                        disabled={RouteStore.isProgress}
                    >
                        Точка А
                    </InputText>
                    <InputText
                        style={{ width: '50%' }}
                        value={RouteStore.route.pointB}
                        onChange={(pointB) => RouteStore.change({ pointB })}
                        disabled={RouteStore.isProgress}
                    >
                        Точка Б
                    </InputText>
                </Hflex>
                <Hflex>
                    <InputDate
                        style={{ width: '50%' }}
                        value={RouteStore.route.startDatePlan}
                        onChange={(startDatePlan) =>
                            RouteStore.change({ startDatePlan })
                        }
                        disabled={RouteStore.isProgress}
                    >
                        Дата начала
                    </InputDate>
                    <InputDate
                        style={{ width: '50%' }}
                        value={RouteStore.route.endDatePlan}
                        minDate={RouteStore.route.startDatePlan}
                        onChange={(endDatePlan) =>
                            RouteStore.change({ endDatePlan })
                        }
                        disabled={RouteStore.isProgress}
                    >
                        Дата окончания
                    </InputDate>
                </Hflex>
                {RouteStore.isProgress && <Spin />}
            </Modal>
        </>
    )
}

export default observer(CreateRouteModal)
