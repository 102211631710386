import React from 'react'
import { Menu as AntMenu, Affix } from 'antd'

const Menu = ({ items, onSelect, ...props }) => (
    <Affix offsetTop={50}>
        <AntMenu
            style={{
                width: 256,
                height: 'calc(100vh - 70px)',
                marginRight: 20,
            }}
            defaultSelectedKeys={['1']}
            onSelect={onSelect}
            mode="inline"
            items={items}
            {...props}
        />
    </Affix>
)

export { Menu }
