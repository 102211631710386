import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'cost',
        title: 'Себестоимость',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'margin',
        title: 'Маржа',
        render: (_, record) =>
            currencyFormatter(
                Math.round(record.revenue * record.days - record.cost)
            ),
    },
    {
        dataIndex: 'marginPercent',
        title: 'Маржа, %',
        render: (_, record) =>
            Math.round(
                ((record.revenue * record.days - record.cost) /
                    record.revenue) *
                    100
            ),
    },
    {
        dataIndex: 'days',
        title: 'Дни',
    },
    {
        dataIndex: 'perDay',
        title: '₽/сут.',
        render: (_, record) =>
            currencyFormatter(Math.round(record.cost / record.days)),
    },
    {
        dataIndex: 'efficiency',
        title: 'Эффективность в мес.',
        render: (_, record) =>
            Math.round((record.revenue / (record.cost / record.days)) * 100) /
            100,
    },
]

const GeneralTable = () => {
    const {
        startDatePlan,
        endDatePlan,
        revenuePlan,
        planCost,
        logistics: { days },
    } = RouteStore.route

    const planDays = endDatePlan.diff(startDatePlan, 'days')
    const revenue = revenuePlan / planDays
    const activeDays = days.filter((item) => item.status)
    const factCost = activeDays.reduce(
        (a, b) =>
            a +
            b.mileage * b.consumption * b.costPerLiter +
            b.salary +
            b.hotel +
            b.addPoint +
            b.platon +
            b.platonFines +
            b.navigation +
            b.tollRoad +
            b.parking +
            b.washing +
            b.fines +
            b.waitRus +
            b.waitAbroad +
            b.repair +
            b.customs +
            b.greencard +
            b.declaration +
            b.carrier,
        0
    )
    return (
        <>
            <H4>Общая</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        revenue,
                        cost: planCost,
                        days: planDays,
                        changeble: true,
                    },
                    {
                        id: 2,
                        revenue,
                        cost: factCost,
                        days: activeDays.length,
                    },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planCost: data[0].cost,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(GeneralTable)
