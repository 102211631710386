import instance from './index'

const rootPath = '/api/candidate/'

export default {
    getById: (id) => {
        return instance.get(rootPath + id)
    },

    getVacancies: () => {
        return instance.get(rootPath + 'vacancies')
    },

    save: (candidate) => {
        return instance.post(rootPath + 'save', { candidate })
    },

    createDriver: (candidateId) => {
        return instance.post(rootPath + 'createDriver', { candidateId })
    },

    addComment: ({ candidateId, text }) => {
        return instance.post(rootPath + 'addComment', { candidateId, text })
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
