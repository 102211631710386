import { makeAutoObservable } from 'mobx'
import CompanyService from '../api/CompanyService'

class CompanyStore {
    isProgress = false
    hasChange = false
    company = {}
    status = [
        { value: 1, text: 'Активная', color: 'green' },
        { value: 2, text: 'Демо', color: 'orange' },
        { value: 3, text: 'Пауза', color: 'gray' },
        { value: 4, text: 'Отключились', color: 'red' },
    ]

    stage = [
        { value: 1, text: 'Отдел продаж', color: 'blue' },
        { value: 2, text: 'Согласование', color: 'yellow' },
        { value: 3, text: 'Пользуются', color: 'green' },
        { value: 4, text: 'Отказались', color: 'red' },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(company) {
        this.company = company
        this.hasChange = false
    }

    change(props) {
        this.company = { ...this.company, ...props }
        this.hasChange = true
    }

    unset() {
        this.company = {}
        this.hasChange = false
    }

    addContact(props) {
        if (!this.company.contacts) {
            this.change({ contacts: [props] })
        } else {
            this.change({ contacts: [...this.company.contacts, props] })
        }
    }

    changeContact(index, props) {
        this.change({
            contacts: this.company.contacts.map((item, i) =>
                i === index ? { ...item, ...props } : item
            ),
        })
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const company = (await CompanyService.getById(id)).data
            if (company.id) {
                this.set(company)
            }
        } catch (err) {
            console.log('Company get error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const company = (await CompanyService.save(this.company)).data
            if (company.id) {
                this.change(company)
                this.hasChange = false
                return company.id
            }
        } catch (err) {
            console.log('Company save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async changeBalance(balance) {
        this.setIsProgress(true)
        try {
            await CompanyService.save({
                id: this.company.id,
                balance,
            })
            this.company.balance = balance
        } catch (err) {
            console.log('Company save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async addComment(text) {
        this.setIsProgress(true)
        try {
            const newComment = (
                await CompanyService.addComment({
                    companyId: this.company.id,
                    text,
                })
            ).data
            if (newComment) {
                if (this.company.comments) {
                    this.company.comments = [
                        newComment,
                        ...this.company.comments,
                    ]
                } else {
                    this.company.comments = [newComment]
                }
            }
        } catch (err) {
            console.log('Company save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await CompanyService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Company delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new CompanyStore()
