import React, { useState } from 'react'
import AuthStore from '../../../store/AuthStore'
import {
    Modal,
    Button,
    InputText,
    Select,
    Vflex,
    Text,
    InputPassword,
} from '../../../ui'
import DirectorService from '../../../api/DirectorService'

const AddUserModal = ({ onUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [role, setRole] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [note, setNote] = useState()
    const [busy, setBusy] = useState()
    const showModal = () => {
        setName()
        setRole()
        setEmail()
        setPassword()
        setNote()
        setBusy()
        setIsModalOpen(true)
    }

    const handleOk = async () => {
        const res = await DirectorService.addUser({
            name,
            role,
            email,
            password,
            note,
        })
        if (res.data) {
            setIsModalOpen(false)
            onUpdate()
        } else {
            setBusy(true)
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Button onClick={showModal}>Добавить</Button>
            <Modal
                title="Добавить сотрудника"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled:
                        name?.length < 1 ||
                        !role ||
                        email?.length < 1 ||
                        password?.length < 1,
                }}
            >
                <Vflex>
                    <InputText value={name} onChange={setName}>
                        ФИО
                    </InputText>
                    <Select
                        options={AuthStore.roles.filter(
                            (item) => item.value !== 'admin'
                        )}
                        value={role}
                        onChange={setRole}
                    >
                        Роль
                    </Select>
                    <InputText
                        value={email}
                        onChange={setEmail}
                    >
                        Емейл
                    </InputText>
                    {busy && <Text type="danger">Емейл занят</Text>}
                    <InputPassword
                        value={password}
                        onChange={setPassword}
                    >
                        Пароль
                    </InputPassword>
                    <InputText value={note} onChange={setNote}>
                        Заметка
                    </InputText>
                </Vflex>
            </Modal>
        </>
    )
}

export default AddUserModal
