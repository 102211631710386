import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useNavigate } from 'react-router-dom'
import AuthStore from '../../store/AuthStore'
import {
    Breadcrumb,
    InputText,
    InputNum,
    Select,
    Button,
    Vflex,
    Hflex,
    InputDate,
    ButtonDefault,
    ButtonGreen,
    ButtonRed,
    Spin,
    Bflex,
} from '../../ui'
import PurchaseStore from '../../store/PurchaseStore'
import PurchaseService from '../../api/PurchaseService'
import AddSupplierModal from './addSupplierModal'
import SuppliersBlock from './suppliersBlock'

const EditPurchase = () => {
    const { purchase, isProgress, hasChange } = PurchaseStore
    const [responsibles, setResponsibles] = useState([])
    const [showOriginal, setShowOriginal] = useState(false)
    const navigate = useNavigate()
    let { id } = useParams()
    useEffect(() => {
        async function fetchData() {
            const responsibles = (await PurchaseService.getResponsibles()).data
            setResponsibles(responsibles)
        }
        fetchData()
    }, [])
    useEffect(() => {
        if (id === 'create') {
            PurchaseStore.set({ status: 1 })
        } else {
            PurchaseStore.getById(id)
        }
    }, [])

    const create = async () => {
        const id = await PurchaseStore.save()
        navigate('/purchase/' + id, { replace: true })
    }

    const save = () => {
        if (id === 'create') create()
        PurchaseStore.save()
    }

    if (isProgress) {
        return <Spin />
    }

    const isOriginal = !purchase.original?.name
    const editable =
        purchase.status == 1 &&
        AuthStore.user.role === 'director' &&
        !isOriginal

    return (
        <Vflex>
            <Bflex align="flex-end">
                <Breadcrumb
                    steps={[
                        {
                            text: 'Закупки',
                            link:
                                AuthStore.user.role !== 'purchase'
                                    ? '/purchase'
                                    : '/',
                        },
                        {
                            text:
                                'Заявка на закупку ' + (purchase.number || ''),
                        },
                    ]}
                />
                {!isOriginal && !showOriginal && (
                    <ButtonDefault onClick={() => setShowOriginal(true)}>
                        Показать оригинал
                    </ButtonDefault>
                )}
                {!isOriginal && showOriginal && (
                    <ButtonDefault onClick={() => setShowOriginal(false)}>
                        Вернуть
                    </ButtonDefault>
                )}
                {purchase.status == 4 && purchase.selectedSupplier && (
                    <Button onClick={() => PurchaseStore.changeStatus(5)}>
                        Закупка состоялась
                    </Button>
                )}
            </Bflex>
            <Hflex>
                <InputText
                    value={
                        !showOriginal ? purchase.name : purchase.original.name
                    }
                    onChange={(name) => PurchaseStore.change({ name })}
                    disabled={(purchase.id && !editable) || showOriginal}
                    style={{ width: '100%' }}
                >
                    Наименование
                </InputText>
                <InputText
                    value={
                        !showOriginal ? purchase.brand : purchase.original.brand
                    }
                    onChange={(brand) => PurchaseStore.change({ brand })}
                    disabled={(purchase.id && !editable) || showOriginal}
                    style={{ width: '100%' }}
                >
                    Марка/Производитель
                </InputText>
                <Select
                    style={{ width: '100%' }}
                    value={purchase.responsible}
                    onChange={(responsible) =>
                        PurchaseStore.change({ responsible })
                    }
                    disabled={purchase.id}
                    options={responsibles}
                >
                    Согласование с руководителем
                </Select>
                <Select
                    style={{ width: '100%' }}
                    value={purchase.type}
                    onChange={(type) => PurchaseStore.change({ type })}
                    disabled={purchase.id}
                    options={PurchaseStore.types}
                >
                    Тип
                </Select>
            </Hflex>
            <Hflex>
                <InputNum
                    value={
                        !showOriginal ? purchase.count : purchase.original.count
                    }
                    onChange={(count) => PurchaseStore.change({ count })}
                    disabled={(purchase.id && !editable) || showOriginal}
                    style={{ width: '100%' }}
                >
                    Количество
                </InputNum>
                <InputNum
                    value={
                        !showOriginal ? purchase.cost : purchase.original.cost
                    }
                    onChange={(cost) => PurchaseStore.change({ cost })}
                    disabled={(purchase.id && !editable) || showOriginal}
                    style={{ width: '100%' }}
                >
                    Плановая цена за штуку
                </InputNum>
                <InputNum
                    value={
                        !showOriginal
                            ? purchase.count * purchase.cost || 0
                            : purchase.original.count *
                                  purchase.original.cost || 0
                    }
                    disabled
                    style={{ width: '100%' }}
                >
                    Общая плановая стоимость
                </InputNum>
                <InputDate
                    value={
                        !showOriginal
                            ? purchase.dateClose
                            : purchase.original.dateClose
                    }
                    onChange={(dateClose) =>
                        PurchaseStore.change({ dateClose })
                    }
                    disabled={(purchase.id && !editable) || showOriginal}
                    style={{ width: '100%' }}
                >
                    Дата закрытия заявки
                </InputDate>
            </Hflex>
            <SuppliersBlock
                suppliers={purchase.suppliers}
                selected={purchase.selectedSupplier}
                count={purchase.count}
                disabled={purchase.id && !editable}
                finished={purchase.status == 5}
            />
            {purchase.id &&
            purchase.status == 1 &&
            AuthStore.user.role === 'director' ? (
                isOriginal ? (
                    <Hflex>
                        <ButtonGreen
                            onClick={() => PurchaseStore.changeStatus(2)}
                        >
                            Одобрить
                        </ButtonGreen>
                        <ButtonRed
                            onClick={() => PurchaseStore.changeStatus(3)}
                        >
                            Отклонить
                        </ButtonRed>
                        <ButtonDefault
                            onClick={() => PurchaseStore.saveOriginal()}
                        >
                            Редактировать
                        </ButtonDefault>
                    </Hflex>
                ) : (
                    <>
                        <Hflex>
                            <AddSupplierModal />
                        </Hflex>
                        <Hflex>
                            <ButtonGreen
                                onClick={async () => {
                                    await PurchaseStore.changeStatus(2)
                                    save()
                                }}
                            >
                                Одобрить
                            </ButtonGreen>
                            <ButtonRed
                                onClick={() => PurchaseStore.changeStatus(3)}
                            >
                                Отклонить
                            </ButtonRed>
                        </Hflex>
                    </>
                )
            ) : purchase.id && AuthStore.user.role === 'purchase' ? (
                <>
                    <Hflex>
                        <AddSupplierModal full />
                    </Hflex>
                    <Button disabled={!hasChange} onClick={save}>
                        Сохранить
                    </Button>
                </>
            ) : (
                !purchase.id && (
                    <>
                        <Hflex>
                            <AddSupplierModal
                                full={AuthStore.user.role === 'purchase'}
                            />
                        </Hflex>
                        <Button
                            disabled={!purchase.responsible || !purchase.type}
                            onClick={create}
                        >
                            Создать заявку
                        </Button>
                    </>
                )
            )}
        </Vflex>
    )
}

export default observer(EditPurchase)
