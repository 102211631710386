import React, { useState, useEffect } from 'react'
import { Vflex, Table, H3, Spin } from '../../../ui/index.jsx'
import DirectorService from '../../../api/DirectorService.jsx'

const columns = [
    {
        title: '',
        dataIndex: 'name',
    },
    {
        title: 'Доп.инфо',
        dataIndex: 'all',
    },
    {
        title: 'Всего',
        dataIndex: 'all',
    },
    {
        title: 'На базе',
        dataIndex: 'all',
    },
    {
        title: 'Ремонт',
        dataIndex: 'all',
    },
    {
        title: 'В продаже',
        dataIndex: 'all',
    },
    {
        title: 'Простой',
        dataIndex: 'all',
    },
    {
        title: 'Нет водителя',
        dataIndex: 'all',
    },
    {
        title: 'В работе',
        dataIndex: 'all',
    },
    {
        title: 'Не испол',
        dataIndex: 'all',
    },
]

const TransportTable = ({ date, period }) => {
    const [data, setData] = useState()
    
    useEffect(() => {
        async function fetchData() {
            const data = (
                await DirectorService.getTransport({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setData(data)
        }
        fetchData()
    }, [date, period])

    if (!data) {
        return <Spin />
    }

    return (
        <Vflex>
            <H3>Автопарк</H3>
            <Table
                columns={columns}
                dataSource={data}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default TransportTable
