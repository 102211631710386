import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import AuthStore from '../../store/AuthStore'
import RouteService from '../../api/RouteService'
import RouteStore from '../../store/RouteStore'
import { Vflex, H3, Table, Bflex, Spin, currencyFormatter } from '../../ui'

import CreateRouteModal from './createRouteModal'

const RoutesPage = () => {
    const [routes, setRoutes] = useState()
    const [trucks, setTrucks] = useState()
    useEffect(() => {
        async function fetchData() {
            const routes = (await RouteService.get()).data
            setRoutes(routes)
            setTrucks(
                routes
                    .map(({ truck }) => truck)
                    .filter(
                        (value, index, array) =>
                            value && array.indexOf(value) === index
                    )
                    .map((item) => ({ value: item, text: item }))
            )
        }
        fetchData()
    }, [])

    if (!routes) {
        return <Spin />
    }
    const columns = [
        {
            title: 'Маршрут',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            fixed: true,
            render: (_, record) => <Link to={'/route/' + record.id}>{_}</Link>,
        },
        {
            title: 'Контрагент',
            dataIndex: 'client',
            sorter: (a, b) => a.client.localeCompare(b.client),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            showSorterTooltip: {
                target: 'full-header',
            },
            filters: RouteStore.status.map(({ value, label }) => ({
                value,
                text: label,
            })),

            onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a?.status - b?.status,
            render: (value) => RouteStore.status[value - 1]?.label,
        },
        {
            title: 'Водитель',
            dataIndex: 'driver',
            sorter: (a, b) => a.driver.localeCompare(b.driver),
        },
        {
            title: 'Грузовик',
            dataIndex: 'truck',
            sorter: (a, b) => a.truck.localeCompare(b.truck),

            showSorterTooltip: {
                target: 'full-header',
            },
            filters: trucks,
            onFilter: (value, record) => record.truck === value,
        },
        {
            title: 'Выручка (План)',
            dataIndex: 'revenuePlan',
            sorter: (a, b) => a.revenuePlan - b.revenuePlan,
            render: (_) => currencyFormatter(_),
        },
        {
            title: 'Выручка (Факт)',
            dataIndex: 'revenueFact',
            sorter: (a, b) => a.revenueFact - b.revenueFact,
            render: (_) => currencyFormatter(_),
        },
        {
            title: 'Затраты (План)',
            dataIndex: 'expensesPlan',
            sorter: (a, b) => a.expensesPlan - b.expensesPlan,
            render: (_) => currencyFormatter(_),
        },
        {
            title: 'Затраты (Факт)',
            dataIndex: 'expensesFact',
            sorter: (a, b) => a.expensesFact - b.expensesFact,
            render: (_) => currencyFormatter(_),
        },
        {
            title: 'Маржа,% (План)',
            render: (_, { revenuePlan, expensesPlan }) =>
                currencyFormatter(
                    Math.round(
                        ((revenuePlan - expensesPlan) / revenuePlan) * 100
                    )
                ),
            sorter: (a, b) =>
                (a.revenuePlan - a.expensesPlan) / a.revenuePlan -
                (b.revenuePlan - b.expensesPlan) / b.revenuePlan,
        },
        {
            title: 'Маржа,% (Факт)',
            render: (_, { revenueFact, expensesFact }) =>
                currencyFormatter(
                    Math.round(
                        ((revenueFact - expensesFact) / revenueFact) * 100
                    )
                ),
            sorter: (a, b) =>
                (a.revenueFact - a.expensesFact) / a.revenueFact -
                (b.revenueFact - b.expensesFact) / b.revenueFact,
        },
        {
            title: 'Дата начала (План)',
            dataIndex: 'startDatePlan',
            sorter: (a, b) =>
                dayjs(a.startDatePlan).diff(dayjs(b.startDatePlan)),
            render: (startDatePlan) =>
                dayjs(startDatePlan).format('DD.MM.YYYY'),
        },
        {
            title: 'Дата начала (Факт)',
            dataIndex: 'startDateFact',
            sorter: (a, b) =>
                dayjs(a.startDateFact).diff(dayjs(b.startDateFact)),
            render: (startDateFact) =>
                startDateFact && dayjs(startDateFact).format('DD.MM.YYYY'),
        },
        {
            title: 'Дата окончания (План)',
            dataIndex: 'endDatePlan',
            sorter: (a, b) => dayjs(a.endDatePlan).diff(dayjs(b.endDatePlan)),
            render: (endDatePlan) => dayjs(endDatePlan).format('DD.MM.YYYY'),
        },
        {
            title: 'Дата окончания (Факт)',
            dataIndex: 'endDateFact',
            sorter: (a, b) => dayjs(a.endDateFact).diff(dayjs(b.endDateFact)),
            render: (endDateFact) =>
                endDateFact && dayjs(endDateFact).format('DD.MM.YYYY'),
        },
    ]

    return (
        <Vflex>
            <Bflex>
                <H3>Маршруты</H3>
                {AuthStore.user.role === 'logist' && <CreateRouteModal />}
            </Bflex>
            <Table
                columns={columns}
                dataSource={routes}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
                scroll={{ x: 'max-content' }}
            />
        </Vflex>
    )
}

export default observer(RoutesPage)
