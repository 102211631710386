import React from 'react'

import SettingsPage from '../settingsPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'
import CompanyPage from '../company/companyPage.jsx'
import EditCompany from '../company/editCompany.jsx'

export default [
    {
        path: '/',
        page: <CompanyPage />,
        label: 'Компании',
    },
    {
        path: '/company/:id',
        page: <EditCompany />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
