import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import DirectorService from '../../../api/DirectorService'
import {
    Vflex,
    Hflex,
    H3,
    H5,
    InputText,
    ButtonRed,
    ButtonBlack,
    Spin,
    Button,
} from '../../../ui'

const CheckListBlock = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [truckCheck, setTruckCheck] = useState()
    const [trailerCheck, setTrailerCheck] = useState()
    useEffect(() => {
        async function fetchData() {
            const list = (await DirectorService.getCheckList()).data
            setTruckCheck(list.truck)
            setTrailerCheck(list.trailer)
        }
        fetchData()
    }, [])

    const onSave = async () => {
        messageApi.open({
            key: 'save',
            type: 'loading',
            content: 'Сохранение...',
        })
        await DirectorService.saveCheckList({
            truck: truckCheck,
            trailer: trailerCheck,
        })
        messageApi.open({
            key: 'save',
            type: 'success',
            content: 'Сохранено!',
            duration: 2,
        })
    }

    if (!truckCheck) {
        return <Spin />
    }
    return (
        <Vflex style={{ width: '100%' }}>
            {contextHolder}
            <H3>Чек-листы</H3>
            <H5>Грузовик</H5>
            {truckCheck.map((item, i) => (
                <Hflex key={i} align="flex-end">
                    <InputText
                        value={item}
                        onChange={(val) => {
                            setTruckCheck(
                                truckCheck.map((item, index) =>
                                    index !== i ? item : val
                                )
                            )
                        }}
                        style={{ width: '100%' }}
                    >
                        Условие №{i + 1}
                    </InputText>
                    <ButtonRed
                        onClick={() =>
                            setTruckCheck(
                                truckCheck.filter((_, index) => index !== i)
                            )
                        }
                    >
                        Удалить
                    </ButtonRed>
                </Hflex>
            ))}
            <Hflex>
                <ButtonBlack onClick={() => setTruckCheck([...truckCheck, ''])}>
                    Добавить
                </ButtonBlack>
            </Hflex>
            <H5>Прицеп</H5>
            {trailerCheck.map((item, i) => (
                <Hflex key={i} align="flex-end">
                    <InputText
                        value={item}
                        onChange={(val) => {
                            setTrailerCheck(
                                trailerCheck.map((item, index) =>
                                    index !== i ? item : val
                                )
                            )
                        }}
                        style={{ width: '100%' }}
                    >
                        Условие №{i + 1}
                    </InputText>
                    <ButtonRed
                        onClick={() =>
                            setTrailerCheck(
                                trailerCheck.filter((_, index) => index !== i)
                            )
                        }
                    >
                        Удалить
                    </ButtonRed>
                </Hflex>
            ))}
            <Hflex>
                <ButtonBlack
                    onClick={() => setTrailerCheck([...trailerCheck, ''])}
                >
                    Добавить
                </ButtonBlack>
            </Hflex>
            <Button onClick={onSave}>Сохранить</Button>
        </Vflex>
    )
}

export default CheckListBlock
