import React from 'react'
import { Popconfirm as AntPopconfirm } from 'antd'

const Popconfirm = ({ onConfirm, children }) => (
    <AntPopconfirm
        title="Вы уверены?"
        onConfirm={onConfirm}
        okText="Да"
        cancelText="Нет"
    >
        {children}
    </AntPopconfirm>
)

export { Popconfirm }
