import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useNavigate } from 'react-router-dom'
import RepairService from '../../api/RepairService'
import RepairStore from '../../store/RepairStore'
import {
    Breadcrumb,
    InputText,
    InputNum,
    InputRange,
    Select,
    Button,
    Vflex,
    Hflex,
    Spin,
    Badge,
} from '../../ui'
import CommentBlock from './commentBlock'

const EditRepair = () => {
    const { repair, status, isProgress, hasChange } = RepairStore
    const [transports, setTransports] = useState([])
    const navigate = useNavigate()
    let { id } = useParams()
    useEffect(() => {
        async function fetchData() {
            const repairs = (await RepairService.getTransport()).data
            setTransports(repairs)
        }
        fetchData()
    }, [])
    useEffect(() => {
        if (id === 'create') {
            RepairStore.unset()
        } else {
            RepairStore.getById(id)
        }
    }, [])

    const save = async () => {
        const id = await RepairStore.save()
        navigate('/repair/' + id, { replace: true })
    }
    if (isProgress) {
        return <Spin />
    }
    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    { text: 'Ремонт', link: '/repair' },
                    { text: repair.number || 'Новый ремонт' },
                ]}
            />
            <Hflex>
                <Select
                    value={repair.transport}
                    onChange={(transport) => RepairStore.change({ transport })}
                    options={transports}
                    style={{ width: '100%' }}
                >
                    Транспорт
                </Select>
                <InputRange
                    value={[repair.startDatePlan, repair.endDatePlan]}
                    onChange={({ start, end }) =>
                        RepairStore.change({
                            startDatePlan: start,
                            endDatePlan: end,
                        })
                    }
                    style={{ width: '100%' }}
                >
                    Дата начала / окончания (план)
                </InputRange>
                <InputNum
                    value={repair.costPlan}
                    onChange={(costPlan) => RepairStore.change({ costPlan })}
                    style={{ width: '100%' }}
                >
                    Стоимость ремонта (план)
                </InputNum>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: '100%' }}
                    value={repair.mechanic}
                    onChange={(mechanic) => RepairStore.change({ mechanic })}
                    options={[
                        { value: 0, label: 'Богданов Алексей' },
                        { value: 1, label: 'Владимир Павлов' },
                        { value: 2, label: 'Михаил Новиков' },
                    ]}
                >
                    Механик
                </Select>
                <InputRange
                    value={[repair.startDateFact, repair.endDateFact]}
                    onChange={({ start, end }) =>
                        RepairStore.change({
                            startDateFact: start,
                            endDateFact: end,
                        })
                    }
                    style={{ width: '100%' }}
                >
                    Дата начала / окончания (факт)
                </InputRange>
                <InputNum
                    value={repair.costFact}
                    onChange={(costFact) => RepairStore.change({ costFact })}
                    style={{ width: '100%' }}
                >
                    Стоимость ремонта (факт)
                </InputNum>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: '100%' }}
                    value={repair.type}
                    onChange={(type) => RepairStore.change({ type })}
                    options={[
                        { value: 0, label: 'ТО' },
                        { value: 1, label: 'Плановый ремонт' },
                        { value: 2, label: 'Внеплановый ремонт' },
                    ]}
                >
                    Вид ремонта
                </Select>
                <InputText
                    value={repair.reason}
                    onChange={(reason) => RepairStore.change({ reason })}
                    style={{ width: '100%' }}
                >
                    Причина ремонта
                </InputText>
                <Select
                    style={{ width: '100%' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={repair.status}
                    onChange={(status) => RepairStore.change({ status })}
                >
                    Статус
                </Select>
            </Hflex>
            <Hflex>
                <InputText
                    value={repair.place}
                    onChange={(place) => RepairStore.change({ place })}
                    style={{ width: 'calc(100%/3)' }}
                >
                    Место ремонта
                </InputText>
            </Hflex>
            <Button disabled={!hasChange} onClick={save}>
                Сохранить
            </Button>
            {repair.id && <CommentBlock />}
        </Vflex>
    )
}

export default observer(EditRepair)
