import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'load',
        title: 'Погрузка/выгрузка',
        editable: true,
    },
    {
        dataIndex: 'repair',
        title: 'Ремонт',
        editable: true,
    },
    {
        dataIndex: 'wait',
        title: 'Ожидание',
        editable: true,
    },
    {
        dataIndex: 'abroad',
        title: 'Зарубеж',
        editable: true,
    },
    {
        dataIndex: 'total',
        title: 'Итого простоя',
        render: (_) => currencyFormatter(_),
    },
]

const WaitTable = () => {
    const {
        planSalaryPerday,
        planWaitLoad,
        planWaitRepair,
        planWaitWait,
        planWaitAbroad,
        logistics: { days },
    } = RouteStore.route
    const load = days.filter(
        (item) => item.status == 2 || item.status == 3
    ).length
    const repair = days.filter((item) => item.status == 5).length
    const wait = days.filter(
        (item) => item.status == 7 && item.waitAbroad == 0
    ).length
    const abroad = days.filter(
        (item) => item.status == 7 && item.waitAbroad != 0
    ).length
    const total = days
        .filter((item) => item.status)
        .reduce((a, b) => a + b.waitRus + b.waitAbroad, 0)
    return (
        <>
            <H4>Простои</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        load: planWaitLoad,
                        repair: planWaitRepair,
                        wait: planWaitWait,
                        abroad: planWaitAbroad,
                        total:
                            planSalaryPerday *
                            (planWaitLoad +
                                planWaitRepair +
                                planWaitWait +
                                planWaitAbroad),
                        changeble: true,
                    },
                    { id: 2, load, repair, wait, abroad, total },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planWaitLoad: data[0].load,
                        planWaitRepair: data[0].repair,
                        planWaitWait: data[0].wait,
                        planWaitAbroad: data[0].abroad,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(WaitTable)
