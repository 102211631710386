import React from 'react'
import RouteStore from '../../store/RouteStore'
import { EditableTable } from '../../ui'

const columns = [
    {
        title: '',
        dataIndex: 'id',
        rowScope: 'row',
    },
    {
        title: 'Пробег',
        dataIndex: 'mileage',
        editable: true,
    },
    {
        title: '₽/км',
        dataIndex: 'cost',
        editable: true,
    },
    {
        title: 'Выгрузка',
        dataIndex: 'discharge',
        editable: true,
    },
    {
        title: 'Простой без водителя',
        dataIndex: 'plain',
        editable: true,
    },
    {
        title: 'Ремонт',
        dataIndex: 'repair',
        editable: true,
    },
    {
        title: 'Погрузка-выгрузка',
        dataIndex: 'loading',
        editable: true,
    },
    {
        title: 'Таможня',
        dataIndex: 'customs',
        editable: true,
    },
    {
        title: 'Стыковка',
        dataIndex: 'docking',
        editable: true,
    },
    {
        title: 'Итого простоя',
        dataIndex: 'total',
    },
]

const MainLogisticBlock = ({ logistics }) => {
    const changeLogistics = (props) => {
        RouteStore.change({
            logistics: {
                ...logistics,
                ...props,
            },
        })
        RouteStore.save()
    }
    const countDays = logistics?.days?.filter((item) => item.status).length
    const fact = countDays && {
        mileage: logistics?.days?.reduce((a, b) => a + b.mileage, 0),
        cost: 0,
        discharge: 0,
        plain: 0,
        repair: 0,
        loading: 0,
        customs: 0,
        docking: 0,
        total: 0,
    }
    const factPerDay = countDays && {
        mileage: Math.round(fact.mileage / countDays),
        cost: Math.round(fact.cost / countDays),
        discharge: Math.round(fact.discharge / countDays),
        plain: Math.round(fact.plain / countDays),
        repair: Math.round(fact.repair / countDays),
        loading: Math.round(fact.loading / countDays),
        customs: Math.round(fact.customs / countDays),
        docking: Math.round(fact.docking / countDays),
        total: Math.round(fact.total / countDays),
    }
    return (
        <EditableTable
            columns={columns}
            changebleDataIndex="changeble"
            dataSource={[
                {
                    id: 'План',
                    mileage: logistics?.plan?.mileage,
                    cost: logistics?.plan?.cost,
                    discharge: logistics?.plan?.discharge,
                    plain: logistics?.plan?.plain,
                    repair: logistics?.plan?.repair,
                    loading: logistics?.plan?.loading,
                    customs: logistics?.plan?.customs,
                    docking: logistics?.plan?.docking,
                    total:
                        (logistics?.plan?.discharge || 0) +
                        (logistics?.plan?.plain || 0) +
                        (logistics?.plan?.repair || 0) +
                        (logistics?.plan?.loading || 0) +
                        (logistics?.plan?.customs || 0) +
                        (logistics?.plan?.docking || 0),
                    changeble: true,
                },
                {
                    id: 'План 2',
                    mileage: logistics?.fact?.mileage,
                    cost: logistics?.fact?.cost,
                    discharge: logistics?.fact?.discharge,
                    plain: logistics?.fact?.plain,
                    repair: logistics?.fact?.repair,
                    loading: logistics?.fact?.loading,
                    customs: logistics?.fact?.customs,
                    docking: logistics?.fact?.docking,
                    total:
                        (logistics?.fact?.discharge || 0) +
                        (logistics?.fact?.plain || 0) +
                        (logistics?.fact?.repair || 0) +
                        (logistics?.fact?.loading || 0) +
                        (logistics?.fact?.customs || 0) +
                        (logistics?.fact?.docking || 0),
                    changeble: true,
                },
                {
                    id: 'Факт',
                    ...fact,
                },
                {
                    id: 'В сутки',
                    ...factPerDay,
                },
            ]}
            setData={(data) =>
                changeLogistics({ plan: data[0], fact: data[1] })
            }
            bordered
            pagination={false}
        />
    )
}

export default MainLogisticBlock
