import React, { useState } from 'react'
import { Modal, ButtonOut, InputText, Vflex } from '../../ui'
import CompanyStore from '../../store/CompanyStore'

const AddContactModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [position, setPosition] = useState()
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [note, setNote] = useState()
    const showModal = () => {
        setPosition()
        setName()
        setPhone()
        setEmail()
        setNote()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        CompanyStore.addContact({ position, name, phone, email, note })
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal}>Добавить</ButtonOut>
            <Modal
                title="Добавить контакт"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled: !position || !name || !phone || !email,
                }}
            >
                <Vflex>
                    <InputText
                        style={{ width: '100%' }}
                        value={position}
                        onChange={setPosition}
                    >
                        Должность
                    </InputText>
                    <InputText
                        style={{ width: '100%' }}
                        value={name}
                        onChange={setName}
                    >
                        ФИО
                    </InputText>
                    <InputText
                        style={{ width: '100%' }}
                        value={phone}
                        onChange={setPhone}
                    >
                        Номер телефона
                    </InputText>
                    <InputText
                        style={{ width: '100%' }}
                        value={email}
                        onChange={setEmail}
                    >
                        Емейл
                    </InputText>
                    <InputText
                        style={{ width: '100%' }}
                        value={note}
                        onChange={setNote}
                    >
                        Заметка
                    </InputText>
                </Vflex>
            </Modal>
        </>
    )
}

export default AddContactModal
