import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import RepairService from '../api/RepairService'

class RepairStore {
    isProgress = false
    hasChange = false
    repair = {}
    status = [
        { value: 1, text: 'Окончен', color: 'green' },
        { value: 2, text: 'В процессе', color: 'orange' },
        { value: 3, text: 'Запланирован', color: 'red' },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(repair) {
        this.repair = repair
        this.hasChange = false
    }

    change(props) {
        this.repair = { ...this.repair, ...props }
        this.hasChange = true
    }

    unset() {
        this.repair = {}
        this.hasChange = false
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const repair = (await RepairService.getById(id)).data
            if (repair.id) {
                repair.startDatePlan =
                    repair.startDatePlan && dayjs(repair.startDatePlan)
                repair.endDatePlan =
                    repair.endDatePlan && dayjs(repair.endDatePlan)
                repair.startDateFact =
                    repair.startDateFact && dayjs(repair.startDateFact)
                repair.endDateFact =
                    repair.endDateFact && dayjs(repair.endDateFact)
                this.set(repair)
            }
        } catch (err) {
            console.log('Repair get error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const repair = (await RepairService.save(this.repair)).data
            if (repair.id) {
                this.change(repair)
                this.hasChange = false
                return repair.id
            }
        } catch (err) {
            console.log('Repair save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async addComment({ done, cost, file }) {
        this.setIsProgress(true)
        console.log(file)
        try {
            const newComment = (
                await RepairService.addComment({
                    repairId: this.repair.id,
                    done,
                    cost,
                    file: file?.uid,
                })
            ).data
            if (newComment) {
                if (this.repair.comments?.length) {
                    this.repair.comments = [newComment, ...this.repair.comments]
                } else {
                    this.repair.comments = [newComment]
                }
            }
        } catch (err) {
            console.log('Repair save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await RepairService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Repair delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new RepairStore()
