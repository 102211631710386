import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import TransportStore from '../../store/TransportStore'
import {
    InputText,
    InputNum,
    InputDate,
    Tabs,
    Vflex,
    Modal,
    Button,
    Spin,
} from '../../ui'

const CreateTransportModal = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    useEffect(() => {
        TransportStore.set({ type: 1, status: 1 })
    }, [])

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = async () => {
        await TransportStore.save()
        navigate('/transport/' + TransportStore.transport.id)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const tabs = [
        {
            key: '1',
            label: 'Тягач',
        },
        {
            key: '2',
            label: 'Прицеп',
        },
    ]

    return (
        <>
            <Button onClick={showModal}>Создать</Button>
            <Modal
                title="Создание маршрута"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                okButtonProps={{
                    disabled:
                        TransportStore.isProgress ||
                        !TransportStore.transport.brand ||
                        !TransportStore.transport.model ||
                        !TransportStore.transport.gosnumber ||
                        !TransportStore.transport.release ||
                        !TransportStore.transport.mileage,
                }}
                cancelText="Отмена"
                cancelButtonProps={{ disabled: TransportStore.isProgress }}
            >
                <Vflex>
                    <Tabs
                        items={tabs}
                        value={TransportStore.transport.type}
                        onChange={(type) => TransportStore.change({ type })}
                    />
                    <InputText
                        value={TransportStore.transport.brand}
                        onChange={(brand) => TransportStore.change({ brand })}
                        disabled={TransportStore.isProgress}
                    >
                        Марка
                    </InputText>
                    <InputText
                        value={TransportStore.transport.model}
                        onChange={(model) => TransportStore.change({ model })}
                        disabled={TransportStore.isProgress}
                    >
                        Модель
                    </InputText>
                    <InputText
                        value={TransportStore.transport.gosnumber}
                        onChange={(gosnumber) =>
                            TransportStore.change({ gosnumber })
                        }
                        disabled={TransportStore.isProgress}
                    >
                        Госномер
                    </InputText>
                    <InputDate
                        picker="year"
                        placeholder="2000"
                        format="YYYY"
                        maxDate={dayjs()}
                        value={
                            TransportStore.transport.release &&
                            dayjs(
                                TransportStore.transport.release.toString(),
                                'YYYY'
                            )
                        }
                        onChange={(val) =>
                            TransportStore.change({ release: val.year() })
                        }
                        disabled={TransportStore.isProgress}
                    >
                        Год выпуска
                    </InputDate>
                    <InputNum
                        value={TransportStore.transport.mileage}
                        onChange={(mileage) =>
                            TransportStore.change({ mileage })
                        }
                        disabled={TransportStore.isProgress}
                    >
                        Пробег, км
                    </InputNum>
                </Vflex>
                {TransportStore.isProgress && <Spin />}
            </Modal>
        </>
    )
}

export default observer(CreateTransportModal)
