import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import PurchaseService from '../../api/PurchaseService'
import PurchaseStore from '../../store/PurchaseStore'
import { Table, File, Spin, Badge, currencyFormatter } from '../../ui'

const columns = [
    {
        title: '№',
        dataIndex: 'number',
        sorter: (a, b) => a.number - b.number,
        render: (_, record) => <Link to={'/purchase/' + record.id}>{_}</Link>,
    },
    {
        title: 'ФИО',
        dataIndex: 'employee',
        sorter: (a, b) => a.employee.localeCompare(b.employee),
    },
    {
        title: 'Дата заявки',
        dataIndex: 'dateOpen',
        sorter: (a, b) => dayjs(a.dateOpen).diff(dayjs(b.dateOpen)),
        render: (dateOpen) => dayjs(dateOpen).format('DD.MM.YYYY'),
    },
    {
        title: 'Наименование',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Кол-во',
        dataIndex: 'count',
        sorter: (a, b) => a.count - b.count,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Плановая стоимость',
        dataIndex: 'cost',
        sorter: (a, b) => a.cost - b.cost,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Ответственный',
        dataIndex: 'responsible',
        sorter: (a, b) => a.responsible.localeCompare(b.responsible),
    },
    {
        title: 'Дата закрытия заявки',
        dataIndex: 'dateClose',
        sorter: (a, b) => dayjs(a.dateClose).diff(dayjs(b.dateClose)),
        render: (dateClose) => dayjs(dateClose).format('DD.MM.YYYY'),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={PurchaseStore.status[num - 1].color}
                    text={PurchaseStore.status[num - 1].text}
                />
            ) : null,
    },
]
const expandColumns = [
    {
        title: 'Поставщик',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Факт. цена',
        dataIndex: 'cost',
        sorter: (a, b) => a.cost - b.cost,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Стоимость логистики',
        dataIndex: 'delivery',
        sorter: (a, b) => a.delivery - b.delivery,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Общая стоимость партии',
        dataIndex: 'total',
        sorter: (a, b) => a.total - b.total,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Счет',
        dataIndex: 'files',
        width: 200,
        render: (_) =>
            _ &&
            _.map((file) => (
                <div key={file.id} style={{ maxWidth: 200 }}>
                    <File id={file.id} name={file.name} />
                </div>
            )),
    },
]

const expandedRowRender = (record) => (
    <Table
        columns={expandColumns}
        dataSource={record.suppliers.map((item) => ({
            ...item,
            total: item.cost * record.count + item.delivery || 0,
        }))}
        pagination={false}
    />
)

const AllTable = () => {
    const [purchases, setRepairs] = useState()
    useEffect(() => {
        async function fetchData() {
            const purchases = (await PurchaseService.getAll()).data
            setRepairs(purchases)
        }
        fetchData()
    }, [])

    if (!purchases) {
        return <Spin />
    }
    return (
        <Table
            columns={columns}
            dataSource={purchases}
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0'],
            }}
            showSorterTooltip={{
                target: 'sorter-icon',
            }}
        />
    )
}

export default AllTable
