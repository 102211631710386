import React, { useState } from 'react'
import { Modal, ButtonBlack, InputText, InputNum } from '../../ui'
import PurchaseStore from '../../store/PurchaseStore'

const AddSupplierModal = ({ full }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [communication, setCommunication] = useState()
    const [cost, setCost] = useState()
    const [delivery, setDelivery] = useState()
    const showModal = () => {
        setName()
        setCommunication()
        setCost()
        setDelivery()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        let props = { name, communication, payTime: 1 }
        if (full) {
            props = { ...props, cost, delivery }
        }
        PurchaseStore.addSupplier(props)
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonBlack onClick={showModal}>Добавить</ButtonBlack>
            <Modal
                title="Добавить поставщика"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled:
                        name?.length < 1 ||
                        communication?.length < 1 ||
                        (full && (!cost || !delivery)),
                }}
            >
                <InputText value={name} onChange={setName}>
                    Название
                </InputText>
                <InputText
                    value={communication}
                    onChange={setCommunication}
                >
                    Способ связи
                </InputText>
                {full && (
                    <InputNum value={cost} onChange={setCost}>
                        Цена за штуку
                    </InputNum>
                )}
                {full && (
                    <InputNum
                        value={delivery}
                        onChange={setDelivery}
                    >
                        Цена логистики
                    </InputNum>
                )}
            </Modal>
        </>
    )
}

export default AddSupplierModal
