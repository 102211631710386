import React from 'react'

import SettingsPage from '../settingsPage.jsx'
import { H3 } from '../../ui/text.jsx'
import DebitPage from '../finance/debitPage.jsx'
import CreditPage from '../finance/creditPage.jsx'
import PaymentPage from '../finance/paymentPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'
import EditRoute from '../routes/editRoute.jsx'
import EditPurchase from '../purchase/editPurchase.jsx'

export default [
    {
        path: '/',
        page: <H3>Дашборд</H3>,
        label: 'Дашборд',
    },
    {
        path: '/debt',
        page: <DebitPage />,
        label: 'Дебиторская задолженность',
    },
    {
        path: '/credit',
        page: <CreditPage />,
        label: 'Кредиторская задолженность',
    },
    {
        path: '/calendar',
        page: <PaymentPage />,
        label: 'Платежный календарь',
    },
    {
        path: '/route/:id',
        page: <EditRoute />,
    },
    {
        path: '/purchase/:id',
        page: <EditPurchase />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
