import React from 'react'
import { Vflex, H3 } from '../ui'

const SettingsPage = () => {
    return (
        <Vflex>
            <H3>Настройки</H3>
        </Vflex>
    )
}

export default SettingsPage
