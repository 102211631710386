import React from 'react'
import { Vflex } from '../../ui'
import MileageTable from './costTables/mileageTable'
import FuelTable from './costTables/fuelTable'
import GeneralTable from './costTables/generalTable'
import SalaryTable from './costTables/salaryTable'
import HotelTable from './costTables/hotelTable'
import AddPointTable from './costTables/addPointTable'
import WaitTable from './costTables/waitTable'
import OtherTable from './costTables/otherTable'
import AbroadTable from './costTables/abroadTable'
import RepairTable from './costTables/repairTable'

const CostTab = () => (
    <Vflex>
        <GeneralTable />
        <MileageTable />
        <FuelTable />
        <SalaryTable />
        <HotelTable />
        <AddPointTable />
        <WaitTable />
        <OtherTable />
        <AbroadTable />
        <RepairTable />
    </Vflex>
)

export default CostTab
