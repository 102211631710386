import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable } from '../../../ui'

const columns = [
    {
        dataIndex: 'full',
        title: 'Рейсовый пробег',
        editable: true,
    },
    {
        dataIndex: 'empty',
        title: 'Порожний пробег',
        editable: true,
    },
    {
        dataIndex: 'total',
        title: 'Общий пробег',
        render: (_, record) => record.full + record.empty,
    },
]

const MileageTable = () => {
    const {
        planMileageFull,
        planMileageEmpty,
        logistics: { days },
    } = RouteStore.route
    const factMileageFull = days
        .filter((item) => item.status && item.status !== 6)
        .reduce((a, b) => a + b.mileage, 0)
    const factMileageEmpty = days
        .filter((item) => item.status && item.status == 6)
        .reduce((a, b) => a + b.mileage, 0)
    return (
        <>
            <H4>Пробег</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        full: planMileageFull,
                        empty: planMileageEmpty,
                        changeble: true,
                    },
                    { id: 2, full: factMileageFull, empty: factMileageEmpty },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planMileageFull: data[0].full,
                        planMileageEmpty: data[0].empty,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(MileageTable)
