import React, { useState } from 'react'
import { Table as AntTable, Form } from 'antd'
import { Vflex, Link, InputNum, Popconfirm } from './'

const Table = ({ columns, dataSource, ...props }) => (
    <AntTable
        rowKey={(row) => row.id}
        size="small"
        columns={columns}
        dataSource={dataSource}
        {...props}
    />
)

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputNode,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Заполните ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

const EditableTable = ({
    columns,
    dataSource,
    setData,
    changebleDataIndex,
    ...props
}) => {
    const [form] = Form.useForm()
    const [editingKey, setEditingKey] = useState('')
    const isEditing = (record) => record.id === editingKey
    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record,
        })
        setEditingKey(record.id)
    }
    const cancel = () => {
        setEditingKey('')
    }
    const save = async (id) => {
        try {
            const row = await form.validateFields()
            const newData = [...dataSource]
            const index = newData.findIndex((item) => id === item.id)
            if (index > -1) {
                const item = newData[index]
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                })
                setData(newData)
                setEditingKey('')
            } else {
                newData.push(row)
                setData(newData)
                setEditingKey('')
            }
        } catch (errInfo) {}
    }

    const lastColumn = {
        title: '',
        dataIndex: changebleDataIndex || 'id',
        fixed: 'right',
        width: 120,
        render: (_, record) => {
            if (!_) return ''
            const editable = isEditing(record)
            return editable ? (
                <Vflex>
                    <Link
                        onClick={() => save(record.id)}
                        style={{
                            marginInlineEnd: 8,
                        }}
                    >
                        Сохранить
                    </Link>
                    <Popconfirm title="Вы уверены?" onConfirm={cancel}>
                        <a>Отмена</a>
                    </Popconfirm>
                </Vflex>
            ) : (
                <Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                    Изменить
                </Link>
            )
        },
    }
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputNode: col.inputNode || <InputNum />,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        }
    })
    mergedColumns.push(lastColumn)
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={dataSource}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
                {...props}
            />
        </Form>
    )
}

const Summary = AntTable.Summary
export { Table, EditableTable, Summary }
