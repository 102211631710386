import React from 'react'

import DirectorDashboardPage from '../director/directorDashboardPage.jsx'
import PurchasePage from '../purchase/purchasePage.jsx'
import EditPurchase from '../purchase/editPurchase.jsx'
import SettingsPage from '../director/settingsPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'

export default [
    {
        path: '/',
        page: <DirectorDashboardPage />,
        label: 'Дашборд',
    },
    {
        path: '/purchase',
        page: <PurchasePage />,
        label: 'Заявки на закупку',
    },
    {
        path: '/purchase/:id',
        page: <EditPurchase />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
