import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable } from '../../../ui'

const columns = [
    {
        dataIndex: 'expenditure',
        title: 'Расход',
        editable: true,
    },
    {
        dataIndex: 'cost',
        title: 'Стоимость литра',
        editable: true,
    },

    {
        dataIndex: 'total',
        title: 'Сумма ГСМ',
    },
]

const FuelTable = () => {
    const {
        planFuelExpenditure,
        planFuelCost,
        logistics: { days },
    } = RouteStore.route
    const activeDays = days.filter((item) => item.status)
    const factTotal = activeDays.reduce(
        (a, b) => a + b.mileage * b.consumption * b.costPerLiter,
        0
    )
    const factFuelExpenditure = activeDays.reduce(
        (a, b) => a + b.mileage * b.consumption,
        0
    )

    return (
        <>
            <H4>ГСМ</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        expenditure: planFuelExpenditure,
                        cost: planFuelCost,
                        total: planFuelExpenditure * planFuelCost,
                        changeble: true,
                    },
                    {
                        id: 2,
                        expenditure: factFuelExpenditure,
                        cost: Math.round(factTotal / factFuelExpenditure),
                        total: factTotal,
                    },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planFuelExpenditure: data[0].expenditure,
                        planFuelCost: data[0].cost,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(FuelTable)
