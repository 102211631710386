import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NotificationService from '../../api/NotificationService'
import { Vflex, H3, Text, Button, Hflex, Spin } from '../../ui'
import AuthStore from '../../store/AuthStore'

const ItemNotificationPage = () => {
    const navigate = useNavigate()
    let { id } = useParams()
    const [notification, setNotification] = useState()
    useEffect(() => {
        async function fetchData() {
            const notification = (await NotificationService.getById(id)).data
            setNotification(notification)
            AuthStore.checkNotification()
        }
        fetchData()
    }, [])

    if (!notification) {
        return <Spin />
    }
    return (
        <Vflex>
            <H3>{notification.title}</H3>
            <Text type="secondary">{notification.date}</Text>
            <Text>{notification.message}</Text>
            <Hflex>
                <Button onClick={() => navigate(notification.link)}>
                    Перейти
                </Button>
            </Hflex>
        </Vflex>
    )
}

export default ItemNotificationPage
