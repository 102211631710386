import React, { useState } from 'react'
import EmployeeStore from '../../store/EmployeeStore'
import {
    Vflex,
    Hflex,
    Bflex,
    Modal,
    H4,
    Text,
    InputDate,
    ButtonOut,
    ButtonRed,
    Popconfirm,
} from '../../ui'

const EditModal = ({ typePeriod, item }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const showModal = () => {
        setStart(item?.start)
        setEnd(item?.end)
        setIsModalOpen(true)
    }

    const handleOk = () => {
        if (!item) {
            EmployeeStore.addNonWorking({ typePeriod, start, end })
        } else {
            EmployeeStore.changeNonWorking({ id: item._id, start, end })
        }
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal} size={item && 'small'}>
                {!item
                    ? `Добавить ${typePeriod === 'O' ? 'отпуск' : 'больничный'}`
                    : 'Редактировать'}
            </ButtonOut>
            <Modal
                title={`${!item ? 'Добавить' : 'Редактировать'} ${typePeriod === 'O' ? 'отпуск' : 'больничный'}`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{ disabled: !start || !end }}
            >
                <InputDate
                    value={start}
                    maxDate={end}
                    onChange={setStart}
                >
                    Начало
                </InputDate>
                <InputDate
                    value={end}
                    minDate={start}
                    onChange={setEnd}
                >
                    Окончание
                </InputDate>
            </Modal>
        </>
    )
}

const NonWorkingTable = ({ items, typePeriod }) => (
    <Vflex style={{ width: '50%' }}>
        <Bflex align="center">
            <H4 style={{ margin: 0 }}>
                {typePeriod === 'O' ? 'Отпуска' : 'Больничные'}
            </H4>
            <EditModal typePeriod={typePeriod} isNew />
        </Bflex>
        {!items || items.length === 0
            ? typePeriod === 'O'
                ? 'Ещё не было отпусков'
                : 'Ещё не было больничных'
            : items?.map((item) => (
                  <Bflex
                      key={item._id}
                      style={{
                          width: '100%',
                          border: '1px solid #D9D9D9',
                          borderRadius: 6,
                          padding: '10px',
                      }}
                  >
                      <Text>
                          {item.start.format('DD.MM.YYYY') +
                              ' — ' +
                              item.end.format('DD.MM.YYYY')}
                      </Text>
                      <Hflex>
                          <Popconfirm
                              onConfirm={() =>
                                  EmployeeStore.deleteNonWorking(item._id)
                              }
                          >
                              <ButtonRed size="small">Удалить</ButtonRed>
                          </Popconfirm>
                          <EditModal typePeriod={typePeriod} item={item} />
                      </Hflex>
                  </Bflex>
              ))}
    </Vflex>
)

const NonWorkingBlock = ({ items }) => (
    <Hflex align="flex-start" gap="large" style={{ marginTop: 30 }}>
        <NonWorkingTable
            typePeriod="O"
            items={items?.filter(({ typePeriod }) => typePeriod === 'O')}
        />
        <NonWorkingTable
            typePeriod="B"
            items={items?.filter(({ typePeriod }) => typePeriod === 'B')}
        />
    </Hflex>
)

export default NonWorkingBlock
