import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'cost',
        title: 'Проживание',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'days',
        title: 'Дни',
    },
    {
        dataIndex: 'average',
        title: 'Средняя стоимость',
        render: (_, record) =>
            currencyFormatter(Math.round(record.cost / record.days)),
    },
]

const HotelTable = () => {
    const {
        endDatePlan,
        startDatePlan,
        planHotelCost,
        logistics: { days },
    } = RouteStore.route
    const planDays = endDatePlan.diff(startDatePlan, 'days')
    const activeDays = days.filter((item) => item.status)
    const factCost = activeDays.reduce((a, b) => a + b.hotel, 0)
    return (
        <>
            <H4>Гостиница</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        cost: planHotelCost,
                        days: planDays,
                        changeble: true,
                    },
                    { id: 2, cost: factCost, days: activeDays.length },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planHotelCost: data[0].cost,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(HotelTable)
