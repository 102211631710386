import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import VacancyService from '../api/VacancyService'

class VacancyStore {
    isProgress = false
    hasChange = false
    vacancy = {}
    type = [
        { value: 1, label: 'Водитель' },
        { value: 2, label: 'Другие сотрудники' },
    ]
    status = [
        { value: 1, text: 'Открыта', color: 'green' },
        { value: 2, text: 'На паузе', color: 'orange' },
        { value: 3, text: 'Закрыта', color: 'red' },
    ]

    candidatStatus = [
        { value: 1, text: 'Собеседование', color: 'orange' },
        { value: 2, text: 'Оформление', color: 'blue' },
        { value: 3, text: 'Резерв', color: 'grey' },
        { value: 4, text: 'Трудоустроен', color: 'green' },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(vacancy) {
        this.vacancy = vacancy
        this.hasChange = false
    }

    change(props) {
        this.vacancy = { ...this.vacancy, ...props }
        this.hasChange = true
    }

    unset() {
        this.vacancy = {}
        this.hasChange = false
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const vacancy = (await VacancyService.getById(id)).data
            if (vacancy.id) {
                vacancy.dateCreate =
                    vacancy.dateCreate && dayjs(vacancy.dateCreate)
                vacancy.candidates = vacancy.candidates.map((candidate) => ({
                    ...candidate,
                    birstday: candidate.birstday && dayjs(candidate.birstday),
                    passportEnd:
                        candidate.passportEnd && dayjs(candidate.passportEnd),
                }))
                this.set(vacancy)
            }
        } catch (err) {
            console.log('getVacancy error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const vacancy = (await VacancyService.save(this.vacancy)).data
            if (vacancy.id) {
                this.change(vacancy)
                this.hasChange = false
                return vacancy.id
            }
        } catch (err) {
            console.log('Vacancy save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async addComment(text) {
        this.setIsProgress(true)
        try {
            const newComment = (
                await VacancyService.addComment({
                    vacancyId: this.vacancy.id,
                    text,
                })
            ).data
            if (newComment) {
                if (this.vacancy.comments.length) {
                    this.vacancy.comments = [
                        newComment,
                        ...this.vacancy.comments,
                    ]
                } else {
                    this.vacancy.comments = [newComment]
                }
            }
        } catch (err) {
            console.log('Vacancy save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await VacancyService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Vacancy delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new VacancyStore()
