import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Layout, Affix, Menu, Space, Button, Flex } from 'antd'
import { Text, Select, Hflex, Notification } from './'
import AuthStore from '../store/AuthStore'
import Logo from './icons/logo.svg'
const { Header } = Layout

const Navbar = observer(({ items }) => {
    let location = useLocation()
    const [selected, setSelected] = useState(['/'])
    useEffect(() => {
        setSelected(['/' + location.pathname.split('/')[1]])
    }, [location])
    return (
        <Affix>
            <Header
                style={{
                    height: 50,
                    lineHeight: '50px',
                    padding: '0 30px',
                    background: '#001529',
                }}
            >
                <Flex align="center" style={{ height: 50 }}>
                    <Space align="center">
                        <Logo style={{ display: 'block' }} />
                        <Text
                            strong
                            style={{
                                display: 'block',
                                color: 'white',
                                opacity: 0.6,
                                fontSize: 16,
                            }}
                        >
                            K-IT
                        </Text>
                    </Space>
                    {AuthStore.isAuth && (
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            items={items}
                            selectedKeys={selected}
                            style={{
                                minWidth: 0,
                                flex: 'auto',
                                marginLeft: 12,
                            }}
                        />
                    )}
                    {AuthStore.isAuth && (
                        <Hflex
                            align="center"
                            gap="large"
                            style={{ marginLeft: 'auto' }}
                        >
                            <Link to="/notifications">
                                <Notification
                                    count={AuthStore.notificationCount}
                                />
                            </Link>
                            <Text
                                strong
                                style={{
                                    display: 'block',
                                    color: 'white',
                                    fontSize: 12,
                                }}
                            >
                                {AuthStore.user.email}
                            </Text>
                            {AuthStore.user.isAdmin && (
                                <Select
                                    style={{ width: 120 }}
                                    value={AuthStore.user.role}
                                    onChange={(role) =>
                                        AuthStore.changeRole(role)
                                    }
                                    options={AuthStore.roles}
                                />
                            )}
                            {AuthStore.user.isAdmin && (
                                <Text
                                    strong
                                    style={{
                                        display: 'block',
                                        color: 'white',
                                        fontSize: 12,
                                    }}
                                >
                                    {AuthStore.user.company.name}
                                </Text>
                            )}
                            <Button
                                style={{
                                    backgroundColor:
                                        'rgba(255, 255, 255, 0.06)',
                                    color: '#fff',
                                    border: 'none',
                                }}
                                onClick={() => AuthStore.logout()}
                            >
                                Выйти
                            </Button>
                        </Hflex>
                    )}
                </Flex>
            </Header>
        </Affix>
    )
})

export default Navbar
