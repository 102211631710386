import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Bflex, H4, Table } from '../../ui'
import TiresBlockAddAuditModal from './tiresAddAuditModal'

const TiresBlock = ({ dataSource, transports }) => {
    const columns = [
        {
            title: 'Номер шины',
            dataIndex: 'num',
            rowScope: 'row',
        },
        {
            title: 'Дата аудита',
            dataIndex: 'auditDate',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
            title: 'Пробег шины',
            dataIndex: 'distance',
        },
        {
            title: 'Дата замены',
            dataIndex: 'replace',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
            title: 'Дата нарезки',
            dataIndex: 'update',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
            title: 'Следующая замена',
            dataIndex: 'next',
            render: (date) => dayjs(date).format('DD.MM.YYYY'),
        },
        {
            title: 'Место замены',
            dataIndex: 'place',
        },
        {
            title: 'Номер оси',
            dataIndex: 'axis',
        },
        {
            title: 'Сторона',
            dataIndex: 'side',
            render: (side) =>
                side === 'left' ? 'Слева' : side === 'right' ? 'Справа' : '',
        },
        {
            title: 'Откуда',
            dataIndex: 'from',
            render: (id) => {
                const current = transports.find((item) => item.value === id)
                return id && current ? (
                    <Link to={'/transport/' + id}>{current.label}</Link>
                ) : (
                    'Новая'
                )
            },
        },
    ]
    const data = !dataSource
        ? []
        : Array.from(Map.groupBy(dataSource, ({ num }) => num)).map((item) => ({
              ...item[1][item[1].length - 1],
              children: item[1].length !== 1 && item[1].slice(0, -1),
          }))
    return (
        <>
            <Bflex>
                <H4>Аудит шин</H4>
                <TiresBlockAddAuditModal transports={transports} />
            </Bflex>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                rowKey={(row) => row.num}
            />
        </>
    )
}

export default TiresBlock
