import instance from './index'

const rootPath = '/api/company/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getManagers: () => {
        return instance.get(rootPath + 'managers')
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    save: (company) => {
        return instance.post(rootPath + 'save', { company })
    },

    addComment: (comment) => {
        return instance.post(rootPath + 'addComment', comment)
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
