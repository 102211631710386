import React, { useState } from 'react'
import { Modal, ButtonOut, InputText, InputDate } from '../../ui'
import EmployeeStore from '../../store/EmployeeStore'

const AddVisaModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [date, setDate] = useState()
    const [note, setNote] = useState()
    const showModal = () => {
        setDate()
        setNote()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        EmployeeStore.addVisa({ date, note })
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal}>Добавить визу</ButtonOut>
            <Modal
                title="Добавить визу"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{ disabled: !date }}
            >
                <InputDate value={date} onChange={setDate}>
                    Дата окончания
                </InputDate>
                <InputText
                    style={{ width: '100%' }}
                    value={note}
                    onChange={setNote}
                >
                    Заметка
                </InputText>
            </Modal>
        </>
    )
}

export default AddVisaModal
