import hr from './hr'
import logist from './logist'
import mechanic from './mechanic'
import driver from './driver'
import finance from './finance'
import purchase from './purchase'
import director from './director'
import admin from './admin'

const getRouters = (role) => {
    switch (role) {
        case 'hr':
            return hr
        case 'logist':
            return logist
        case 'mechanic':
            return mechanic
        case 'driver':
            return driver
        case 'finance':
            return finance
        case 'purchase':
            return purchase
        case 'director':
            return director
        case 'admin':
            return admin
        default:
            return []
    }
}

export { getRouters }
