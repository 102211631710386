import React from 'react'

import TransportPage from '../transport/transportPage.jsx'
import EditTransport from '../transport/editTransport.jsx'
import ReportPage from '../transport/reportPage.jsx'
import RoutesPage from '../routes/routesPage.jsx'
import EditRoute from '../routes/editRoute.jsx'
import RepairPage from '../repair/repairPage.jsx'
import EditRepair from '../repair/editRepair.jsx'
import PurchasePage from '../purchase/purchasePage.jsx'
import EditPurchase from '../purchase/editPurchase.jsx'
import SettingsPage from '../settingsPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'
import DashboardPage from '../transport/dashboard/dashboardPage.jsx'

export default [
    {
        path: '/',
        page: <DashboardPage />,
        label: 'Дашборд',
    },
    {
        path: '/transport',
        page: <TransportPage />,
        label: 'Транспорт',
    },
    {
        path: '/transport/:id',
        page: <EditTransport />,
    },
    {
        path: '/report',
        page: <ReportPage />,
        label: 'Табель',
    },
    {
        path: '/repair',
        page: <RepairPage />,
        label: 'Ремонт',
    },
    {
        path: '/repair/:id',
        page: <EditRepair />,
    },
    {
        path: '/routes',
        page: <RoutesPage />,
        label: 'Заявки',
    },
    {
        path: '/route/:id',
        page: <EditRoute />,
    },
    {
        path: '/purchase',
        page: <PurchasePage />,
        label: 'Закупки',
    },
    {
        path: '/purchase/:id',
        page: <EditPurchase />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
