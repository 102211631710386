import React, { useState } from 'react'
import { Modal, ButtonBlack, Checkbox } from '../../ui'

const ChecklistModal = ({ checkList, onCheck }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [checkCount, setCheckCount] = useState(0)
    
    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
        onCheck()
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonBlack type="primary" onClick={showModal}>
                Чек-лист
            </ButtonBlack>
            <Modal
                title="Чек лист готовности: Камаз"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled: checkList.length !== checkCount,
                }}
            >
                {checkList.map((item, index) => (
                    <Checkbox
                        key={index}
                        onChange={(val) =>
                            setCheckCount(checkCount + (val ? 1 : -1))
                        }
                        style={{ width: '50%', marginBottom: 5 }}
                    >
                        {item}
                    </Checkbox>
                ))}
            </Modal>
        </>
    )
}

export default ChecklistModal
