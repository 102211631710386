import React from 'react'
import {
    Typography,
    Divider as AntDivider,
    Statistic as AntStatistic,
} from 'antd'

const H3 = ({ type, style, children }) => (
    <Typography.Title
        level={3}
        type={type}
        style={{ textTransform: 'uppercase', ...style }}
    >
        {children}
    </Typography.Title>
)

const H4 = ({ type, style, children }) => (
    <Typography.Title
        level={4}
        type={type}
        style={{ textTransform: 'uppercase', ...style }}
    >
        {children}
    </Typography.Title>
)

const H5 = ({ type, style, children }) => (
    <Typography.Title
        level={5}
        type={type}
        style={{ textTransform: 'uppercase', margin: 0, ...style }}
    >
        {children}
    </Typography.Title>
)

const Text = ({ children, ...props }) => (
    <Typography.Text {...props}>{children}</Typography.Text>
)

const Link = ({ onClick, children, ...props }) => (
    <Typography.Link {...props} onClick={onClick}>
        {children}
    </Typography.Link>
)

const Statistic = (props) => <AntStatistic groupSeparator=" " {...props} />

const Divider = (props) => (
    <AntDivider {...props} style={{ margin: '12px 0' }} />
)

export { H3, H4, H5, Text, Link, Statistic, Divider }
