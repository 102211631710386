import React, { useState } from 'react'
import dayjs from 'dayjs'
import {
    Modal,
    ButtonOut,
    InputText,
    InputDate,
    InputNum,
    Select,
} from '../../ui'
import TransportStore from '../../store/TransportStore'

const TiresBlockAddAuditModal = ({ transports }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [num, setNum] = useState()
    const [auditDate, setAuditDate] = useState(dayjs())
    const [distance, setDistance] = useState()
    const [replace, setReplace] = useState()
    const [update, setUpdate] = useState()
    const [next, setNext] = useState()
    const [place, setPlace] = useState()
    const [axis, setAxis] = useState()
    const [side, setSide] = useState()
    const [from, setFrom] = useState()
    const showModal = () => {
        setAuditDate(dayjs())
        setDistance()
        setReplace()
        setUpdate()
        setNext()
        setPlace()
        setAxis()
        setSide()
        setFrom()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        TransportStore.addTires({
            num,
            auditDate,
            distance,
            replace,
            update,
            next,
            place,
            axis,
            side,
            from,
        })
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal}>Добавить аудит</ButtonOut>
            <Modal
                title="Добавить параметр ввода в эксплуатацию"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled:
                        !num ||
                        !auditDate ||
                        !distance ||
                        !replace ||
                        !update ||
                        !next ||
                        !place ||
                        !axis ||
                        !side ||
                        !from,
                }}
            >
                <InputText value={num} onChange={setNum}>
                    Номер шины
                </InputText>
                <InputDate
                    value={auditDate}
                    onChange={setAuditDate}
                >
                    Дата аудита
                </InputDate>
                <InputNum
                    value={distance}
                    onChange={setDistance}
                >
                    Пробег шины
                </InputNum>
                <InputDate
                    value={replace}
                    onChange={setReplace}
                >
                    Дата замены
                </InputDate>
                <InputDate
                    value={update}
                    onChange={setUpdate}
                >
                    Дата нарезки
                </InputDate>
                <InputDate value={next} onChange={setNext}>
                    Следующая замена
                </InputDate>
                <InputText value={place} onChange={setPlace}>
                    Место замены
                </InputText>
                <InputNum value={axis} onChange={setAxis}>
                    Номер оси
                </InputNum>
                <Select
                    value={side}
                    onChange={setSide}
                    options={[
                        { value: 'left', label: 'Слева' },
                        { value: 'right', label: 'Справа' },
                    ]}
                >
                    Сторона
                </Select>
                <Select
                    value={from}
                    onChange={setFrom}
                    options={transports}
                >
                    Откуда
                </Select>
            </Modal>
        </>
    )
}

export default TiresBlockAddAuditModal
