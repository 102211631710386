import React from 'react'
import { Layout } from 'antd'
import Navbar from './navbar'
const { Content } = Layout

const MainComponent = ({ navItems, children }) => {
    return (
        <Layout style={{ background: '#fff' }}>
            <Navbar items={navItems} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Content
                    style={{
                        maxWidth: 1440,
                        padding: '0 30px 20px',
                    }}
                >
                    {children}
                </Content>
            </div>
        </Layout>
    )
}

export default MainComponent
