import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Vflex, Hflex, Bflex, H3, Button, Table, Badge, Spin, currencyFormatter } from '../../ui'
import CompanyStore from '../../store/CompanyStore'
import CompanyService from '../../api/CompanyService'

const columns = [
    {
        title: 'Название',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => <Link to={'/company/' + record.id}>{_}</Link>,
    },
    {
        title: 'Менеджер',
        dataIndex: 'manager',
        sorter: (a, b) => a.manager.localeCompare(b.manager),
    },
    {
        title: 'Послед. коммуникация',
        dataIndex: 'last',
        sorter: (a, b) =>
            dayjs(a.last, 'DD.MM.YYYY') - dayjs(b.last, 'DD.MM.YYYY'),
        render: (last) => dayjs(last).fromNow(true),
    },
    {
        title: 'Баланс, ₽',
        dataIndex: 'balance',
        sorter: (a, b) => a.balance - b.balance,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Транспорт',
        dataIndex: 'transportCount',
        sorter: (a, b) => a.balance - b.balance,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Водители',
        dataIndex: 'driverCount',
        sorter: (a, b) => a.balance - b.balance,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        sorter: (a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)),
        render: (createdAt) => dayjs(createdAt).format('DD.MM.YYYY'),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={CompanyStore.status[num - 1].color}
                    text={CompanyStore.status[num - 1].text}
                />
            ) : null,
    },
]
const CompanyPage = () => {
    const navigate = useNavigate()
    const [company, setСompany] = useState()
    useEffect(() => {
        async function fetchData() {
            const company = (await CompanyService.get()).data
            setСompany(company)
        }
        fetchData()
    }, [])

    if (!company) {
        return <Spin />
    }
    return (
        <Vflex>
            <Bflex>
                <H3>Компании</H3>
                <Hflex>
                    <Button onClick={() => navigate('/company/create')}>
                        Создать
                    </Button>
                </Hflex>
            </Bflex>
            <Table
                columns={columns}
                dataSource={company}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default CompanyPage
