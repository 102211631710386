import instance from './index'

const rootPath = '/api/transport/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getDashboard: () => {
        return instance.get(rootPath + 'dashboard')
    },

    getReport: (date) => {
        return instance.post(rootPath + 'report', { date })
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    save: (transport) => {
        return instance.post(rootPath + 'save', { transport })
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
