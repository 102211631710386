import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Vflex, DashPeriod } from '../../ui/index.jsx'
import TargetTable from './indicatorTables/targetTable.jsx'
import EmployeeTable from './indicatorTables/employeeTable.jsx'
import TransportTable from './indicatorTables/transportTable.jsx'
import RepairTable from './indicatorTables/repairTable.jsx'
import BaseTable from './indicatorTables/baseTable.jsx'
import SaleTable from './indicatorTables/saleTable.jsx'
import RentTable from './indicatorTables/rentTable.jsx'

const DirectorDashboardPage = () => {
    const [date, setDate] = useState(dayjs())
    const [period, setPeriod] = useState('week')

    return (
        <Vflex>
            <DashPeriod
                date={date}
                setDate={setDate}
                period={period}
                setPeriod={setPeriod}
            />
            <TargetTable date={date} period={period} />
            <EmployeeTable date={date} period={period} />
            <TransportTable date={date} period={period} />
            <RepairTable date={date} period={period} />
            <BaseTable date={date} period={period} />
            <SaleTable date={date} period={period} />
            <RentTable date={date} period={period} />
        </Vflex>
    )
}

export default DirectorDashboardPage
