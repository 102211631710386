import React from 'react'
import { Vflex, Hflex, InputText, Text, H4, Bflex } from '../../ui'
import CompanyStore from '../../store/CompanyStore'
import AddContactModal from './addContactModal'

const ContactsBlock = ({ contacts }) => (
    <Vflex>
        <Bflex>
            <H4>Контакты</H4>
            <AddContactModal />
        </Bflex>
        {!contacts || contacts.length === 0 ? (
            <Text>Контактов нет</Text>
        ) : (
            contacts.map(({ position, name, phone, email, note }, i) => (
                <Hflex key={i}>
                    <InputText
                        value={position}
                        onChange={(position) =>
                            CompanyStore.changeContact(i, {
                                position,
                            })
                        }
                        style={{ width: '100%' }}
                    >
                        Должность
                    </InputText>
                    <InputText
                        value={name}
                        onChange={(name) =>
                            CompanyStore.changeContact(i, {
                                name,
                            })
                        }
                        style={{ width: '100%' }}
                    >
                        ФИО
                    </InputText>
                    <InputText
                        value={phone}
                        onChange={(phone) =>
                            CompanyStore.changeContact(i, {
                                phone,
                            })
                        }
                        style={{ width: '100%' }}
                    >
                        Номер телефона
                    </InputText>
                    <InputText
                        value={email}
                        onChange={(email) =>
                            CompanyStore.changeContact(i, {
                                email,
                            })
                        }
                        style={{ width: '100%' }}
                    >
                        Емейл
                    </InputText>
                    <InputText
                        value={note}
                        onChange={(note) =>
                            CompanyStore.changeContact(i, {
                                note,
                            })
                        }
                        style={{ width: '100%' }}
                    >
                        Заметка
                    </InputText>
                </Hflex>
            ))
        )}
    </Vflex>
)

export default ContactsBlock
