import React, { useState, useEffect } from 'react'
import TransportService from '../../../api/TransportService'
import { Vflex, H3, Spin } from '../../../ui'
import RepairTable from './repairTable'
import ParkTable from './parkTable'

const DashboardPage = () => {
    const [data, setData] = useState()
    const [repairs, setRepairs] = useState()
    useEffect(() => {
        async function fetchData() {
            const data = (await TransportService.getDashboard()).data
            let routes = data.routes
            let repairs = data.repairs
            let transports = data.transports.map((item) => {
                item = {
                    ...item,
                    way:
                        routes.findIndex(
                            (route) =>
                                route.truck === item.id ||
                                route.trailer === item.id
                        ) !== -1,
                    repair:
                        repairs.findIndex(
                            (repair) => repair.transport === item.id
                        ) !== -1,
                    sale: item.status == 3,
                    rent: item.status == 5,
                    rented: item.status == 6,
                }
                item.downtime = !item.way && !item.repair
                return item
            })
            let trucks = transports.filter((item) => item.type === 1)
            let trailers = transports.filter((item) => item.type === 2)
            let transportById = []
            transports.forEach((item) => (transportById[item.id] = item))
            let coupling = routes.map((item) => ({
                id: item.id,
                name:
                    transportById[item.truck].name +
                    ' + ' +
                    transportById[item.trailer].name,
                way: !(
                    transportById[item.truck].repair ||
                    transportById[item.trailer].repair
                ),
                repair:
                    transportById[item.truck].repair ||
                    transportById[item.trailer].repair,
            }))
            setData([
                {
                    id: 1,
                    name: 'Сцепка',
                    all: coupling.length,
                    way: coupling.reduce((a, b) => a + (b.way ? 1 : 0), 0),
                    repair: coupling.reduce(
                        (a, b) => a + (b.repair ? 1 : 0),
                        0
                    ),
                    children: coupling,
                },
                {
                    id: 2,
                    name: 'Тягач',
                    all: trucks.length,
                    way: trucks.reduce((a, b) => a + (b.way ? 1 : 0), 0),
                    repair: trucks.reduce((a, b) => a + (b.repair ? 1 : 0), 0),
                    downtime: trucks.reduce(
                        (a, b) => a + (b.downtime ? 1 : 0),
                        0
                    ),
                    sale: trucks.reduce((a, b) => a + (b.sale ? 1 : 0), 0),
                    rent: trucks.reduce((a, b) => a + (b.rent ? 1 : 0), 0),
                    rented: trucks.reduce((a, b) => a + (b.rented ? 1 : 0), 0),
                    children: trucks,
                },
                {
                    id: 3,
                    name: 'Прицеп',
                    all: trailers.length,
                    way: trailers.reduce((a, b) => a + (b.way ? 1 : 0), 0),
                    repair: trailers.reduce(
                        (a, b) => a + (b.repair ? 1 : 0),
                        0
                    ),
                    downtime: trailers.reduce(
                        (a, b) => a + (b.downtime ? 1 : 0),
                        0
                    ),
                    sale: trailers.reduce((a, b) => a + (b.sale ? 1 : 0), 0),
                    rent: trailers.reduce((a, b) => a + (b.rent ? 1 : 0), 0),
                    rented: trailers.reduce(
                        (a, b) => a + (b.rented ? 1 : 0),
                        0
                    ),
                    children: trailers,
                },
            ])
            setRepairs(
                repairs.map((item) => ({
                    ...item,
                    name: transportById[item.transport].name,
                    transportType: transportById[item.transport].type,
                }))
            )
        }
        fetchData()
    }, [])

    if (!data) {
        return <Spin />
    }
    console.log(data)
    return (
        <Vflex>
            <H3>Автопарк</H3>
            <ParkTable data={data} />
            <H3>Ремонты</H3>
            <RepairTable repairs={repairs} />
        </Vflex>
    )
}

export default DashboardPage
