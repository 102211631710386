import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Vflex, Table, H3, Spin } from '../../../ui/index.jsx'
import DirectorService from '../../../api/DirectorService.jsx'

const columns = [
    {
        title: 'Транспорт',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Причина',
        dataIndex: 'reason',
        sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
        title: 'Дата (план)',
        dataIndex: 'datePlan',
        sorter: (a, b) => dayjs(a.startDatePlan).diff(dayjs(b.startDatePlan)),
        render: (startDatePlan) => dayjs(startDatePlan).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата (факт)',
        dataIndex: 'dateFact',
        sorter: (a, b) => dayjs(a.endDatePlan).diff(dayjs(b.endDatePlan)),
        render: (endDatePlan) => dayjs(endDatePlan).format('DD.MM.YYYY'),
    },
    {
        title: 'Срок',
        dataIndex: 'countDays',
        sorter: (a, b) => a.countDays - b.countDays,
    },
]

const SaleTable = ({ date, period }) => {
    const [data, setData] = useState()
    
    useEffect(() => {
        async function fetchData() {
            const data = (
                await DirectorService.getSale({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setData(data)
        }
        fetchData()
    }, [date, period])

    if (!data) {
        return <Spin />
    }

    return (
        <Vflex>
            <H3>Продажа</H3>
            <Table
                columns={columns}
                dataSource={data}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default SaleTable
