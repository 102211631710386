import React, { useState, useEffect } from 'react'
import { Vflex, Table, H3, Spin } from '../../../ui/index.jsx'
import DirectorService from '../../../api/DirectorService.jsx'

const columns = [
    {
        title: '',
        dataIndex: 'name',
    },
    {
        title: 'Всего',
        dataIndex: 'all',
    },
    {
        title: 'Принято',
        dataIndex: 'all',
    },
    {
        title: 'Уволено',
        dataIndex: 'all',
    },
    {
        title: 'В рейсе',
        dataIndex: 'all',
    },
    {
        title: 'Дома',
        dataIndex: 'all',
    },
    {
        title: 'Больничный',
        dataIndex: 'all',
    },
    {
        title: 'Выходной',
        dataIndex: 'all',
    },
    {
        title: 'Коэффициент (К)',
        dataIndex: 'all',
    },
    {
        title: 'К, в рейсе',
        dataIndex: 'all',
    },
]

const EmployeeTable = ({ date, period }) => {
    const [data, setData] = useState()
    
    useEffect(() => {
        async function fetchData() {
            const data = (
                await DirectorService.getEmployee({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setData(data)
        }
        fetchData()
    }, [date, period])

    if (!data) {
        return <Spin />
    }

    return (
        <Vflex>
            <H3>Штат водителей</H3>
            <Table
                columns={columns}
                dataSource={data}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default EmployeeTable
