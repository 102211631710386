import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Vflex, Hflex, ButtonOut, Text, Textarea, H4 } from './'

const Comments = ({ comments, add }) => {
    const [comment, setComment] = useState('')
    return (
        <Vflex>
            <H4>КОММЕНТАРИИ</H4>
            <Hflex align="start">
                <Vflex style={{ width: '50%' }}>
                    {!comments || comments.length === 0 ? (
                        <Text>Комментариев нет</Text>
                    ) : (
                        comments.map(({ name, date, text }, i) => (
                            <Vflex
                                key={i}
                                style={{
                                    width: '100%',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: 6,
                                    padding: '4px 11px',
                                }}
                            >
                                <Text type="secondary">
                                    {`${name} ${dayjs(date).format('DD.MM.YYYY')}`}
                                </Text>
                                <Text>{text}</Text>
                            </Vflex>
                        ))
                    )}
                </Vflex>
                <Vflex style={{ width: '50%' }}>
                    <Textarea
                        placeholder="Комментарий..."
                        autoSize={{ minRows: 3 }}
                        value={comment}
                        onChange={setComment}
                    />
                    <ButtonOut onClick={() => add(comment)}>
                        Отправить комментарий
                    </ButtonOut>
                </Vflex>
            </Hflex>
        </Vflex>
    )
}

export { Comments }
