import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import CandidateService from '../../api/CandidateService'
import CandidateStore from '../../store/CandidateStore'
import {
    Breadcrumb,
    InputText,
    InputDate,
    Select,
    Vflex,
    Hflex,
    Badge,
    Spin,
    Button,
    Comments,
} from '../../ui'
import InformationBlock from './informationBlock'

const CandidateEdit = () => {
    const navigate = useNavigate()
    const { candidate, status, isProgress, hasChange } = CandidateStore
    const [vacancies, setVacancies] = useState([])
    let { id, vacancy } = useParams()
    let current
    useEffect(() => {
        async function fetchData() {
            const vacancies = (await CandidateService.getVacancies()).data
            setVacancies(vacancies)
        }
        fetchData()
    }, [])
    useEffect(() => {
        if (id === 'create') {
            CandidateStore.set({ vacancy })
        } else {
            async function get() {
                try {
                    await CandidateStore.getById(id)
                } catch (e) {
                    navigate('/vacancy/' + vacancy)
                }
            }
            get()
        }
    }, [id])

    const save = async () => {
        const id = await CandidateStore.save()
        navigate('/candidate/' + candidate.vacancy + '/' + id, {
            replace: true,
        })
        current = vacancies.find((item) => item.value === candidate.vacancy)
    }

    const create = async () => {
        const id = (await CandidateService.createDriver(candidate.id)).data
        navigate('/employee/' + id)
    }

    if (isProgress || vacancies.length === 0) {
        return <Spin />
    }

    current = vacancies.find((item) => item.value === vacancy)

    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    { text: 'Вакансии', link: '/vacancy' },
                    { text: current.label, link: '/vacancy/' + vacancy },
                    { text: candidate.name || 'Имя Кандидата' },
                ]}
            />
            <Hflex>
                <InputText
                    placeholder="Иванов Иван Иванович"
                    style={{ width: '100%' }}
                    value={candidate.name}
                    onChange={(name) => CandidateStore.change({ name })}
                >
                    ФИО
                </InputText>
                <InputDate
                    style={{ width: '100%' }}
                    value={candidate.birstday}
                    onChange={(birstday) => CandidateStore.change({ birstday })}
                >
                    Дата рождения
                </InputDate>
                <InputText
                    placeholder="+7 (999) 999-99-99"
                    style={{ width: '100%' }}
                    value={candidate.phone}
                    onChange={(phone) => CandidateStore.change({ phone })}
                >
                    Номер телефона
                </InputText>
                <InputText
                    placeholder="Воронеж"
                    style={{ width: '100%' }}
                    value={candidate.city}
                    onChange={(city) => CandidateStore.change({ city })}
                >
                    Город
                </InputText>
                {current.type == 1 && (
                    <Select
                        style={{
                            width: '100%',
                            minWidth: 120,
                        }}
                        options={[
                            {
                                value: true,
                                label: 'Есть',
                            },
                            {
                                value: false,
                                label: 'Нет',
                            },
                        ]}
                        value={candidate.passport}
                        onChange={(passport) =>
                            CandidateStore.change({ passport })
                        }
                    >
                        Загранпаспорт
                    </Select>
                )}
                {current.type == 1 && (
                    <InputDate
                        style={{ width: '100%' }}
                        value={candidate.passportEnd}
                        onChange={(passportEnd) =>
                            CandidateStore.change({ passportEnd })
                        }
                    >
                        Дата окончания
                    </InputDate>
                )}
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: 'calc(100%/3)' }}
                    options={vacancies}
                    value={candidate.vacancy}
                    onChange={(vacancy) => CandidateStore.change({ vacancy })}
                >
                    Вакансия
                </Select>
                <Select
                    style={{ width: 'calc(100%/3)' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={candidate.status}
                    onChange={(status) => CandidateStore.change({ status })}
                >
                    Статус
                </Select>
                <InputText
                    placeholder="Ждём"
                    style={{ width: 'calc(100%/3)' }}
                    value={candidate.tag}
                    onChange={(tag) => CandidateStore.change({ tag })}
                >
                    Тег
                </InputText>
            </Hflex>
            {current.type == 1 && !hasChange && candidate.status == 4 && (
                <Button onClick={create}>Создать водителя</Button>
            )}
            {current.type == 1 && <InformationBlock candidate={candidate} />}
            <Button disabled={!hasChange || !candidate.vacancy} onClick={save}>
                Сохранить
            </Button>
            {candidate.id && (
                <Comments
                    comments={candidate.comments}
                    add={(comment) => CandidateStore.addComment(comment)}
                />
            )}
        </Vflex>
    )
}

export default observer(CandidateEdit)
