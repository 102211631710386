import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import RepairStore from '../../store/RepairStore'
import {
    Vflex,
    Hflex,
    H4,
    InputText,
    InputNum,
    Button,
    ButtonFile,
    File,
    Table,
} from '../../ui'

const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        render: (endDateFact) => dayjs(endDateFact).format('DD.MM.YYYY'),
    },
    {
        title: 'Время',
        dataIndex: 'date',
        render: (endDateFact) => dayjs(endDateFact).format('HH:mm'),
    },
    {
        title: 'Что сделано',
        dataIndex: 'done',
    },
    {
        title: 'Сколько потрачено',
        dataIndex: 'cost',
    },
    {
        title: 'Ответственный',
        dataIndex: 'name',
    },
    {
        title: 'Файлы',
        dataIndex: 'file',
        render: (_) => _ && <File id={_.id} name={_.name} />,
    },
]

const CommentBlock = () => {
    const [done, setDone] = useState('')
    const [cost, setCost] = useState()
    const [file, setFile] = useState()
    return (
        <Vflex>
            <H4>Комментарии по ремонту</H4>
            <Hflex align="end">
                <InputText
                    value={done}
                    onChange={setDone}
                    style={{ width: '100%' }}
                >
                    Что сделано
                </InputText>

                <ButtonFile value={file} onChange={setFile} />
                <InputNum
                    value={cost}
                    onChange={(cost) => setCost(cost)}
                    style={{ minWidth: 240 }}
                >
                    Сколько потрачено
                </InputNum>
                <Button
                    onClick={() => RepairStore.addComment({ done, cost, file })}
                    disabled={!done || !cost}
                >
                    Сохранить
                </Button>
            </Hflex>
            <Table
                columns={columns}
                dataSource={RepairStore.repair.comments}
                bordered
                pagination={false}
            />
        </Vflex>
    )
}

export default observer(CommentBlock)
