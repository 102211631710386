import instance from './index'

const rootPath = '/api/route/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getEmployees: ({ routeId, start, end }) => {
        return instance.post(rootPath + 'employees', { routeId, start, end })
    },

    getTransport: ({ routeId, start, end }) => {
        return instance.post(rootPath + 'transport', { routeId, start, end })
    },

    getCheckList: () => {
        return instance.get(rootPath + 'checkList')
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    save: (route) => {
        return instance.post(rootPath + 'save', { route })
    },

    addComment: ({ routeId, text }) => {
        return instance.post(rootPath + 'addComment', { routeId, text })
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
