import instance from './index'

const rootPath = '/api/finance/'

export default {
    getDebit: (params) => {
        return instance.get(rootPath + 'debit', { params })
    },

    getCredit: (params) => {
        return instance.get(rootPath + 'credit', { params })
    },

    getPayment: (params) => {
        return instance.get(rootPath + 'payment', { params })
    },
}
