import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import CandidateService from '../api/CandidateService'

class CandidateStore {
    isProgress = false
    hasChange = false
    candidate = {}
    status = [
        { value: 1, text: 'Собеседование', color: 'orange' },
        { value: 2, text: 'Оформление', color: 'blue' },
        { value: 3, text: 'Резерв', color: 'grey' },
        { value: 4, text: 'Трудоустроен', color: 'green' },
        { value: 5, text: 'Уволился', color: 'red' },
    ]
    constructor() {
        makeAutoObservable(this)
    }

    setIsProgress(isProgress) {
        this.isProgress = isProgress
    }

    set(candidate) {
        this.candidate = candidate
        this.hasChange = false
    }

    change(props) {
        this.candidate = { ...this.candidate, ...props }
        this.hasChange = true
    }

    unset() {
        this.candidate = {}
        this.hasChange = false
    }

    async getById(id) {
        this.setIsProgress(true)
        this.unset()
        try {
            const candidate = (await CandidateService.getById(id)).data
            if (candidate.id) {
                candidate.birstday =
                    candidate.birstday && dayjs(candidate.birstday)
                candidate.employmentDate =
                    candidate.employmentDate && dayjs(candidate.employmentDate)
                candidate.dismissalDate =
                    candidate.dismissalDate && dayjs(candidate.dismissalDate)
                candidate.passportEnd =
                    candidate.passportEnd && dayjs(candidate.passportEnd)
                candidate.medicalDate =
                    candidate.medicalDate && dayjs(candidate.medicalDate)
                this.set(candidate)
            }
        } catch (err) {
            console.log('getCandidate error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async save() {
        this.setIsProgress(true)
        try {
            const candidate = (await CandidateService.save(this.candidate)).data
            if (candidate.id) {
                this.change(candidate)
                this.hasChange = false
                return candidate.id
            }
        } catch (err) {
            console.log('Candidate save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async addComment(text) {
        this.setIsProgress(true)
        try {
            const newComment = (
                await CandidateService.addComment({
                    candidateId: this.candidate.id,
                    text,
                })
            ).data
            if (newComment) {
                if (this.candidate.comments) {
                    this.candidate.comments = [
                        newComment,
                        ...this.candidate.comments,
                    ]
                } else {
                    this.candidate.comments = [newComment]
                }
            }
        } catch (err) {
            console.log('Candidate save error', err)
        } finally {
            this.setIsProgress(false)
        }
    }

    async delete(id) {
        this.setIsProgress(true)
        try {
            await CandidateService.delete(id)
            this.unset()
        } catch (err) {
            console.log('Candidate delete error', err)
        } finally {
            this.setIsProgress(false)
        }
    }
}

export default new CandidateStore()
