import { makeAutoObservable } from 'mobx'
import AuthService from '../api/AuthService'
import NotificationService from '../api/NotificationService'

class AuthStore {
    isAuth = false
    isAuthInProgress = true
    notificationCount = 0
    user = {}
    roles = [
        {
            value: 'hr',
            label: 'HR',
        },
        {
            value: 'logist',
            label: 'Логист',
        },
        {
            value: 'mechanic',
            label: 'Механик',
        },
        {
            value: 'driver',
            label: 'Драйвер',
        },
        {
            value: 'finance',
            label: 'Финансист',
        },
        {
            value: 'purchase',
            label: 'Закупщик',
        },
        {
            value: 'director',
            label: 'Директор',
        },
        {
            value: 'admin',
            label: 'Администратор',
        },
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setIsAuth(isAuth) {
        this.isAuth = isAuth
    }

    setIsAuthInProgress(isAuthInProgress) {
        this.isAuthInProgress = isAuthInProgress
    }

    setUser(user) {
        this.user = user
    }

    setRole(role) {
        this.user.role = role
    }

    async changeRole(role) {
        await AuthService.changeRole(role)
        this.setRole(role)
    }

    async changeCompany(id) {
        const company = (await AuthService.changeCompany(id)).data
        if (company) {
            this.user.company = company
        }
    }

    async login(email, password) {
        this.setIsAuthInProgress(true)
        try {
            const user = (await AuthService.login(email, password)).data
            if (user.userId) {
                this.setUser(user)
                this.setIsAuth(true)
            }
        } catch (err) {
            console.log('Login error', err)
        } finally {
            this.setIsAuthInProgress(false)
        }
    }

    async logout() {
        this.setIsAuthInProgress(true)
        try {
            await AuthService.logout()
            this.setUser({})
            this.setIsAuth(false)
        } catch (err) {
            console.log('Logout error:', err)
        } finally {
            this.setIsAuthInProgress(false)
        }
    }

    async checkAuth() {
        try {
            const user = (await AuthService.checkAuth()).data
            if (user.userId) {
                this.setUser(user)
                this.setIsAuth(true)
            } else {
                this.setUser({})
                this.setIsAuth(false)
            }
        } catch (err) {
            console.log('CheckAuth error:', err)
        }
        this.setIsAuthInProgress(false)
    }

    async checkNotification() {
        try {
            this.notificationCount = (await NotificationService.check()).data
        } catch (err) {
            console.log('checkNotification error:', err)
        }
    }
}

export default new AuthStore()
