import React from 'react'
import dayjs from 'dayjs'
import { Bflex, H4, Table, Text, ButtonOut } from '../../ui'
import { Link } from 'react-router-dom'

const columns = [
    {
        title: 'Дата ТО, план',
        dataIndex: 'startDatePlan',
        rowScope: 'row',
        render: (startDatePlan, { endDatePlan }) =>
            startDatePlan &&
            dayjs(startDatePlan).format('DD.MM.YYYY') +
                ' — ' +
                (!endDatePlan ? '' : dayjs(endDatePlan).format('DD.MM.YYYY')),
    },
    {
        title: 'Дата ТО, факт',
        dataIndex: 'startDateFact',
        render: (startDateFact, { endDateFact }) =>
            startDateFact &&
            dayjs(startDateFact).format('DD.MM.YYYY') +
                ' — ' +
                (!endDateFact ? '' : dayjs(endDateFact).format('DD.MM.YYYY')),
    },
    {
        title: 'Отклон. по дате',
        dataIndex: 'endDatePlan',
        render: (endDatePlan, { endDateFact }) => {
            if (!endDatePlan || !endDateFact) return
            const deviation = dayjs(endDateFact).diff(
                dayjs(endDatePlan),
                'days'
            )
            return deviation === 0 ? (
                <Text type="secondary">0</Text>
            ) : deviation < 0 ? (
                <Text type="success">{deviation}</Text>
            ) : (
                <Text type="danger">+{deviation}</Text>
            )
        },
    },
    {
        title: 'Затраты, план',
        dataIndex: 'costPlan',
    },
    {
        title: 'Затраты, факт',
        dataIndex: 'costFact',
    },
    {
        title: 'Отклон. по затратам',
        dataIndex: 'costPlan',
        render: (costPlan, { costFact }) => {
            if (!costPlan || !costFact) return
            const deviation = costFact - costPlan
            return deviation === 0 ? (
                <Text type="secondary">0</Text>
            ) : deviation < 0 ? (
                <Text type="success">{deviation}</Text>
            ) : (
                <Text type="danger">+{deviation}</Text>
            )
        },
    },
    {
        title: 'Причина ТО',
        dataIndex: 'reason',
    },
]

const ServiceBlock = ({ dataSource }) => (
    <>
        <Bflex>
            <H4>Техническое обслуживание</H4>
            <Link to="/repair/create">
                <ButtonOut>Добавить ремонт</ButtonOut>
            </Link>
        </Bflex>
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
        />
    </>
)

export default ServiceBlock
