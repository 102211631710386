import React from 'react'
import { Link } from 'react-router-dom'
import { Hflex } from './flex'
import { H4 } from './text'

const Breadcrumb = ({ steps }) => (
    <Hflex>
        {steps.map(({ text, link }, index) => {
            return link ? (
                <Link to={link} key={index}>
                    <H4 type="secondary">
                        {(index !== 0 ? ' / ' : '') + text}
                    </H4>
                </Link>
            ) : (
                <H4 key={index}>{(index !== 0 ? ' / ' : '') + text}</H4>
            )
        })}
    </Hflex>
)

export default Breadcrumb
