import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useNavigate, Link } from 'react-router-dom'
import VacancyService from '../../api/VacancyService'
import VacancyStore from '../../store/VacancyStore'
import { Vflex, Bflex, H3, Button, Table, Badge, Spin } from '../../ui'

const columns = [
    {
        title: 'Вакансия',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => <Link to={'/vacancy/' + record.id}>{_}</Link>,
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        sorter: (a, b) => a.type - b.type,
        render: (_) => VacancyStore.type[_ - 1].label,
    },
    {
        title: 'Сайт',
        dataIndex: 'cite',
        sorter: (a, b) => a.cite.localeCompare(b.cite),
    },
    {
        title: 'Город',
        dataIndex: 'city',
        sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
        title: 'Кол-во кандидатов',
        dataIndex: 'count',
        sorter: (a, b) => a.count - b.count,
    },
    {
        title: 'Зарплата, ₽',
        dataIndex: 'salary',
    },
    {
        title: 'Дата создания',
        dataIndex: 'dateCreate',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={VacancyStore.status[num - 1].color}
                    text={VacancyStore.status[num - 1].text}
                />
            ) : null,
    },
]

const VacancyPage = () => {
    const navigate = useNavigate()
    const [vacancies, setVacancys] = useState()
    useEffect(() => {
        async function fetchData() {
            const vacancies = (await VacancyService.get()).data
            setVacancys(vacancies)
        }
        fetchData()
    }, [])

    if (!vacancies) {
        return <Spin />
    }

    return (
        <Vflex>
            <Bflex>
                <H3>Вакансии</H3>
                <Button onClick={() => navigate('/vacancy/create')}>
                    Создать
                </Button>
            </Bflex>
            <Table
                columns={columns}
                dataSource={vacancies}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default VacancyPage
