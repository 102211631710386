import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import EmployeeService from '../../api/EmployeeService'
import EmployeeStore from '../../store/EmployeeStore'
import { Vflex, Bflex, H3, MonthPeriod, Table, Spin, Hflex } from '../../ui'
import { Tooltip } from 'antd'

const ReportPage = () => {
    const [date, setDate] = useState(dayjs().startOf('month'))
    const [employees, setEmployees] = useState()
    useEffect(() => {
        async function fetchData() {
            const employees = (
                await EmployeeService.getReport(date.format('DD.MM.YYYY'))
            ).data
            setEmployees(employees)
        }
        fetchData()
    }, [date])

    if (!employees) {
        return <Spin />
    }

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 300,
            fixed: 'left',
        },
        ...(employees.length === 0
            ? {}
            : employees[0].days.map((item, i) => ({
                  title: item.day,
                  dataIndex: 'days',
                  onHeaderCell: () => ({
                      style: {
                          padding: '0 6px',
                          textAlign: 'center',
                      },
                  }),
                  onCell: (_) => ({
                      style: {
                          background: EmployeeStore.getDayProps(
                              _.days[i].typeDate
                          ).color,
                          padding: '0 6px',
                          textAlign: 'center',
                      },
                  }),
                  render: (days) => (
                      <Tooltip
                          title={
                              EmployeeStore.getDayProps(days[i].typeDate).full
                          }
                      >
                          {EmployeeStore.getDayProps(days[i].typeDate).text}
                      </Tooltip>
                  ),
                  width: 30,
              }))),
        {
            title: 'Заметка',
            dataIndex: 'comment',
            fixed: 'right',
        },
    ]

    return (
        <Vflex>
            <Bflex>
                <H3>Табель</H3>
                <MonthPeriod date={date} setDate={setDate} />
            </Bflex>
            <Table
                columns={columns}
                dataSource={employees}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />

            <Hflex>
                {EmployeeStore.dayProps.map((item) => (
                    <div key={item.text}>
                        <span
                            style={{
                                background: item.color,
                                width: 30,
                                textAlign: 'center',
                                display: 'inline-block',
                            }}
                        >
                            {item.text}
                        </span>{' '}
                        — {item.full}
                    </div>
                ))}
            </Hflex>
        </Vflex>
    )
}

export default ReportPage
