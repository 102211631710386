import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import {
    Vflex,
    DashPeriod,
    Table,
    H3,
    Spin,
    Hflex,
    Tabs,
    currencyFormatter,
} from '../../ui/index.jsx'
import FinanceService from '../../api/FinanceService.jsx'
import { Link } from 'react-router-dom'

const columns = [
    {
        title: 'Расход',
        dataIndex: 'brand',
        sorter: (a, b) => a.brand.localeCompare(b.brand),
    },
    {
        title: 'Контрагент',
        dataIndex: 'client',
        sorter: (a, b) => a.client.localeCompare(b.client),
    },
    {
        title: 'Срок оплаты',
        dataIndex: 'dateClose',
        sorter: (a, b) =>
            dayjs(a.dateClose, 'DD.MM.YYYY') - dayjs(b.dateClose, 'DD.MM.YYYY'),
        render: (dateClose) => dayjs(dateClose).format('DD.MM.YYYY'),
    },
    {
        title: 'Сумма',
        dataIndex: 'revenue',
        sorter: (a, b) => a.revenue - b.revenue,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Источник',
        dataIndex: 'number',
        render: (_, record) => <Link to={'/purchase/' + record.id}>{_}</Link>,
    },
]

const CreditPage = () => {
    const [tab, setTab] = useState(1)
    const [date, setDate] = useState(dayjs())
    const [period, setPeriod] = useState('week')
    const [operations, setOperations] = useState()
    const tabs = [
        {
            key: 1,
            label: 'Актуальное',
        },
        {
            key: 2,
            label: 'Ежемесячные платежи',
        },
        {
            key: 3,
            label: 'Просрочка',
        },
    ]
    useEffect(() => {
        async function fetchData() {
            const operations = (
                await FinanceService.getCredit({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setOperations(operations)
        }
        fetchData()
    }, [date, period])

    if (!operations) {
        return <Spin />
    }

    return (
        <Vflex>
            <Hflex align="flex-end">
                <Vflex>
                    <H3>Кредиторская задолженность</H3>
                    <Tabs
                        items={tabs}
                        value={tab}
                        onChange={setTab}
                    />
                </Vflex>
                <DashPeriod
                    date={date}
                    setDate={setDate}
                    period={period}
                    setPeriod={setPeriod}
                />
            </Hflex>
            <Table
                columns={columns}
                dataSource={operations.filter(
                    (item) =>
                        (tab == 1 && dayjs(item.dateClose).isAfter()) ||
                        (tab == 2 && item.isMonthly) ||
                        (tab == 3 && !dayjs(item.dateClose).isAfter())
                )}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default CreditPage
