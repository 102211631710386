import React from 'react'
import {
    Flex,
    Space,
    Typography,
    Input,
    DatePicker,
    Select as AntSelect,
    InputNumber,
} from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
const { Text } = Typography

const InputText = ({
    value,
    onChange,
    placeholder,
    disabled,
    style,
    children,
}) => (
    <Flex vertical style={style}>
        <Text type="secondary">{children}</Text>
        <Input
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </Flex>
)

const InputPassword = ({ value, onChange, style }) => (
    <Flex vertical style={style}>
        <Text type="secondary">Пароль</Text>
        <Input.Password
            placeholder="Пароль"
            iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </Flex>
)

const InputNum = ({ value, onChange, style, children, ...props }) => (
    <Flex vertical style={style}>
        <Text type="secondary">{children}</Text>
        <InputNumber
            {...props}
            style={{ width: '100%' }}
            min={0}
            value={value}
            onChange={(val) => onChange(val)}
            formatter={currencyFormatter}
            parser={currencyParser}
        />
    </Flex>
)

const Input2Num = ({
    value1,
    onChange1,
    value2,
    onChange2,
    style,
    children,
    ...props
}) => (
    <Flex vertical style={style}>
        <Text type="secondary">{children}</Text>
        <Space.Compact>
            <InputNumber
                {...props}
                style={{ width: '100%' }}
                min={0}
                value={value1}
                onChange={(val) => onChange1(val)}
                formatter={currencyFormatter}
                parser={currencyParser}
            />
            <InputNumber
                {...props}
                style={{ width: '100%' }}
                min={0}
                value={value2}
                onChange={(val) => onChange2(val)}
                formatter={currencyFormatter}
                parser={currencyParser}
            />
        </Space.Compact>
    </Flex>
)

const InputDate = ({
    value,
    onChange,
    picker,
    minDate,
    maxDate,
    disabled,
    style,
    children,
    ...props
}) => (
    <Flex vertical style={{ minWidth: 140, ...style }}>
        <Text type="secondary">{children}</Text>
        <DatePicker
            style={{ width: '100%' }}
            format="DD.MM.YYYY"
            placeholder="01.01.2000"
            {...props}
            value={value}
            onChange={onChange}
            picker={picker}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
        />
    </Flex>
)

const InputRange = ({ value, onChange, disabled, style, children }) => (
    <Flex vertical style={{ minWidth: 140, ...style }}>
        <Text type="secondary">{children}</Text>
        <DatePicker.RangePicker
            format="DD.MM.YYYY"
            placeholder="01.01.2000"
            value={value}
            disabled={disabled}
            onChange={(dates) => onChange({ start: dates[0], end: dates[1] })}
        />
    </Flex>
)

const InputPhone = (value, onChange, children, style) => (
    <Flex vertical style={style}>
        <Text type="secondary">{children || 'Номер телефона'}</Text>
        <Input
            placeholder="+7 (999) 999-99-99"
            mask="+7 (000) 000-000-000"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    </Flex>
)

const Select = ({
    value,
    onChange,
    options,
    disabled,
    style,
    children,
    ...props
}) => (
    <Flex vertical style={style}>
        <Text type="secondary">{children}</Text>
        <AntSelect
            value={value}
            onChange={onChange}
            options={options}
            disabled={disabled}
            {...props}
        />
    </Flex>
)

const Textarea = ({ value, onChange, children, ...props }) => (
    <Input.TextArea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...props}
    >
        {children}
    </Input.TextArea>
)

export {
    InputText,
    InputPassword,
    InputNum,
    Input2Num,
    InputDate,
    InputRange,
    InputPhone,
    Select,
    Textarea,
    currencyFormatter,
}

const currencyFormatter = (value) => {
    return isNaN(value) ? '' : new Intl.NumberFormat('ru').format(value)
}

const currencyParser = (val) => {
    try {
        if (typeof val === 'string' && !val.length) {
            val = '0.0'
        }
        var group = currencyFormatter(1111).replace(/1/g, '')
        var decimal = currencyFormatter(1.1).replace(/1/g, '')
        var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
        reversedVal = reversedVal.replace(/[^0-9.]/g, '')
        const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length
        const needsDigitsAppended = digitsAfterDecimalCount > 2

        if (needsDigitsAppended) {
            reversedVal =
                reversedVal * Math.pow(10, digitsAfterDecimalCount - 2)
        }

        return Number.isNaN(reversedVal) ? 0 : reversedVal
    } catch (error) {
        console.error(error)
    }
}
