import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Modal, ButtonOut, InputDate, InputNum } from '../../ui'
import TransportStore from '../../store/TransportStore'

const SpareAddAuditModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [date, setDate] = useState(dayjs())
    const [discs, setDiscs] = useState()
    const [pads, setPads] = useState()
    const [battery, setBattery] = useState()
    const [gearbox, setGearbox] = useState()
    const [motor, setMotor] = useState()
    const showModal = () => {
        setDate(dayjs())
        setDiscs()
        setPads()
        setBattery()
        setGearbox()
        setMotor()
        setIsModalOpen(true)
    }

    const handleOk = () => {
        TransportStore.addSpare({ date, discs, pads, battery, gearbox, motor })
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal}>Добавить аудит</ButtonOut>
            <Modal
                title="Добавить параметр ввода в эксплуатацию"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{
                    disabled:
                        !date ||
                        !discs ||
                        !pads ||
                        !battery ||
                        !gearbox ||
                        !motor,
                }}
            >
                <InputDate value={date} onChange={setDate}>
                    Дата
                </InputDate>
                <InputNum
                    max={10}
                    value={discs}
                    onChange={setDiscs}
                >
                    Тормозные диски
                </InputNum>
                <InputNum
                    max={10}
                    value={pads}
                    onChange={setPads}
                >
                    Колодки
                </InputNum>
                <InputNum
                    max={10}
                    value={battery}
                    onChange={setBattery}
                >
                    Аккумулятор
                </InputNum>
                <InputNum
                    max={10}
                    value={gearbox}
                    onChange={setGearbox}
                >
                    КПП
                </InputNum>
                <InputNum
                    max={10}
                    value={motor}
                    onChange={setMotor}
                >
                    Двигатель
                </InputNum>
            </Modal>
        </>
    )
}

export default SpareAddAuditModal
