import React, { useState } from 'react'
import { H3, Hflex, Menu } from '../../ui'
import {
    TeamOutlined,
    AuditOutlined,
    CalculatorOutlined,
} from '@ant-design/icons'

import UsersBlock from './settings/usersBlock'
import CheckListBlock from './settings/checkListBlock'
import CalcBlock from './settings/calcBlock'

const SettingsPage = () => {
    const [current, setCurrent] = useState(1)
    return (
        <Hflex align="flex-start">
            <Menu
                items={[
                    {
                        key: 1,
                        label: 'Сотрудники',
                        icon: <TeamOutlined />,
                    },
                    {
                        key: 2,
                        label: 'Чек-листы',
                        icon: <AuditOutlined />,
                    },
                    {
                        key: 3,
                        label: 'Расчёты',
                        icon: <CalculatorOutlined />,
                    },
                ]}
                onSelect={(item) => setCurrent(item.key)}
            />
            {current == 1 && <UsersBlock />}
            {current == 2 && <CheckListBlock />}
            {current == 3 && <CalcBlock />}
        </Hflex>
    )
}

export default SettingsPage
