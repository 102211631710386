import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import AuthStore from '../../store/AuthStore'
import RouteStore from '../../store/RouteStore'
import {
    Breadcrumb,
    InputText,
    InputNum,
    InputDate,
    InputRange,
    Select,
    Vflex,
    Hflex,
    Tabs,
    Button,
    Spin,
} from '../../ui'
import MainLogisticBlock from './mainLogisticBlock'
import DaysLogisticBlock from './daysLogisticBlock'
import CostTab from './costTab'
import ChecklistModal from './checklistModal'

const EditRoute = () => {
    const [currentTab, setCurrentTab] = useState(1)
    const [checkTrack, setCheckTrack] = useState(false)
    const [checkTrailer, setCheckTrailer] = useState(false)
    let { id } = useParams()
    useEffect(() => {
        RouteStore.getById(id)
    }, [id])
    const {
        route,
        employees,
        transports,
        checkList,
        status,
        statusDoc,
        statusBill,
        isProgress,
        hasChange,
    } = RouteStore
    const { role } = AuthStore.user

    if (isProgress) {
        return <Spin />
    }
    const tabs = [
        {
            key: 1,
            label: 'Логистика',
        },
        {
            key: 2,
            label: 'Себестоимость',
        },
    ]
    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    {
                        text: 'Маршруты',
                        link: role === 'logist' ? '/' : '/routes',
                    },
                    {
                        text: route.pointA + ' — ' + route.pointB,
                    },
                ]}
            />
            <Hflex>
                <InputText
                    value={route.pointA + ' — ' + route.pointB}
                    disabled={true}
                    style={{ width: '100%' }}
                >
                    Маршрут
                </InputText>
                <InputRange
                    style={{ width: '100%' }}
                    value={[route.startDatePlan, route.endDatePlan]}
                    onChange={({ start, end }) =>
                        RouteStore.change({
                            startDatePlan: start,
                            endDatePlan: end,
                        })
                    }
                    disabled={route.startDatePlan && role !== 'logist'}
                >
                    Дата начала / окончания
                </InputRange>
                <InputNum
                    style={{ width: '100%' }}
                    value={route.revenuePlan}
                    onChange={(revenuePlan) =>
                        RouteStore.change({ revenuePlan })
                    }
                    disabled={role !== 'logist'}
                >
                    Стоимость рейса
                </InputNum>
            </Hflex>
            <Hflex>
                <Hflex align="end" style={{ width: '100%' }}>
                    <Select
                        style={{ width: '100%' }}
                        disabled={role !== 'driver'}
                        value={route.driver}
                        onChange={(driver) => RouteStore.change({ driver })}
                        options={employees}
                    >
                        Водитель
                    </Select>
                    {role === 'driver' && (
                        <Button onClick={() => RouteStore.save()}>
                            Готово
                        </Button>
                    )}
                </Hflex>
                <Hflex align="end" style={{ width: '100%' }}>
                    <Select
                        style={{ width: '100%' }}
                        disabled={role !== 'mechanic'}
                        value={route.truck}
                        onChange={(truck) => RouteStore.change({ truck })}
                        options={transports.filter((item) => item.type === 1)}
                    >
                        Грузовик
                    </Select>
                    {role === 'mechanic' && !checkTrack && (
                        <ChecklistModal
                            checkList={checkList.truck}
                            onCheck={() => setCheckTrack(true)}
                        />
                    )}
                    {role === 'mechanic' && checkTrack && (
                        <Button onClick={() => RouteStore.save()}>
                            Готово
                        </Button>
                    )}
                </Hflex>
                <Hflex align="end" style={{ width: '100%' }}>
                    <Select
                        style={{ width: '100%' }}
                        disabled={role !== 'mechanic'}
                        value={route.trailer}
                        onChange={(trailer) => RouteStore.change({ trailer })}
                        options={transports.filter((item) => item.type === 2)}
                    >
                        Прицеп
                    </Select>
                    {role === 'mechanic' && !checkTrailer && (
                        <ChecklistModal
                            checkList={checkList.trailer}
                            onCheck={() => setCheckTrailer(true)}
                        />
                    )}
                    {role === 'mechanic' && checkTrailer && (
                        <Button onClick={() => RouteStore.save()}>
                            Готово
                        </Button>
                    )}
                </Hflex>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: '100%' }}
                    disabled={role !== 'logist'}
                    value={route.status}
                    onChange={(status) => RouteStore.change({ status })}
                    options={status}
                >
                    Статус маршрута
                </Select>
                <InputText
                    disabled={role !== 'logist'}
                    value={route.client}
                    onChange={(client) => RouteStore.change({ client })}
                    style={{ width: '100%' }}
                >
                    Контрагент
                </InputText>
                <InputDate
                    style={{ width: '100%' }}
                    value={route.payDate}
                    onChange={(payDate) => RouteStore.change({ payDate })}
                    disabled={role !== 'logist'}
                >
                    Дата оплаты
                </InputDate>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: '100%' }}
                    disabled={role !== 'logist'}
                    value={route.statusBill}
                    onChange={(statusBill) => RouteStore.change({ statusBill })}
                    options={statusBill}
                >
                    Статус счета
                </Select>
                <InputNum
                    style={{ width: '100%' }}
                    value={route.prepayment}
                    onChange={(prepayment) => RouteStore.change({ prepayment })}
                    disabled={role !== 'logist'}
                >
                    Предоплата
                </InputNum>
                <Select
                    style={{ width: '100%' }}
                    disabled={role !== 'logist'}
                    value={route.statusDoc}
                    onChange={(statusDoc) => RouteStore.change({ statusDoc })}
                    options={statusDoc}
                >
                    Статус документов
                </Select>
            </Hflex>
            {role === 'logist' && (
                <Button disabled={!hasChange} onClick={() => RouteStore.save()}>
                    Сохранить
                </Button>
            )}

            <Tabs
                items={tabs}
                onChange={setCurrentTab}
                activeKey={currentTab}
            />
            {currentTab == 1 && (
                <MainLogisticBlock logistics={route.logistics} />
            )}
            {currentTab == 1 && (
                <DaysLogisticBlock logistics={route.logistics} />
            )}
            {currentTab == 2 && <CostTab />}
        </Vflex>
    )
}

export default observer(EditRoute)
