import React from 'react'
import { Table, Badge } from '../../../ui'

const columns = [
    {
        title: 'Тип',
        dataIndex: 'name',
    },
    {
        title: 'Всего',
        dataIndex: 'all',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
    },
    {
        title: 'В пути',
        dataIndex: 'way',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
    {
        title: 'Ремонт',
        dataIndex: 'repair',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
    {
        title: 'Простои',
        dataIndex: 'downtime',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
    {
        title: 'В продаже',
        dataIndex: 'sale',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
    {
        title: 'В аренде',
        dataIndex: 'rent',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
    {
        title: 'Взяли в аренду',
        dataIndex: 'rented',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        render: (_) =>
            typeof _ === 'number' ? _ : _ && <Badge color="green" />,
    },
]
const ParkTable = ({ data }) => (
    <Table
        columns={columns}
        dataSource={data}
        showSorterTooltip={{
            target: 'sorter-icon',
        }}
    />
)

export default ParkTable
