import React from 'react'
import { Badge as AntBadge } from 'antd'
import { BellOutlined } from '@ant-design/icons'

const Badge = (props) => <AntBadge {...props} />

const Ribon = ({ text, children }) => (
    <AntBadge.Ribbon text={text}>{children}</AntBadge.Ribbon>
)

const Notification = ({ count }) => (
    <Badge count={count} size="small">
        <BellOutlined style={{ color: 'white', fontSize: 24 }} />
    </Badge>
)

export { Badge, Ribon, Notification }
