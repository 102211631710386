import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Vflex, Cflex, H3, InputText, InputPassword, Button } from '../ui'

import AuthStore from '../store/AuthStore'

const LoginPage = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return (
        <Cflex style={{ height: 'calc(100vh - 147px)' }}>
            <Vflex style={{ width: 400 }}>
                <H3 style={{ textAlign: 'center' }}>АВТОРИЗАЦИЯ</H3>
                <InputText
                    type="email"
                    value={email}
                    onChange={setEmail}
                >
                    Email
                </InputText>
                <InputPassword
                    value={password}
                    onChange={setPassword}
                />
                <Button block onClick={() => AuthStore.login(email, password)}>
                    Войти
                </Button>
            </Vflex>
        </Cflex>
    )
}

export default observer(LoginPage)
