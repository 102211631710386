import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vflex, Bflex, H3, Button, Tabs } from '../../ui'
import AuthStore from '../../store/AuthStore'
import MyTable from './myTable'
import DirectorTable from './directorTable'
import AllTable from './allTable'

const PurchasePage = () => {
    const navigate = useNavigate()
    const [tab, setTab] = useState(1)
    let tabs = [
        {
            key: 1,
            label: 'Мои заявки',
        },
    ]
    AuthStore.user.role === 'director' &&
        tabs.push({
            key: 2,
            label: 'Ответственный',
        })
    AuthStore.user.role === 'purchase' &&
        tabs.push({
            key: 3,
            label: 'Все заявки',
        })

    return (
        <Vflex>
            <Bflex>
                <H3>Заявки на Закупку</H3>
                <Button onClick={() => navigate('/purchase/create')}>
                    Создать
                </Button>
            </Bflex>
            <Tabs items={tabs} value={tab} onChange={setTab} />
            {tab === 1 && <MyTable />}
            {tab === 2 && <DirectorTable />}
            {tab === 3 && <AllTable />}
        </Vflex>
    )
}

export default PurchasePage
