import React from 'react'
import dayjs from 'dayjs'
import { Bflex, H4, Table } from '../../ui'
import SpareAddAuditModal from './spareAddAuditModal'

const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        rowScope: 'row',
        render: (date) => dayjs(date).format('DD.MM.YYYY'),
    },
    {
        title: 'Тормозные диски',
        dataIndex: 'discs',
    },
    {
        title: 'Колодки',
        dataIndex: 'pads',
    },
    {
        title: 'Аккумулятор',
        dataIndex: 'battery',
    },
    {
        title: 'КПП',
        dataIndex: 'gearbox',
    },
    {
        title: 'Двигатель',
        dataIndex: 'motor',
    },
]

const SpareBlock = ({ dataSource }) => (
    <>
        <Bflex>
            <H4>Аудит запчастей</H4>
            <SpareAddAuditModal />
        </Bflex>
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            rowKey={(row) => row.date}
        />
    </>
)

export default SpareBlock
