import React, { useState, useEffect } from 'react'
import DirectorService from '../../../api/DirectorService'
import { message } from 'antd'
import { Vflex, H3, H5, RadioGroup, Button, Spin } from '../../../ui'

const CalcBlock = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [transport, setTransport] = useState()
    const [tires, setTires] = useState()
    const [oil, setOil] = useState()

    useEffect(() => {
        async function fetchData() {
            const data = (await DirectorService.getCalc()).data
            setTransport(data.transport)
            setTires(data.tires)
            setOil(data.oil)
        }
        fetchData()
    }, [])

    const onSave = async () => {
        messageApi.open({
            key: 'save',
            type: 'loading',
            content: 'Сохранение...',
        })

        await DirectorService.saveCalc({
            transport,
            tires,
            oil,
        })
        
        messageApi.open({
            key: 'save',
            type: 'success',
            content: 'Сохранено!',
            duration: 2,
        })
    }

    if (!transport) {
        return <Spin />
    }
    return (
        <Vflex gap="large" style={{ width: '100%' }}>
            {contextHolder}
            <H3>Расчёты</H3>
            <div>
                <H5>На ремонт транспорта</H5>
                <RadioGroup
                    value={transport}
                    onChange={setTransport}
                    options={[
                        { value: 1, label: '₽/км пути' },
                        { value: 2, label: 'План на месяц' },
                    ]}
                />
            </div>
            <div>
                <H5>На замену шин</H5>
                <RadioGroup
                    value={tires}
                    onChange={setTires}
                    options={[
                        { value: 1, label: '₽/км пути' },
                        { value: 2, label: 'План на месяц' },
                    ]}
                />
            </div>
            <div>
                <H5>На замену масла</H5>
                <RadioGroup
                    value={oil}
                    onChange={setOil}
                    options={[
                        { value: 1, label: '₽/км пути' },
                        { value: 2, label: 'План на месяц' },
                    ]}
                />
            </div>
            <Button onClick={onSave}>Сохранить</Button>
        </Vflex>
    )
}

export default CalcBlock
