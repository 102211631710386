import React from 'react'
import { observer } from 'mobx-react-lite'
import EmployeeStore from '../../store/EmployeeStore'
import { InputText, InputDate, Hflex, H4, Bflex } from '../../ui'
import AddVisaModal from './addVisaModal'

const VisaBloack = ({ employee }) => {
    return (
        <>
            <Bflex align="flex-end">
                <H4>Виза</H4>
                <AddVisaModal />
            </Bflex>
            {employee.visas?.map((visa, i) => (
                <Hflex key={i}>
                    <InputDate
                        value={visa.date}
                        onChange={(date) =>
                            EmployeeStore.changeVisa(i, { date })
                        }
                    >
                        Дата окончания
                    </InputDate>
                    <InputText
                        style={{ width: '100%' }}
                        value={visa.note}
                        onChange={(note) =>
                            EmployeeStore.changeVisa(i, { note })
                        }
                    >
                        Заметка
                    </InputText>
                </Hflex>
            ))}
        </>
    )
}

export default observer(VisaBloack)
