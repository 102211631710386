import React, { useState, useEffect } from 'react'
import { Vflex, H3, Bflex, Table, Spin } from '../../../ui'
import AddUserModal from './addUserModal'
import DirectorService from '../../../api/DirectorService'
import EditUserModal from './editUserModal'
import AuthStore from '../../../store/AuthStore'

const columns = [
    {
        title: 'Сотрудник',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Емейл',
        dataIndex: 'email',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Роль',
        dataIndex: 'role',
        sorter: (a, b) => a.role.localeCompare(b.role),
        render: (_) => {
            const index = AuthStore.roles.findIndex((item) => item.value == _)
            if (index !== -1) {
                return AuthStore.roles[index].label
            } else {
                return ''
            }
        },
    },
    {
        title: 'Заметка',
        dataIndex: 'note',
        sorter: (a, b) => a.role.localeCompare(b.role),
    },
]

const UsersBlock = () => {
    const [users, setUsers] = useState()
    const [currentUser, setCurrentUser] = useState()
    const [update, setUpdate] = useState()
    useEffect(() => {
        async function fetchData() {
            const users = (await DirectorService.getUsers()).data
            setUsers(users)
        }
        fetchData()
    }, [update])

    if (!users) {
        return <Spin />
    }
    return (
        <Vflex style={{ width: '100%' }}>
            <Bflex>
                <H3>Сотрудники</H3>
                <AddUserModal onUpdate={() => setUpdate(!update)}>
                    Создать
                </AddUserModal>
                <EditUserModal
                    user={currentUser}
                    onClose={() => {
                        setCurrentUser()
                        setUpdate(!update)
                    }}
                />
            </Bflex>
            <Table
                columns={columns}
                dataSource={users}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setCurrentUser(record)
                        },
                    }
                }}
            />
        </Vflex>
    )
}

export default UsersBlock
