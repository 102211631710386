import React from 'react'
import dayjs from 'dayjs'
import RouteStore from '../../store/RouteStore'
import { Bflex, H4, Table } from '../../ui'

const columns = [
    {
        title: 'Маршрут',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        fixed: true,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        showSorterTooltip: {
            target: 'full-header',
        },
        filters: RouteStore.status.map(({ value, label }) => ({
            value,
            text: label,
        })),

        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => a?.status - b?.status,
        render: (value) => RouteStore.status[value - 1]?.label,
    },
    {
        title: 'Сцепка',
        dataIndex: 'coupling',
        sorter: (a, b) => a.coupling.localeCompare(b.coupling),
    },
    {
        title: 'Дата начала (План)',
        dataIndex: 'startDatePlan',
        sorter: (a, b) => dayjs(a.startDatePlan).diff(dayjs(b.startDatePlan)),
        render: (startDatePlan) => dayjs(startDatePlan).format('DD.MM.YYYY'),
    },
    {
        title: 'Дата окончания (План)',
        dataIndex: 'endDatePlan',
        sorter: (a, b) => dayjs(a.endDatePlan).diff(dayjs(b.endDatePlan)),
        render: (endDatePlan) => dayjs(endDatePlan).format('DD.MM.YYYY'),
    },
]

const PlanedBlock = ({ dataSource }) => (
    <>
        <Bflex>
            <H4>Маршруты</H4>
        </Bflex>
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
        />
    </>
)

export default PlanedBlock
