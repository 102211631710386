import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'cost',
        title: 'Ставка на км',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'total',
        title: 'Сумма ремонта',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'tires',
        title: 'Шины на км',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'totalTires',
        title: 'Сумма шины',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
]

const RepairTable = () => {
    const {
        planRepairCost,
        planRepairTotal,
        planRepairTires,
        planRepairTotalTires,
        logistics: { days },
    } = RouteStore.route
    //const activeDays = days.filter((item) => item.status)
    return (
        <>
            <H4>Ремонт</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        cost: planRepairCost,
                        total: planRepairTotal,
                        tires: planRepairTires,
                        totalTires: planRepairTotalTires,
                        changeble: true,
                    },
                    { id: 2, cost: 0, total: 0, tires: 0, totalTires: 0 },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planRepairCost: data[0].cost,
                        planRepairTotal: data[0].total,
                        planRepairTires: data[0].tires,
                        planRepairTotalTires: data[0].totalTires,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(RepairTable)
