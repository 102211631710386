import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import {
    Vflex,
    DashPeriod,
    Table,
    H3,
    Spin,
    Hflex,
    currencyFormatter,
} from '../../ui/index.jsx'
import FinanceService from '../../api/FinanceService.jsx'

const columns = [
    {
        title: 'Контрагент',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Сумма',
        dataIndex: 'revenue',
        sorter: (a, b) => a.revenue - b.revenue,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Задолженность',
        dataIndex: 'debt',
        render: (_) => (_ ? 'Дебиторская' : 'Кредиторская'),
    },
    {
        title: 'Срок оплаты',
        dataIndex: 'payDate',
        sorter: (a, b) =>
            dayjs(a.payDate, 'DD.MM.YYYY') - dayjs(b.payDate, 'DD.MM.YYYY'),
        render: (payDate) => dayjs(payDate).format('DD.MM.YYYY'),
    },
]

const PaymentPage = () => {
    const [date, setDate] = useState(dayjs())
    const [period, setPeriod] = useState('week')
    const [operations, setOperations] = useState()
    useEffect(() => {
        async function fetchData() {
            const operations = (
                await FinanceService.getPayment({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setOperations(operations)
        }
        fetchData()
    }, [date, period])

    if (!operations) {
        return <Spin />
    }

    return (
        <Vflex>
            <Hflex align="flex-end">
                <H3>Платежный календарь</H3>
                <DashPeriod
                    date={date}
                    setDate={setDate}
                    period={period}
                    setPeriod={setPeriod}
                />
            </Hflex>
            <Table
                columns={columns}
                dataSource={operations}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default PaymentPage
