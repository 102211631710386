import React, { useState } from 'react'
import { Modal, ButtonOut, InputText, Input2Num } from '../../ui'
import TransportStore from '../../store/TransportStore'

const AddParamModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [plan, setPlan] = useState(0)
    const [fact, setFact] = useState(0)
    const showModal = () => {
        setName('')
        setPlan(0)
        setFact(0)
        setIsModalOpen(true)
    }

    const handleOk = () => {
        TransportStore.addParam({ name, plan, fact })
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut onClick={showModal}>Добавить другое</ButtonOut>
            <Modal
                title="Добавить параметр ввода в эксплуатацию"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{ disabled: name?.length < 1 }}
            >
                <InputText value={name} onChange={setName}>
                    Название
                </InputText>
                <Input2Num
                    value1={plan}
                    onChange1={setPlan}
                    value2={fact}
                    onChange2={setFact}
                >
                    План/Факт
                </Input2Num>
            </Modal>
        </>
    )
}

export default AddParamModal
