import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyStore from '../../store/CompanyStore'
import CompanyService from '../../api/CompanyService'
import {
    Breadcrumb,
    InputText,
    Select,
    Vflex,
    Hflex,
    Badge,
    Spin,
    H4,
    Button,
    Statistic,
    Comments,
    ButtonOut,
} from '../../ui'
import ChangeBalanceModal from './changeBalanceModal'
import ContactsBlock from './contactsBlock'
import AuthStore from '../../store/AuthStore'

const EditCompany = () => {
    const navigate = useNavigate()
    const { company, status, stage, isProgress, hasChange } = CompanyStore
    const [managers, setManagers] = useState([])
    let { id } = useParams()
    
    useEffect(() => {
        async function fetchData() {
            const managers = (await CompanyService.getManagers()).data
            setManagers(managers)
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (id === 'create') {
            CompanyStore.unset()
        } else {
            CompanyStore.getById(id)
        }
    }, [id])

    const save = async () => {
        const id = await CompanyStore.save()
        navigate('/company/' + id, {
            replace: true,
        })
    }

    if (isProgress) {
        return <Spin />
    }

    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    { text: 'Компании', link: '/' },
                    { text: company.name || 'Новая компания' },
                ]}
            />
            {company.id && (
                <Hflex>
                    <Statistic
                        style={{ width: '100%' }}
                        title="Баланс, ₽"
                        value={company.balance}
                        suffix={<ChangeBalanceModal value={company.balance} />}
                    />
                    <Statistic
                        style={{ width: '100%' }}
                        title="Кол-во машин"
                        value={company.transportCount}
                        valueStyle={{
                            color: '#1890FF',
                        }}
                    />
                    <Statistic
                        style={{ width: '100%' }}
                        title="Кол-во сотрудников"
                        value={company.userCount}
                        valueStyle={{
                            color: '#1890FF',
                        }}
                    />
                    <Statistic
                        style={{ width: '100%' }}
                        title="Кол-во водителей"
                        value={company.employeeCount}
                        valueStyle={{
                            color: '#1890FF',
                        }}
                    />
                </Hflex>
            )}
            <Hflex>
                <InputText
                    style={{ width: '100%' }}
                    value={company.name}
                    onChange={(name) => CompanyStore.change({ name })}
                >
                    Название
                </InputText>
                <Select
                    style={{ width: '100%' }}
                    options={stage.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={company.stage}
                    onChange={(stage) => CompanyStore.change({ stage })}
                >
                    Этап
                </Select>
                <Select
                    style={{ width: '100%' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={company.status}
                    onChange={(status) => CompanyStore.change({ status })}
                >
                    Статус
                </Select>
                <Select
                    style={{ width: '100%' }}
                    options={managers}
                    value={company.manager}
                    onChange={(manager) => CompanyStore.change({ manager })}
                >
                    Менеджер
                </Select>
            </Hflex>
            <Button disabled={!hasChange} onClick={save}>
                Сохранить
            </Button>
            {company.id && (
                <>
                    <ButtonOut
                        disabled={company.id === AuthStore.user.company.id}
                        onClick={() => AuthStore.changeCompany(company.id)}
                    >
                        Сделать текущей
                    </ButtonOut>
                    <Comments
                        comments={company.comments}
                        add={(comment) => CompanyStore.addComment(comment)}
                    />
                    <ContactsBlock contacts={company.contacts} />
                    <H4>Информация</H4>
                    <Hflex>
                        <InputText
                            style={{ width: '100%' }}
                            value={company.address}
                            onChange={(address) =>
                                CompanyStore.change({ address })
                            }
                        >
                            Адрес
                        </InputText>
                        <InputText
                            style={{ width: '100%' }}
                            value={company.inn}
                            onChange={(inn) => CompanyStore.change({ inn })}
                        >
                            ИНН
                        </InputText>
                    </Hflex>
                    <InputText
                        style={{ width: '100%' }}
                        value={company.note}
                        onChange={(note) => CompanyStore.change({ note })}
                    >
                        Заметка
                    </InputText>
                    <Button disabled={!hasChange} onClick={save}>
                        Сохранить
                    </Button>
                </>
            )}
        </Vflex>
    )
}

export default observer(EditCompany)
