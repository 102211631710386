import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'phone',
        title: 'Сотовая связь',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'platon',
        title: 'Платон',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'platonFines',
        title: 'Платон/штраф',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'navigation',
        title: 'Навигация',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'toll',
        title: 'Платные дороги',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'parking',
        title: 'Стоянка',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'washing',
        title: 'Мойка',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'fines',
        title: 'Штраф',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
]

const OtherTable = () => {
    const {
        planOtherPhone,
        planOtherPlaton,
        planOtherPlatonFines,
        planOtherNavigation,
        planOtherToll,
        planOtherParking,
        planOtherWashing,
        planOtherFines,
        logistics: { days },
    } = RouteStore.route
    const activeDays = days.filter((item) => item.status)
    const phone = activeDays.reduce((a, b) => a + b.phone, 0)
    const platon = activeDays.reduce((a, b) => a + b.platon, 0)
    const platonFines = activeDays.reduce((a, b) => a + b.platonFines, 0)
    const navigation = activeDays.reduce((a, b) => a + b.navigation, 0)
    const toll = activeDays.reduce((a, b) => a + b.tollRoad, 0)
    const parking = activeDays.reduce((a, b) => a + b.parking, 0)
    const washing = activeDays.reduce((a, b) => a + b.washing, 0)
    const fines = activeDays.reduce((a, b) => a + b.fines, 0)
    return (
        <>
            <H4>Прочее</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        phone: planOtherPhone,
                        platon: planOtherPlaton,
                        platonFines: planOtherPlatonFines,
                        navigation: planOtherNavigation,
                        toll: planOtherToll,
                        parking: planOtherParking,
                        washing: planOtherWashing,
                        fines: planOtherFines,
                        changeble: true,
                    },
                    {
                        id: 2,
                        phone,
                        platon,
                        platonFines,
                        navigation,
                        toll,
                        parking,
                        washing,
                        fines,
                    },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planOtherPhone: data[0].phone,
                        planOtherPlaton: data[0].platon,
                        planOtherPlatonFines: data[0].platonFines,
                        planOtherNavigation: data[0].navigation,
                        planOtherToll: data[0].toll,
                        planOtherParking: data[0].parking,
                        planOtherWashing: data[0].washing,
                        planOtherFines: data[0].fines,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(OtherTable)
