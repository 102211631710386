import axios from 'axios'
import AuthStore from '../store/AuthStore'

const api = axios.create({
    withCredentials: true,
})

api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            AuthStore.setIsAuth(false)
        }
        return error
    }
)

export default api
