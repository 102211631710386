import React from 'react'
import AuthStore from '../../store/AuthStore'
import PurchaseStore from '../../store/PurchaseStore'
import {
    InputText,
    InputNum,
    Button,
    Hflex,
    H4,
    ButtonFiles,
    RadioGroup,
    InputDate,
} from '../../ui'
const SuppliersBlock = ({ suppliers, selected, count, disabled, finished }) => {
    const selectedSup = !isNaN(selected) && suppliers[selected]
    return (
        <>
            {selectedSup && (
                <>
                    <H4>Выбранный поставщик</H4>
                    <Hflex align="end">
                        <InputText
                            value={selectedSup.name}
                            disabled
                            style={{ width: '100%' }}
                        >
                            Поставщик № {selected + 1}
                        </InputText>
                        <InputText
                            value={selectedSup.communication}
                            disabled
                            style={{ width: '100%' }}
                        >
                            Способ связи
                        </InputText>
                        <InputNum
                            value={selectedSup.cost}
                            disabled
                            style={{ width: '100%' }}
                        >
                            Цена за штуку
                        </InputNum>
                        <InputNum
                            value={selectedSup.delivery}
                            disabled
                            style={{ width: '100%' }}
                        >
                            Цена логистики
                        </InputNum>
                        <InputNum
                            value={
                                selectedSup.cost * count +
                                    selectedSup.delivery || 0
                            }
                            disabled
                            style={{ width: '100%' }}
                        >
                            Фактическая стоимость
                        </InputNum>
                    </Hflex>
                    <Hflex>
                        <RadioGroup
                            options={[
                                {
                                    value: 1,
                                    label: 'Разовый платёж',
                                },
                                {
                                    value: 2,
                                    label: 'Ежемесячный платёж',
                                },
                            ]}
                            value={selectedSup.payTime}
                            onChange={(payTime) =>
                                PurchaseStore.changeSupplier(selected, {
                                    payTime,
                                })
                            }
                        />
                    </Hflex>
                    {selectedSup.payTime == 1 ? (
                        <Hflex>
                            <InputNum
                                value={selectedSup.total}
                                onChange={(total) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        total,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Общая стоимость партии
                            </InputNum>
                            <InputDate
                                value={selectedSup.payDate}
                                onChange={(payDate) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        payDate,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Дата оплаты
                            </InputDate>
                        </Hflex>
                    ) : (
                        <Hflex>
                            <InputNum
                                value={selectedSup.total}
                                onChange={(total) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        total,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Общая стоимость партии
                            </InputNum>
                            <InputNum
                                max={31}
                                value={selectedSup.payDay}
                                onChange={(payDay) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        payDay,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Дата платежа
                            </InputNum>
                            <InputDate
                                value={selectedSup.firstPayDate}
                                onChange={(firstPayDate) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        firstPayDate,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Дата первого платежа
                            </InputDate>
                            <InputDate
                                value={selectedSup.lastPayDate}
                                onChange={(lastPayDate) =>
                                    PurchaseStore.changeSupplier(selected, {
                                        lastPayDate,
                                    })
                                }
                                style={{ width: '25%' }}
                            >
                                Дата последнего платежа
                            </InputDate>
                        </Hflex>
                    )}

                    <Hflex>
                        <ButtonFiles
                            value={selectedSup.files}
                            onChange={(files) =>
                                PurchaseStore.changeSupplier(selected, {
                                    files,
                                })
                            }
                        />
                    </Hflex>
                </>
            )}
            <H4>Рекомендуемые поставщики</H4>
            {AuthStore.user.role === 'purchase'
                ? suppliers?.map(
                      (item, i) =>
                          i !== selected && (
                              <>
                                  <Hflex align="end" key={i}>
                                      <InputText
                                          value={item.name}
                                          onChange={(name) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  name,
                                              })
                                          }
                                          style={{ width: '100%' }}
                                      >
                                          Поставщик № {i + 1}
                                      </InputText>
                                      <InputText
                                          value={item.communication}
                                          onChange={(communication) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  communication,
                                              })
                                          }
                                          style={{ width: '100%' }}
                                      >
                                          Способ связи
                                      </InputText>
                                      <InputNum
                                          value={item.cost}
                                          onChange={(cost) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  cost,
                                              })
                                          }
                                          style={{ width: '100%' }}
                                      >
                                          Цена за штуку
                                      </InputNum>
                                      <InputNum
                                          value={item.delivery}
                                          onChange={(delivery) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  delivery,
                                              })
                                          }
                                          style={{ width: '100%' }}
                                      >
                                          Цена логистики
                                      </InputNum>
                                      <InputNum
                                          value={
                                              item.cost * count +
                                                  item.delivery || 0
                                          }
                                          disabled
                                          style={{ width: '100%' }}
                                      >
                                          Общая стоимость партии
                                      </InputNum>
                                      <InputDate
                                          value={item.payDate}
                                          onChange={(payDate) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  payDate,
                                              })
                                          }
                                          style={{ width: '100%' }}
                                      >
                                          Дата оплаты
                                      </InputDate>
                                      {!finished && (
                                          <Button
                                              onClick={() =>
                                                  PurchaseStore.setSelectedSupplier(
                                                      i
                                                  )
                                              }
                                              disabled={!disabled}
                                          >
                                              Выбрать
                                          </Button>
                                      )}
                                  </Hflex>
                                  <Hflex>
                                      <ButtonFiles
                                          value={item.files}
                                          onChange={(files) =>
                                              PurchaseStore.changeSupplier(i, {
                                                  files,
                                              })
                                          }
                                      />
                                  </Hflex>
                              </>
                          )
                  )
                : suppliers?.map((item, i) => (
                      <Hflex align="end" key={i}>
                          <InputText
                              value={item.name}
                              onChange={(name) =>
                                  PurchaseStore.changeSupplier(i, { name })
                              }
                              disabled={disabled}
                              style={{ width: '100%' }}
                          >
                              Поставщик № {i + 1}
                          </InputText>
                          <InputText
                              value={item.communication}
                              onChange={(communication) =>
                                  PurchaseStore.changeSupplier(i, {
                                      communication,
                                  })
                              }
                              disabled={disabled}
                              style={{ width: '100%' }}
                          >
                              Способ связи
                          </InputText>
                      </Hflex>
                  ))}
        </>
    )
}

export default SuppliersBlock
