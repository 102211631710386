import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import VacancyStore from '../../store/VacancyStore'
import CandidatesBlock from './candidatesBlock'
import {
    Breadcrumb,
    InputText,
    InputDate,
    Select,
    Vflex,
    Hflex,
    Badge,
    Spin,
    Button,
    Input2Num,
} from '../../ui'

const VacancyEdit = () => {
    const navigate = useNavigate()
    const { vacancy, status, isProgress, hasChange } = VacancyStore
    let { id } = useParams()
    useEffect(() => {
        if (id === 'create') {
            VacancyStore.unset()
        } else {
            VacancyStore.getById(id)
        }
    }, [id])

    const save = async () => {
        const id = await VacancyStore.save()
        navigate('/vacancy/' + id, { replace: true })
    }
    if (isProgress) {
        return <Spin />
    }
    return (
        <Vflex>
            <Breadcrumb
                steps={[
                    { text: 'Вакансии', link: '/vacancy' },
                    { text: vacancy.name || 'Новая вакансия' },
                ]}
            />
            <Hflex>
                <InputText
                    placeholder="Водитель E"
                    style={{ width: 'calc(100%/3)' }}
                    value={vacancy.name}
                    onChange={(name) => VacancyStore.change({ name })}
                >
                    Вакансия
                </InputText>
                <Select
                    style={{ width: 'calc(100%/3)' }}
                    disabled={vacancy.id}
                    options={VacancyStore.type}
                    value={vacancy.type}
                    onChange={(type) => VacancyStore.change({ type })}
                >
                    Тип вакансии
                </Select>
                <InputText
                    placeholder="Воронеж"
                    style={{ width: 'calc(100%/3)' }}
                    value={vacancy.city}
                    onChange={(city) => VacancyStore.change({ city })}
                >
                    Город
                </InputText>
            </Hflex>
            <Hflex>
                <Select
                    style={{ width: 'calc(100%/3)' }}
                    options={status.map(({ value, text, color }) => ({
                        value,
                        label: <Badge color={color} text={text} />,
                    }))}
                    placeholder="Выберите статус"
                    value={vacancy.status}
                    onChange={(status) => VacancyStore.change({ status })}
                >
                    Статус
                </Select>
                <Input2Num
                    style={{ width: 'calc(100%/3)' }}
                    value1={vacancy.salaryMin}
                    onChange1={(salaryMin) =>
                        VacancyStore.change({ salaryMin })
                    }
                    value2={vacancy.salaryMax}
                    onChange2={(salaryMax) =>
                        VacancyStore.change({ salaryMax })
                    }
                >
                    Зарплата (от/до)
                </Input2Num>
                <InputDate
                    style={{ width: 'calc(100%/3)' }}
                    value={vacancy.dateCreate}
                    onChange={(dateCreate) =>
                        VacancyStore.change({ dateCreate })
                    }
                >
                    Дата создания
                </InputDate>
            </Hflex>
            <Hflex>
                <InputText
                    style={{ width: '50%' }}
                    value={vacancy.site}
                    onChange={(site) => VacancyStore.change({ site })}
                >
                    Сайт
                </InputText>
                <InputText
                    style={{ width: '50%' }}
                    value={vacancy.link}
                    onChange={(link) => VacancyStore.change({ link })}
                >
                    Ссылка на вакансию
                </InputText>
            </Hflex>
            <Hflex>
                <InputText
                    style={{ width: '100%' }}
                    value={vacancy.comment}
                    onChange={(comment) => VacancyStore.change({ comment })}
                >
                    Комментарий
                </InputText>
            </Hflex>
            <Button disabled={!hasChange || !vacancy.type} onClick={save}>
                Сохранить
            </Button>
            {id && (
                <CandidatesBlock
                    vacancy={vacancy.id}
                    candidates={vacancy.candidates}
                />
            )}
        </Vflex>
    )
}

export default observer(VacancyEdit)
