import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable } from '../../../ui'

const columns = [
    {
        dataIndex: 'cost',
        title: 'Ставка за км',
        editable: true,
    },
    {
        dataIndex: 'mileage',
        title: 'Километраж',
    },

    {
        dataIndex: 'perday',
        title: 'Суточная ставка',
        editable: true,
    },
    {
        dataIndex: 'days',
        title: 'Дни',
    },
    {
        dataIndex: 'total',
        title: 'Сумма суточных',
        render: (_, record) =>
            record.cost * record.mileage + record.perday * record.days,
    },
]

const SalaryTable = () => {
    const {
        endDatePlan,
        startDatePlan,
        planMileageFull,
        planMileageEmpty,
        planSalaryCost,
        planSalaryPerday,
        logistics: { days },
    } = RouteStore.route
    const planDays = endDatePlan.diff(startDatePlan, 'days')
    const activeDays = days.filter((item) => item.status)
    const factMileage = activeDays.reduce((a, b) => a + b.mileage, 0)
    return (
        <>
            <H4>Зарплата</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        cost: planSalaryCost,
                        mileage: planMileageFull + planMileageEmpty,
                        perday: planSalaryPerday,
                        days: planDays,
                        changeble: true,
                    },
                    {
                        id: 2,
                        cost: 0,
                        mileage: factMileage,
                        perday: 0,
                        days: activeDays.length,
                    },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planSalaryCost: data[0].cost,
                        planSalaryPerday: data[0].perday,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(SalaryTable)
