import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Vflex, DashPeriod, Table, H3, Spin, Hflex, Tabs, currencyFormatter } from '../../ui'
import FinanceService from '../../api/FinanceService.jsx'
import RouteStore from '../../store/RouteStore.jsx'
import { Link } from 'react-router-dom'

const columns = [
    {
        title: 'Контрагент',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        showSorterTooltip: {
            target: 'full-header',
        },
        filters: RouteStore.statusDoc.map(({ value, label }) => ({
            value,
            text: label,
        })),

        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => a?.status - b?.status,
        render: (value) => RouteStore.statusDoc[value - 1]?.label,
    },
    {
        title: 'Сумма',
        dataIndex: 'revenue',
        sorter: (a, b) => a.revenue - b.revenue,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Счёт',
        dataIndex: 'bill',
        showSorterTooltip: {
            target: 'full-header',
        },
        filters: RouteStore.statusBill.map(({ value, label }) => ({
            value,
            text: label,
        })),

        onFilter: (value, record) => record.bill === value,
        sorter: (a, b) => a?.bill - b?.bill,
        render: (value) => RouteStore.statusBill[value - 1]?.label,
    },
    {
        title: 'Срок оплаты',
        dataIndex: 'payDate',
        sorter: (a, b) =>
            dayjs(a.payDate, 'DD.MM.YYYY') - dayjs(b.payDate, 'DD.MM.YYYY'),
        render: (payDate) => dayjs(payDate).format('DD.MM.YYYY'),
    },
    {
        title: 'Долг заказчика',
        dataIndex: 'debt',
        sorter: (a, b) => a.debt - b.debt,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Маршрут',
        dataIndex: 'route',
        render: (_, record) => <Link to={'/route/' + record.id}>{_}</Link>,
    },
]

const DebitPage = () => {
    const [tab, setTab] = useState(1)
    const [date, setDate] = useState(dayjs())
    const [period, setPeriod] = useState('week')
    const [operations, setOperations] = useState()
    const tabs = [
        {
            key: 1,
            label: 'Актуальное',
        },
        {
            key: 2,
            label: 'Просрочка',
        },
    ]
    const timeColumn = {
        title: 'Время',
        dataIndex: 'payDate',
        sorter: (a, b) =>
            dayjs(a.payDate, 'DD.MM.YYYY') - dayjs(b.payDate, 'DD.MM.YYYY'),
        render: (payDate) => dayjs(payDate).fromNow(true),
    }
    useEffect(() => {
        async function fetchData() {
            const operations = (
                await FinanceService.getDebit({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setOperations(operations)
        }
        fetchData()
    }, [date, period])

    if (!operations) {
        return <Spin />
    }

    return (
        <Vflex>
            <Hflex align="flex-end">
                <Vflex>
                    <H3>Дебиторская задолженность</H3>
                    <Tabs
                        items={tabs}
                        value={tab}
                        onChange={setTab}
                    />
                </Vflex>
                <DashPeriod
                    date={date}
                    setDate={setDate}
                    period={period}
                    setPeriod={setPeriod}
                />
            </Hflex>
            <Table
                columns={
                    tab == 1 ? columns : columns.toSpliced(1, 0, timeColumn)
                }
                dataSource={operations.filter(
                    (item) =>
                        (tab == 1 && dayjs(item.payDate).isAfter()) ||
                        (tab == 2 && !dayjs(item.payDate).isAfter())
                )}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default DebitPage
