import React from 'react'
import { Flex } from 'antd'

const Vflex = ({ children, ...props }) => (
    <Flex vertical gap="small" {...props}>
        {children}
    </Flex>
)

const Hflex = ({ children, ...props }) => (
    <Flex align="center" gap="small" {...props}>
        {children}
    </Flex>
)

const Bflex = ({ children, ...props }) => (
    <Flex justify="space-between" align="center" gap="small" {...props}>
        {children}
    </Flex>
)

const Cflex = ({ children, ...props }) => (
    <Flex justify="center" align="center" {...props}>
        {children}
    </Flex>
)

export { Vflex, Hflex, Bflex, Cflex }
