import React from 'react'
import { Tabs as AntTabs } from 'antd'

const Tabs = ({ items, onChange, activeKey, props }) => (
    <AntTabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        activeKey={activeKey}
        {...props}
    />
)

export { Tabs }
