import React from 'react'
import dayjs from 'dayjs'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Vflex, Hflex, Tabs, InputDate, ButtonDefault, Divider } from '.'

const MonthPeriod = ({ date, setDate }) => {
    const add = () => setDate(date.add(1, 'month'))
    const sub = () => setDate(date.add(-1, 'month'))
    return (
        <Hflex>
            <ButtonDefault onClick={sub}>
                <LeftOutlined />
            </ButtonDefault>
            <InputDate
                picker="month"
                value={date}
                onChange={setDate}
                format={'MMMM, YYYY'}
            />
            <ButtonDefault onClick={add}>
                <RightOutlined />
            </ButtonDefault>
        </Hflex>
    )
}

const DashPeriod = ({ date, setDate, period, setPeriod }) => {
    const add = () => setDate(date.add(1, period))
    const sub = () => setDate(date.add(-1, period))
    return (
        <Hflex style={{ width: 265, margin: '16px 0', marginLeft: 'auto' }}>
            <Vflex style={{ width: '100%' }}>
                <Tabs
                    onChange={setPeriod}
                    items={[
                        {
                            key: 'week',
                            label: 'Неделя',
                        },
                        {
                            key: 'month',
                            label: 'Месяц',
                        },
                        {
                            key: 'quarter',
                            label: 'Квартал',
                        },
                        {
                            key: 'year',
                            label: 'Год',
                        },
                    ]}
                />
                <Hflex style={{ marginTop: -16 }}>
                    <ButtonDefault onClick={sub}>
                        <LeftOutlined />
                    </ButtonDefault>
                    <InputDate
                        style={{ width: '100%' }}
                        picker={period}
                        value={date}
                        onChange={setDate}
                        format={
                            period === 'week'
                                ? 'ww неделя, YYYY'
                                : period === 'month'
                                  ? 'MMMM, YYYY'
                                  : period === 'quarter'
                                    ? 'Q, YYYY'
                                    : ''
                        }
                    />
                    <ButtonDefault onClick={add}>
                        <RightOutlined />
                    </ButtonDefault>
                </Hflex>
            </Vflex>
        </Hflex>
    )
}
export { MonthPeriod, DashPeriod }
