import React, { useState } from 'react'
import { Modal, ButtonOut, InputNum } from '../../ui'
import CompanyStore from '../../store/CompanyStore'

const ChangeBalanceModal = ({ value }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [balance, setBalance] = useState()
    const showModal = () => {
        setBalance(value)
        setIsModalOpen(true)
    }

    const handleOk = () => {
        CompanyStore.changeBalance(balance)
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonOut
                size="small"
                style={{
                    marginLeft: 6,
                    marginTop: -21,
                    position: 'absolute',
                }}
                onClick={showModal}
            >
                Начислить
            </ButtonOut>
            <Modal
                title="Редактирование баланса"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Сохранить"
                cancelText="Отмена"
                okButtonProps={{ disabled: value === balance }}
            >
                <InputNum value={balance} onChange={setBalance}>
                    Баланс, ₽
                </InputNum>
            </Modal>
        </>
    )
}

export default ChangeBalanceModal
