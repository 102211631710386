import instance from './index'

const rootPath = '/api/notification/'

export default {
    check: () => {
        return instance.get(rootPath + 'check')
    },

    getAll: () => {
        return instance.get(rootPath)
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },
}
