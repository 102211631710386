import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import EmployeeService from '../../api/EmployeeService'
import EmployeeStore from '../../store/EmployeeStore'
import { Vflex, Bflex, H3, ButtonDefault, Table, Spin } from '../../ui'

const DriversPage = () => {
    const navigate = useNavigate()
    const [employees, setEmployees] = useState()
    useEffect(() => {
        async function fetchData() {
            const employees = (await EmployeeService.getDrivers()).data
            setEmployees(employees)
        }
        fetchData()
    }, [])

    if (!employees) {
        return <Spin />
    }

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 300,
            fixed: 'left',
            render: (_, record) => (
                <Link to={'/employee/' + record.id}>{_}</Link>
            ),
        },
        {
            title: 'Маршрут',
            dataIndex: 'route',
            sorter: (a, b) => a.route.localeCompare(b.route),
        },
        {
            title: 'Грузовик',
            dataIndex: 'truck',
            sorter: (a, b) => a.truck.localeCompare(b.truck),
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            fixed: 'left',
        },
        {
            title: 'Город',
            dataIndex: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city),
            fixed: 'left',
        },
        ...(employees.length === 0
            ? {}
            : employees[0].days.map((item, i) => ({
                  title: item.day,
                  dataIndex: 'days',
                  onHeaderCell: () => ({
                      style: {
                          padding: '16px 6px',
                          textAlign: 'center',
                      },
                  }),
                  onCell: (_) => ({
                      style: {
                          background: EmployeeStore.getDayProps(
                              _.days[i].typeDate
                          ).color,
                          padding: '16px 6px',
                          textAlign: 'center',
                      },
                  }),
                  render: (days) =>
                      EmployeeStore.getDayProps(days[i].typeDate).text,
                  width: 30,
              }))),
        {
            title: 'Заметка',
            dataIndex: 'comment',
            fixed: 'right',
        },
    ]

    return (
        <Vflex>
            <Bflex>
                <H3>Водители</H3>
                <ButtonDefault onClick={() => navigate('/purchase/create')}>
                    Заявка на закупку
                </ButtonDefault>
            </Bflex>
            <Table
                columns={columns}
                dataSource={employees}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default DriversPage
