import React from 'react'
import { Vflex, DashPeriod, Table } from '../../ui'

const columns = [
    {
        title: '',
        dataIndex: 'key',
        rowScope: 'row',
    },
    {
        title: 'Сумма маршрутов',
        dataIndex: 'routesTotal',
    },
    {
        title: 'Текущая сумма',
        dataIndex: 'currentTotal',
    },
    {
        title: 'Сумма на одно авто',
        dataIndex: 'averageTotal',
    },
    {
        title: 'Пробег общий',
        dataIndex: 'mileage',
    },
    {
        title: 'Пробег на одно авто',
        dataIndex: 'averageMileage',
    },
    {
        title: 'Ставка ₽/км',
        dataIndex: 'bet',
    },
    {
        title: 'Себестоимость',
        dataIndex: 'cost',
    },
    {
        title: 'Маржа, ₽',
        dataIndex: 'margin',
    },
    {
        title: 'Маржа, %',
        dataIndex: 'marginPercent',
    },
]
const data = [
    {
        key: 'План',
        routesTotal: 1000000,
        currentTotal: 1000000,
        averageTotal: 1000000,
        mileage: 1000000,
        averageMileage: 1000000,
        bet: 1000000,
        cost: 1000000,
        margin: 1000000,
        marginPercent: 20,
    },
    {
        key: 'Текущий итог',
        routesTotal: 1000000,
        currentTotal: 1000000,
        averageTotal: 1000000,
        mileage: 1000000,
        averageMileage: 1000000,
        bet: 1000000,
        cost: 1000000,
        margin: 1000000,
        marginPercent: 20,
    },
    {
        key: 'Отклонение',
        routesTotal: 1000000,
        currentTotal: 1000000,
        averageTotal: 1000000,
        mileage: 1000000,
        averageMileage: 1000000,
        bet: 1000000,
        cost: 1000000,
        margin: 1000000,
        marginPercent: 20,
    },
]

const LogisticDashboardPage = () => {
    return (
        <Vflex>
            <DashPeriod />
            <Table
                columns={columns}
                dataSource={data}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default LogisticDashboardPage
