import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'mileage',
        title: 'Километраж',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'addedCost',
        title: 'Добавочная стоимость',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'days',
        title: 'Дни',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
]

const AddPointTable = () => {
    const {
        planAddPointMileage,
        planAddPointAddedCost,
        planAddPointDays,
        factAddPointMileage,
        factAddPointAddedCost,
        factAddPointDays,
    } = RouteStore.route
    return (
        <>
            <H4>Доп. точки</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        mileage: planAddPointMileage,
                        addedCost: planAddPointAddedCost,
                        days: planAddPointDays,
                        changeble: true,
                    },
                    {
                        id: 2,
                        mileage: factAddPointMileage,
                        addedCost: factAddPointAddedCost,
                        days: factAddPointDays,
                        changeble: true,
                    },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planAddPointMileage: data[0].mileage,
                        planAddPointAddedCost: data[0].addedCost,
                        planAddPointDays: data[0].days,
                        factAddPointMileage: data[1].mileage,
                        factAddPointAddedCost: data[1].addedCost,
                        factAddPointDays: data[1].days,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(AddPointTable)
