import React from 'react'

import RoutesPage from '../routes/routesPage.jsx'
import EditRoute from '../routes/editRoute.jsx'
import DriversPage from '../employee/driversPage.jsx'
import DriverEmployeeEdit from '../employee/driverEmployeeEdit.jsx'
import ReportPage from '../employee/reportPage.jsx'
import PurchasePage from '../purchase/purchasePage.jsx'
import EditPurchase from '../purchase/editPurchase.jsx'
import SettingsPage from '../settingsPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'

export default [
    {
        path: '/',
        page: <DriversPage />,
        label: 'Водители',
    },
    {
        path: '/employee/:id',
        page: <DriverEmployeeEdit />,
    },
    {
        path: '/routes',
        page: <RoutesPage />,
        label: 'Маршруты',
    },
    {
        path: '/route/:id',
        page: <EditRoute />,
    },
    {
        path: '/report',
        page: <ReportPage />,
        label: 'Табель',
    },
    {
        path: '/purchase',
        page: <PurchasePage />,
        label: 'Закупки',
    },
    {
        path: '/purchase/:id',
        page: <EditPurchase />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
