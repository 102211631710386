import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import TransportService from '../../api/TransportService'
import {
    Bflex,
    Hflex,
    Vflex,
    ButtonDefault,
    H3,
    Table,
    Badge,
    Spin,
    currencyFormatter,
} from '../../ui'
import CreateTransportModal from './createTransportModal'
import TransportStore from '../../store/TransportStore'

const columns = [
    {
        title: 'Название',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => <Link to={'/transport/' + record.id}>{_}</Link>,
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        render: (type) => (type === 1 ? 'Тягач' : 'Прицеп'),
        sorter: (a, b) => a.type - b.type,
    },
    {
        title: 'Госномер',
        dataIndex: 'gosnumber',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Год выпуска',
        dataIndex: 'release',
        sorter: (a, b) => a.year - b.year,
    },
    {
        title: 'Пробег, км',
        dataIndex: 'mileage',
        sorter: (a, b) => a.mileage - b.mileage,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={TransportStore.status[num - 1].color}
                    text={TransportStore.status[num - 1].text}
                />
            ) : null,
    },
]

const TransportPage = () => {
    const navigate = useNavigate()
    const [transports, setTransports] = useState()
    useEffect(() => {
        async function fetchData() {
            const transports = (await TransportService.get()).data
            setTransports(transports)
        }
        fetchData()
    }, [])

    if (!transports) {
        return <Spin />
    }
    return (
        <Vflex>
            <Bflex>
                <H3>Транспорт</H3>
                <Hflex>
                    <ButtonDefault onClick={() => navigate('/purchase/create')}>
                        Заявка на закупку
                    </ButtonDefault>
                    <CreateTransportModal />
                </Hflex>
            </Bflex>
            <Table
                columns={columns}
                dataSource={transports}
                showSorterTooltip={{
                    target: 'sorter-icon',
                }}
            />
        </Vflex>
    )
}

export default observer(TransportPage)
