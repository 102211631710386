import React, { useState, useEffect } from 'react'
import { Table, Spin, currencyFormatter } from '../../../ui/index.jsx'
import DirectorService from '../../../api/DirectorService.jsx'

const columns = [
    {
        title: '',
        dataIndex: 'name',
        rowScope: 'row',
    },
    {
        title: 'План',
        dataIndex: 'plan',
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Текущий итог',
        dataIndex: 'fact',
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Отклонение',
        dataIndex: 'plan',
        render: (_, record) => currencyFormatter(_ - record.fact),
    },
]

const TargetTable = ({ date, period }) => {
    const [targets, setTargets] = useState()
    
    useEffect(() => {
        async function fetchData() {
            const targets = (
                await DirectorService.getTarget({
                    period,
                    date: date.format('DD.MM.YYYY'),
                })
            ).data
            setTargets(targets)
        }
        fetchData()
    }, [date, period])

    if (!targets) {
        return <Spin />
    }

    return (
        <Table
            columns={columns}
            dataSource={targets}
            pagination={false}
            showSorterTooltip={{
                target: 'sorter-icon',
            }}
        />
    )
}

export default TargetTable
