import React from 'react'
import dayjs from 'dayjs'
import RouteStore from '../../store/RouteStore'
import { Select } from 'antd'
import {
    Vflex,
    EditableTable,
    Badge,
    Summary,
    InputDate,
    Hflex,
    Button,
} from '../../ui'

const columns = [
    {
        title: '',
        dataIndex: 'date',
        rowScope: 'row',
        fixed: 'left',
        width: 120,
        render: (date) => date && dayjs(date).format('DD.MM.YYYY'),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        width: 120,
        inputNode: (
            <Select name="status">
                {RouteStore.dayStatus.map(({ value, text, color }) => (
                    <Select.Option key={value} value={value}>
                        <Badge color={color} text={text} />
                    </Select.Option>
                ))}
            </Select>
        ),
        render: (_) =>
            _ && (
                <Badge
                    color={RouteStore.dayStatus[_ - 1].color}
                    text={RouteStore.dayStatus[_ - 1].text}
                />
            ),
        editable: true,
    },
    { title: 'Пробег', dataIndex: 'mileage', width: 120, editable: true },
    {
        title: 'Расход, л/км',
        dataIndex: 'consumption',
        width: 120,
        editable: true,
    },
    {
        title: 'Стоимость литра',
        dataIndex: 'costPerLiter',
        width: 120,
        editable: true,
    },
    {
        title: 'Ставка зарплаты',
        dataIndex: 'salary',
        width: 120,
        editable: true,
    },
    { title: 'Гостиница', dataIndex: 'hotel', width: 120, editable: true },
    { title: 'Доп. точка', dataIndex: 'addPoint', width: 120, editable: true },
    { title: 'Сотовая связь', dataIndex: 'phone', width: 120, editable: true },
    { title: 'Платон', dataIndex: 'platon', width: 120, editable: true },
    {
        title: 'Платон/штрафы',
        dataIndex: 'platonFines',
        width: 120,
        editable: true,
    },
    { title: 'Навигация', dataIndex: 'navigation', width: 120, editable: true },
    {
        title: 'Платная дорога',
        dataIndex: 'tollRoad',
        width: 120,
        editable: true,
    },
    { title: 'Стоянка', dataIndex: 'parking', width: 120, editable: true },
    { title: 'Мойка', dataIndex: 'washing', width: 120, editable: true },
    { title: 'Штрафы', dataIndex: 'fines', width: 120, editable: true },
    {
        title: 'Простой по РФ',
        dataIndex: 'waitRus',
        width: 120,
        editable: true,
    },
    {
        title: 'Простой зарубеж',
        dataIndex: 'waitAbroad',
        width: 120,
        editable: true,
    },
    { title: 'Ремонт', dataIndex: 'repair', width: 120, editable: true },
    { title: 'Таможня', dataIndex: 'customs', width: 120, editable: true },
    { title: 'Гринкарта', dataIndex: 'greencard', width: 120, editable: true },
    {
        title: 'Декларирование',
        dataIndex: 'declaration',
        width: 120,
        editable: true,
    },
    {
        title: 'Услуги перевозчика',
        dataIndex: 'carrier',
        width: 120,
        editable: true,
    },
]

const DaysLogisticBlock = ({ logistics }) => {
    const changeLogistics = (props) => {
        RouteStore.change({
            logistics: {
                ...logistics,
                ...props,
            },
        })
        RouteStore.save()
    }
    const addDay = () => {
        const lastDate = logistics.days[logistics.days.length - 1].date
        changeLogistics({
            days: [...logistics.days, { date: dayjs(lastDate).add(1, 'day') }],
        })
    }

    const data = logistics?.days?.map((item) => ({
        ...item,
        id: item._id,
        changeble: true,
    }))
    return !data?.length ? (
        <Hflex>
            <InputDate
                onChange={(date) => changeLogistics({ days: [{ date }] })}
            >
                Фактическая дата начала
            </InputDate>
        </Hflex>
    ) : (
        <Vflex>
            <Hflex>
                <Button onClick={addDay}>Добавить день</Button>
            </Hflex>
            <EditableTable
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                scroll={{
                    x: 'max-content',
                    y: 55 * 10,
                }}
                setData={(days) => changeLogistics({ days })}
                summary={(data) => (
                    <Summary fixed>
                        <Summary.Row>
                            <Summary.Cell key={0} index={0}>
                                Итого
                            </Summary.Cell>
                            <Summary.Cell key={1} index={1}></Summary.Cell>
                            {columns.map(
                                (col, i) =>
                                    i > 1 && (
                                        <Summary.Cell key={i} index={i}>
                                            {data.reduce(
                                                (a, b) =>
                                                    a + (b[col.dataIndex] || 0),
                                                0
                                            )}
                                        </Summary.Cell>
                                    )
                            )}
                            <Summary.Cell key={-1} index={-1}></Summary.Cell>
                        </Summary.Row>
                    </Summary>
                )}
            />
        </Vflex>
    )
}

export default DaysLogisticBlock
