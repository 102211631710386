import React, { useState, useEffect } from 'react'
import { Vflex, H3, H5, Divider, Bflex, Text, Spin, Badge } from '../../ui'
import { useNavigate, useLocation } from 'react-router-dom'
import NotificationService from '../../api/NotificationService'

const NotificationPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [notifications, setNotifications] = useState()
    useEffect(() => {
        async function fetchData() {
            const notifications = (await NotificationService.getAll()).data
            setNotifications(notifications)
        }
        fetchData()
    }, [location.key])

    if (!notifications) {
        return <Spin />
    }
    return (
        <Vflex>
            <H3>Уведомления</H3>
            {notifications.length == 0 && <Text>Уведомлений ещё не было</Text>}
            {notifications.map((item) => (
                <div
                    key={item.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/notifications/' + item.id)}
                >
                    <Bflex>
                        <Vflex>
                            <H5>
                                {item.title}{' '}
                                {!item.isView && <Badge color="red" />}
                            </H5>
                            <Text type="secondary">{item.message}</Text>
                        </Vflex>
                        <Text>{item.date}</Text>
                    </Bflex>
                    <Divider />
                </div>
            ))}
        </Vflex>
    )
}

export default NotificationPage
