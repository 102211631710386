import instance from './index'

const rootPath = '/api/vacancy/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    save: (vacancy) => {
        return instance.post(rootPath + 'save', { vacancy })
    },

    addComment: ({ vacancyId, text }) => {
        return instance.post(rootPath + 'addComment', { vacancyId, text })
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
