import instance from './index'

const rootPath = '/api/director/'

export default {
    getTarget: (params) => {
        return instance.get(rootPath + 'target', { params })
    },

    getEmployee: (params) => {
        return instance.get(rootPath + 'employee', { params })
    },

    getTransport: (params) => {
        return instance.get(rootPath + 'transport', { params })
    },

    getRepair: (params) => {
        return instance.get(rootPath + 'repair', { params })
    },

    getBase: (params) => {
        return instance.get(rootPath + 'base', { params })
    },

    getSale: (params) => {
        return instance.get(rootPath + 'sale', { params })
    },

    getRent: (params) => {
        return instance.get(rootPath + 'rent', { params })
    },

    getUsers: () => {
        return instance.get(rootPath + 'users')
    },

    addUser: (user) => {
        return instance.post(rootPath + 'addUser', user)
    },

    changeUser: (user) => {
        return instance.post(rootPath + 'changeUser', user)
    },

    getCheckList: () => {
        return instance.get(rootPath + 'checkList')
    },

    saveCheckList: (checkList) => {
        return instance.post(rootPath + 'saveCheckList', checkList)
    },

    getCalc: () => {
        return instance.get(rootPath + 'calc')
    },

    saveCalc: (calc) => {
        return instance.post(rootPath + 'saveCalc', calc)
    },
}
