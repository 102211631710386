import React from 'react'
import { observer } from 'mobx-react-lite'
import RouteStore from '../../../store/RouteStore'
import { H4, EditableTable, currencyFormatter } from '../../../ui'

const columns = [
    {
        dataIndex: 'crossing',
        title: 'Переход границы',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'greencard',
        title: 'Гринкарта',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
    {
        dataIndex: 'expenses',
        title: 'Доп. расходы',
        editable: true,
        render: (_) => currencyFormatter(_),
    },
]

const AbroadTable = () => {
    const {
        planAbroadCrossing,
        planAbroadGreencard,
        planAbroadExpenses,
        logistics: { days },
    } = RouteStore.route
    const activeDays = days.filter((item) => item.status)
    const crossing = activeDays.reduce((a, b) => a + b.customs, 0)
    const greencard = activeDays.reduce((a, b) => a + b.greencard, 0)
    const expenses = activeDays.reduce(
        (a, b) => a + b.declaration + b.carrier,
        0
    )
    return (
        <>
            <H4>Расходы на МП</H4>
            <EditableTable
                columns={columns}
                changebleDataIndex="changeble"
                dataSource={[
                    {
                        id: 1,
                        crossing: planAbroadCrossing,
                        greencard: planAbroadGreencard,
                        expenses: planAbroadExpenses,
                        changeble: true,
                    },
                    { id: 2, crossing, greencard, expenses },
                ]}
                setData={(data) =>
                    RouteStore.saveChange({
                        planAbroadCrossing: data[0].crossing,
                        planAbroadGreencard: data[0].greencard,
                        planAbroadExpenses: data[0].expenses,
                    })
                }
                bordered
                pagination={false}
            />
        </>
    )
}

export default observer(AbroadTable)
