import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import PurchaseService from '../../api/PurchaseService'
import PurchaseStore from '../../store/PurchaseStore'
import { Table, Spin, Badge, currencyFormatter } from '../../ui'

const columns = [
    {
        title: '№',
        dataIndex: 'number',
        sorter: (a, b) => a.number - b.number,
        render: (_, record) => <Link to={'/purchase/' + record.id}>{_}</Link>,
    },
    {
        title: 'Дата заявки',
        dataIndex: 'dateOpen',
        sorter: (a, b) => dayjs(a.dateOpen).diff(dayjs(b.dateOpen)),
        render: (dateOpen) => dayjs(dateOpen).format('DD.MM.YYYY'),
    },
    {
        title: 'Наименование',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Кол-во',
        dataIndex: 'count',
        sorter: (a, b) => a.count - b.count,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Плановая стоимость',
        dataIndex: 'cost',
        sorter: (a, b) => a.cost - b.cost,
        render: (_) => currencyFormatter(_),
    },
    {
        title: 'Ответственный',
        dataIndex: 'responsible',
        sorter: (a, b) => a.responsible.localeCompare(b.responsible),
    },
    {
        title: 'Дата закрытия заявки',
        dataIndex: 'dateClose',
        sorter: (a, b) => dayjs(a.dateClose).diff(dayjs(b.dateClose)),
        render: (dateClose) => dayjs(dateClose).format('DD.MM.YYYY'),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        sorter: (a, b) => a.status - b.status,
        render: (num) =>
            num ? (
                <Badge
                    color={PurchaseStore.status[num - 1].color}
                    text={PurchaseStore.status[num - 1].text}
                />
            ) : null,
    },
]
const MyTable = () => {
    const [purchases, setRepairs] = useState()
    useEffect(() => {
        async function fetchData() {
            const purchases = (await PurchaseService.get()).data
            setRepairs(purchases)
        }
        fetchData()
    }, [])

    if (!purchases) {
        return <Spin />
    }
    return (
        <Table
            columns={columns}
            dataSource={purchases}
            showSorterTooltip={{
                target: 'sorter-icon',
            }}
        />
    )
}

export default MyTable
