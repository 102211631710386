import React from 'react'

import HRDashboardPage from '../employee/hrDashboardPage.jsx'
import ReportPage from '../employee/reportPage.jsx'
import PurchasePage from '../purchase/purchasePage.jsx'
import EditPurchase from '../purchase/editPurchase.jsx'
import SettingsPage from '../settingsPage.jsx'
import NotificationPage from '../notification/notificationPage.jsx'
import ItemNotificationPage from '../notification/itemPage.jsx'
import VacancyPage from '../vacancy/vacancyPage.jsx'
import VacancyEdit from '../vacancy/vacancyEdit.jsx'
import CandidateEdit from '../vacancy/candidateEdit.jsx'
import DriversPage from '../employee/driversPage.jsx'
import DriverEmployeeEdit from '../employee/driverEmployeeEdit.jsx'

export default [
    {
        path: '/',
        page: <HRDashboardPage />,
        label: 'Дашборд',
    },
    {
        path: '/report',
        page: <ReportPage />,
        label: 'Табель',
    },
    {
        path: '/vacancy',
        page: <VacancyPage />,
        label: 'Вакансии',
    },
    {
        path: '/vacancy/:id',
        page: <VacancyEdit />,
    },
    {
        path: '/candidate/:vacancy/:id',
        page: <CandidateEdit />,
    },
    {
        path: '/drivers',
        page: <DriversPage />,
        label: 'Водители',
    },
    {
        path: '/employee/:id',
        page: <DriverEmployeeEdit />,
    },
    {
        path: '/purchase',
        page: <PurchasePage />,
        label: 'Закупки',
    },
    {
        path: '/purchase/:id',
        page: <EditPurchase />,
    },
    {
        path: '/settings',
        page: <SettingsPage />,
        label: 'Настройки',
    },
    {
        path: '/notifications',
        page: <NotificationPage />,
    },
    {
        path: '/notifications/:id',
        page: <ItemNotificationPage />,
    },
]
