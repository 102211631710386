import React, { useState, useEffect, useRef } from 'react'
import {
    Route,
    Routes,
    BrowserRouter,
    Link,
    useNavigate,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ConfigProvider } from 'antd'
import ru from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
dayjs.locale('ru')
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
import quarterOfYear from 'dayjs/plugin/relativeTime'
dayjs.extend(quarterOfYear)
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import AuthStore from '../store/AuthStore'
import { MainComponent, Spin } from '../ui'

import { getRouters } from './navigate'
import LoginPage from './loginPage'

const Router = observer(() => {
    const navigate = useNavigate()
    const [update, setUpdate] = useState()
    let ref = useRef(false)
    useEffect(() => {
        AuthStore.checkAuth()
        AuthStore.checkNotification()
        setTimeout(() => setUpdate(!update), 60000)
    }, [update])
    useEffect(() => {
        if (ref.isChangeRole) {
            navigate('/')
        }
        if (AuthStore.user.role) {
            ref.isChangeRole = true
        }
    }, [AuthStore.user.role])
    let routers = getRouters(AuthStore.user.role)

    const navItems = routers
        .filter(({ label }) => label)
        .map(({ path, label }) => ({
            key: path,
            label: <Link to={path}>{label}</Link>,
        }))
    return (
        <MainComponent navItems={navItems}>
            {AuthStore.isAuthInProgress ? (
                <Spin />
            ) : (
                <Routes>
                    {!AuthStore.isAuth ? (
                        <Route key="login" path="*" element={<LoginPage />} />
                    ) : (
                        routers.map(({ path, page }) => (
                            <Route key={path} path={path} element={page} />
                        ))
                    )}
                </Routes>
            )}
        </MainComponent>
    )
})

const App = () => (
    <ConfigProvider locale={ru}>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    </ConfigProvider>
)

export default App
