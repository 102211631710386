import instance from './index'

const rootPath = '/api/purchase/'

export default {
    get: () => {
        return instance.get(rootPath)
    },

    getAll: () => {
        return instance.get(rootPath + 'all')
    },

    getDirector: () => {
        return instance.get(rootPath + 'director')
    },

    getResponsibles: () => {
        return instance.get(rootPath + 'responsibles')
    },

    getById: (id) => {
        return instance.get(rootPath + id)
    },

    save: (purchase) => {
        return instance.post(rootPath + 'save', { purchase })
    },

    delete: (id) => {
        return instance.get(rootPath + 'delete/' + id)
    },
}
